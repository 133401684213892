import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { catchError, lastValueFrom, map, throwError } from 'rxjs';
import { RegionService } from 'src/app/services/region/region.service';
import { OverseasService } from 'src/app/services/overseas/overseas.service';
import { Router } from '@angular/router';
import { ROUTE } from 'src/app/shared/constant/constant';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { LimoHkService } from 'src/app/services/limo-hk/limo-hk.service';
import { ILimoZone } from 'src/app/model/zone';
import { IChinaLimoItem, IHkLimoOrder, ILimoActiveLocationHKData, ILimoCarSearchParams, ILimoCarSearchResponseData } from 'src/app/model/limo';
import { LimoService } from 'src/app/services/limo/limo.service';
import { TranslocoService } from '@ngneat/transloco';
import { RentType } from 'src/app/model/home-page';
import { IRegionOption } from 'src/app/model/region';
import { s3Static } from 'src/app/services/s3-files/s3-files.service';

@Component({
  selector: 'app-limo-search-form',
  templateUrl: './limo-search-form.component.html',
  styleUrls: ['./limo-search-form.component.scss']
})
export class LimoSearchFormComponent implements OnInit {
  @Input() type: string;
  @Output() currentCarData = new EventEmitter();
  @Output() currentPrice = new EventEmitter();
  @Output() loadingState = new EventEmitter();

  staticRegion = [
    {
      code: 'HK',
      label: this.translocoService.translate('hk&Cross'),
    },
    {
      code: 'CH',
      label: this.translocoService.translate('china'),
    },
  ];
  limoFormGroup!: FormGroup;

  // general data container for location data
  limoZoneAirportType: ILimoZone[];
  // like limoZoneAirportType but without "Airport" option
  limoZoneDistrictType: ILimoZone[];
  
  // for location options that will be displayed on the form
  listLocation: ILimoActiveLocationHKData[];
  listLocationForPickup: ILimoActiveLocationHKData[];
  listLocationForDropOff: ILimoActiveLocationHKData[];
  
  // for area options
  listPickupArea: ILimoZone[];
  listDropOffArea: ILimoZone[];
  price: number = 0;
  disableLocation: boolean = false;
  limoOrderData: IHkLimoOrder;
  listChinaMap: IChinaLimoItem[];
  currentRegion: string = 'HK';
  showPickupLocation: boolean = false;
  showDropOffLocation: boolean = false;
  showChinaRegion: boolean = false;
  screenDisplay: string;

  pickupLabel: string = null;
  dropOffLabel: string = null;
  chinaServiceLabel: string = null;

  limoCarData: ILimoCarSearchResponseData[];
  regionData: IRegionOption[];

  isLoading: boolean = true;

  private createForm() {
    this.limoFormGroup = this.formBuilder.group({
      limoZonePickupArea: new FormControl(null),
      limoZonePickUpLocation: new FormControl(null),
      limoZoneDropOffArea: new FormControl(null),
      limoZoneDropOffLocation: new FormControl(null),
      chinaService: new FormControl(null),
      type: 'DISTRICT',
      region: this.currentRegion,
      additionalLocation: new FormControl(null)
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private regionServive: RegionService,
    private locationService: OverseasService,
    private limoService: LimoService,
    private limoHkService: LimoHkService,
    private router: Router,
    private notificationService: NotificationsService,
    private translocoService: TranslocoService,
  ) { }

  async ngOnInit() {
    this.onResize();
    this.createForm();
    this.loadingState.emit(true);

    // await this.getRegionData();
    await this.loadActiveLocationHK();
    await this.loadChinaLocation();
    await this.fetchLimoData();

    if (this.type === 'edit') {
      this.disableLocation = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 768) {
      this.screenDisplay = 'mobile';
    } else {
      this.screenDisplay = 'desktop';
    }
  }

  async getRegionData() {
    const regions$ = this.regionServive.getRegions();
    const regions = await lastValueFrom(regions$);
    this.regionData = regions;
  }

  async fetchLimoData() {
    const limoData = this.limoHkService.getLimoData();
    
    if (limoData) {
      this.limoFormGroup = this.formBuilder.group({
        limoZonePickupArea: limoData.limoZonePickupArea,
        limoZonePickUpLocation: limoData.limoZonePickUpLocation,
        limoZoneDropOffArea: limoData.limoZoneDropOffArea,
        limoZoneDropOffLocation: limoData.limoZoneDropOffLocation,
        type: limoData.type,
        region: limoData.region,
        additionalLocation: limoData.additionalLocation,
        chinaService: limoData.chinaService
      });

      await this.onChangePickUpLocation(limoData.limoZonePickUpLocation);
      await this.onChangeDropOffLocation(limoData.limoZoneDropOffLocation);

      await this.getPrice(limoData.limoZonePickUpLocation, limoData.limoZoneDropOffLocation, limoData.type);
      await this.searchLimoCar();
      
      this.pickupLabel = this.listLocation.find(val => val._id === limoData.limoZonePickUpLocation) ? this.listLocation.find(val => val._id === limoData.limoZonePickUpLocation).name : null;
      this.dropOffLabel = this.listLocation.find(val => val._id === limoData.limoZoneDropOffLocation) ? this.listLocation.find(val => val._id === limoData.limoZoneDropOffLocation).name : null;
      this.chinaServiceLabel = this.listChinaMap.find(val => val.code === limoData.chinaService) ? this.listChinaMap.find(val => val.code === limoData.chinaService).name : null;
      
      this.loadingState.emit(false);
    }
  }

  onShowPickupLocation() {
    this.showPickupLocation = true;
  }

  onShowDropOffLocation() {
    this.showDropOffLocation = true;
  }

  onShowChinaRegion() {
    this.showChinaRegion = true;
  }

  closeModal() {
    const { limoZonePickUpLocation, limoZoneDropOffLocation, chinaService } = this.limoFormGroup.value;
    this.pickupLabel = this.listLocation.find(val => val._id === limoZonePickUpLocation) ? this.listLocation.find(val => val._id === limoZonePickUpLocation).name : null;
    this.dropOffLabel = this.listLocation.find(val => val._id === limoZoneDropOffLocation) ? this.listLocation.find(val => val._id === limoZoneDropOffLocation).name : null;
    this.chinaServiceLabel = this.listChinaMap.find(val => val.code === chinaService) ? this.listChinaMap.find(val => val.code === chinaService).name : null;
    this.showPickupLocation = false;
    this.showDropOffLocation = false;
    this.showChinaRegion = false;
  }

  async loadChinaLocation() {
    const china$ = this.limoService.getLimoChineseList();
    const china = await lastValueFrom(china$);
    this.listChinaMap = china.data;
  }

  onChangeRegion(region: string) {
    if (this.limoFormGroup.value.currentRegion !== region) {
      this.limoHkService.clearLimoData();
      this.limoHkService.clearLimoOrderData();
    }

    this.currentRegion = region;

    this.limoFormGroup.reset();
    this.createForm();
  }

  async getPrice(limoZonePickupArea: string, limoZoneDropOffArea: string, type: string) {
    const additionalLocationId = this.limoFormGroup.value.additionalLocation;
    const price$ = this.locationService.getLimoZoneToZonePrice(limoZonePickupArea, limoZoneDropOffArea, type, additionalLocationId);
    const price = await lastValueFrom(price$);
    this.price = price.data;
    this.currentPrice.emit(this.price);
  }

  async loadActiveLocationHK() {
    const activeLocation = await lastValueFrom(this.locationService.getActiveLocationHK());
    const filteredData = activeLocation.data.filter((val) => typeof val?.name === 'string');

    this.listLocation = filteredData;
    this.listLocationForPickup = filteredData;
    this.listLocationForDropOff = filteredData;
  }

  filterNamedLocationAreaOnly(data: ILimoActiveLocationHKData[]) {
    return data.filter((val) => typeof val.name != undefined);
  }

  async onChangePickUpLocation(event: any) {
    const pickUpLocationID = typeof event === 'object' ? event.value : event;
    const currentDropOffLocationSelected = this.limoFormGroup.value['limoZoneDropOffLocation'];

    let newListLocation = this.listLocation.filter(location => {
      // exclude the location if not allowed to select the same location on drop off
      return location._id !== pickUpLocationID || (location._id === pickUpLocationID && location.isAllowSameLocation);
    });

    this.listLocationForDropOff = newListLocation;

    this.limoFormGroup.patchValue({
      limoZoneDropOffLocation: currentDropOffLocationSelected,
    });

    const pickup$ = this.locationService.getLimoZoneByArea(pickUpLocationID);
    const pickup = await lastValueFrom(pickup$);
    this.listPickupArea = pickup.data.filter((val) => typeof val?.name === 'string');
  }

  async onChangeDropOffLocation(event : any) {
    const dropOffLocationID = typeof event === 'object' ? event.value : event;
    const currentPickUpLocationSelected = this.limoFormGroup.value['limoZonePickUpLocation'];

    let newListLocation = this.listLocation.filter(location => {
      // exclude the location if not allowed to select the same location on drop off
      return location._id !== dropOffLocationID || (location._id === dropOffLocationID && location.isAllowSameLocation);
    });

    this.listLocationForPickup = newListLocation;

    this.limoFormGroup.patchValue({
      limoZonePickUpLocation: currentPickUpLocationSelected,
    });

    const dropoff$ = this.locationService.getLimoZoneByArea(dropOffLocationID);
    const dropoff = await lastValueFrom(dropoff$);
    this.listDropOffArea = dropoff.data.filter((val) => typeof val?.name === 'string');
  }

  async onSubmit() {
    this.loadingState.emit(false);

    const { limoZonePickUpLocation, limoZoneDropOffLocation, chinaService } = this.limoFormGroup.value;

    if (this.currentRegion === 'HK') {
      if (!limoZonePickUpLocation || !limoZoneDropOffLocation) {
        const errMsg = 'Please select pickup and drop-off location!';
        this.notificationService.showAlerts(errMsg, 404);
        return;
      }
    }
    if (this.currentRegion === 'CH') {
      if (!chinaService) {
        const errMsg = 'Please select service region!';
        this.notificationService.showAlerts(errMsg, 404);
        return;
      }
    }

    if (this.type === 'search') {
      await this.setLimoOrderData();
      this.limoHkService.saveLimoData(this.limoFormGroup.value);
      const url = this.currentRegion === 'HK' ? ROUTE.LIMO_CAR_SELECTION : ROUTE.LIMO_CHINA;
      this.router.navigate([url]);
    } else {
      await this.setLimoOrderData();
    }

  }

  async setLimoOrderData() {
    const { limoZonePickUpLocation, limoZoneDropOffLocation, limoZonePickupArea, limoZoneDropOffArea, region, chinaService } = this.limoFormGroup.value;

    let orderData: IHkLimoOrder;
    if (this.currentRegion === 'HK') {
      await this.getPrice(limoZonePickUpLocation, limoZoneDropOffLocation, 'DISTRICT');
      await this.searchLimoCar();
      
      orderData = {
        pickupZone: this.listLocation.find(val => val._id === limoZonePickUpLocation) && this.currentRegion ? this.listLocation.find(val => val._id === limoZonePickUpLocation).name : null,
        dropOffZone: this.listLocation.find(val => val._id === limoZoneDropOffLocation) && this.currentRegion ? this.listLocation.find(val => val._id === limoZoneDropOffLocation).name : null,
        pickupArea: this.listPickupArea.find(val => val._id === limoZonePickupArea) && this.currentRegion ? this.listPickupArea.find(val => val._id === limoZonePickupArea).name : null,
        dropOffArea: this.listDropOffArea.find(val => val._id === limoZoneDropOffArea) && this.currentRegion ? this.listDropOffArea.find(val => val._id === limoZoneDropOffArea).name : null,
        region: this.staticRegion.find(val => val.code === region).label || null,
        price: this.price,
      };
    } else {
      orderData = {
        region: this.staticRegion.find(val => val.code === region).label || null,
        chinaService: chinaService || null,
      };
    }
    this.limoHkService.saveLimoOrderData(orderData);
  }

  async searchLimoCar() {
    this.loadingState.emit(true);

    const formGroupData = this.limoFormGroup.value;
    await this.getRegionData();
    const regionID = this.regionData.find(val => val.code === formGroupData.region)._id;

    // return;
    let params: ILimoCarSearchParams = {
      region: regionID,
      pickupLocation: formGroupData.limoZonePickUpLocation,
      dropOffLocation: formGroupData.limoZoneDropOffLocation,
      rentType: RentType.RentCarWithDriver,
    };

    this.limoService.searchCars(params).pipe(
      map(items => items.data.sort((a, b) => {
        return b.estimatedPrice - a.estimatedPrice;
      })),
      catchError(err => {
        const errorr: { msg: string }[] = err.error.errors || [{ msg: 'Something went wrong!!!' }];
        errorr.forEach(item => {
          Object.keys(item).map(msg => {
            this.notificationService.showAlerts(item[msg], 404);
          });
        });
        return throwError(err);
      })
    ).subscribe(res => {
      this.limoCarData = res.map(car => {
        return {
          ...car,
          images: car.images.map(img => {
            if (img.includes('http')) {
              return img;
            } else {
              return `${s3Static}${img}`;
            }
          })
        };
      });

      this.currentCarData.emit(this.limoCarData);

      this.loadingState.emit(false);

      // sort by 'Price Low to High'
      // this.onSortBy(SORT_OPTIONS[2]);
    });
  }

}
