<form *transloco="let t" [formGroup]="customerForm" (ngSubmit)="onSubmit()" class="bg-white customer-content">
  <h4 *transloco="let t">{{ t('customerInformation') }}</h4>
  <!-- <div class="divider"></div> -->
  <div class="row">
    <div class="col-md-6 form-input-space">
      <label class="required" *transloco="let t">{{ t('firstName') }}</label>
      <input type="text" class="form-control" formControlName="name">
      <small class="form-text text-danger" *transloco="let t">{{ t('firstNameIsARequiredField') }}</small>
    </div>
    <div class="col-md-6 form-input-space">
      <label class="required" *transloco="let t">{{ t('lastName') }}</label>
      <input type="text" class="form-control" formControlName="surName">
      <small class="form-text text-danger" *transloco="let t">{{ t('lastNameIsARequiredField') }}</small>
    </div>

    <div class="col-md-12 form-input-space">
      <label class="required">{{ t('email') }}</label>
      <input type="text" class="form-control" formControlName="email">
      <small class="form-text text-danger" *transloco="let t">{{ t('emailIsInvalid') }}</small>
    </div>

    <div class="col-md-12 form-input-space">
      <label class="required" *transloco="let t">{{ t('mobileNumber') }}</label>
      <div class="row">
        <div class="col-md-3 area-code">
          <select class="form-control select-icon" formControlName="areaCode">
            <option *ngFor="let item of areaCodes" [value]="item">{{ item }}</option>
          </select>
        </div>
        <div class="col-md-9">
          <label></label>
          <input type="text" class="form-control" formControlName="phone">
          <small class="form-text text-danger" *transloco="let t">{{ t('mobileNumberIsInvalid') }}</small>
        </div>
      </div>
    </div>

    <div class="col-md-12 form-input-space" *ngIf="questions$ | async as questions">
      <label class="required">{{t('howDoFind')}}</label>
      <select class="form-control mt-2 select-icon" formControlName="HOW_TO_KNOW_MLS">
        <option value="null" disabled selected *transloco="let t">---{{ t('selectOne') }}---</option>
        <option *ngFor="let item of questions.options" [value]="item.id">{{ item.value }}</option>
      </select>
      <small class="form-text text-danger" *transloco="let t">{{ t('pleaseSelectAtLeast1Answer') }}</small>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-4">
      <label class="required" *transloco="let t">{{ t('firstName') }}</label>
    </div>
    <div class="col-md-8">
      <input type="text" class="form-control" formControlName="name">
      <small class="form-text text-danger" *transloco="let t">{{ t('firstNameIsARequiredField') }}</small>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <label class="required" *transloco="let t">{{ t('lastName') }}</label>
    </div>
    <div class="col-md-8">
      <input type="text" class="form-control" formControlName="surName">
      <small class="form-text text-danger" *transloco="let t">{{ t('lastNameIsARequiredField') }}</small>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <label class="required">{{ t('email') }}</label>
    </div>
    <div class="col-md-8">
      <input type="text" class="form-control" formControlName="email">
      <small class="form-text text-danger" *transloco="let t">{{ t('emailIsInvalid') }}</small>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <label class="required" *transloco="let t">{{ t('areaCode') }}</label>
    </div>
    <div class="col-md-8">
      <select class="form-control mt-2 select-icon" formControlName="areaCode">
        <option *ngFor="let item of areaCodes" [value]="item">{{ item }}</option>
      </select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <label class="required" *transloco="let t">{{ t('mobileNumber') }}</label>
    </div>
    <div class="col-md-8">
      <input type="text" class="form-control" formControlName="phone">
      <small class="form-text text-danger" *transloco="let t">{{ t('mobileNumberIsInvalid') }}</small>
    </div>
  </div> -->

  <!-- <div class="row mt-3" *ngIf="questions$ | async as questions">
    <div class="col-md-4">
      <label class="required">{{t('howDoFind')}}</label>
    </div>
    <div class="col-md-8">
      <select class="form-control mt-2 select-icon" formControlName="HOW_TO_KNOW_MLS">
        <option value="null" disabled selected *transloco="let t">---{{ t('selectOne') }}---</option>
        <option *ngFor="let item of questions.options" [value]="item.id">{{ item.value }}</option>
      </select>
      <small class="form-text text-danger" *transloco="let t">{{ t('pleaseSelectAtLeast1Answer') }}</small>
    </div>
  </div> -->
  <div class="mt-4" *ngIf="order && order?.place?.region?.code !== 'HK'">
    <h4 class="mb-2" *transloco="let t">{{ t('travelInformation') }}</h4>
    <p *transloco="let t">{{ t('weRecommendProvidingYourTravelInformationBelow') }}</p>
    <div class="row">
      <div class="col-md-4 flight-info">
        <label *transloco="let t">{{ t('flightInfo') }}</label>
      </div>
      <div class="col-md-4 select-airline">
        <label>Arrival Time <label class="optional">(optional)</label></label>
      </div>
      <div class="col-md-4">
        <p-calendar dataType="string" formControlName="arrivalTime" placeholder="Arrival Time" [timeOnly]="true"
          [hourFormat]="24" inputId="timeonly"></p-calendar>
      </div>
      <div class="col-md-4 flight-number">
        <label *transloco="let t">{{ t('flightNumber') }} <label class="optional">(optional)</label></label>
      </div>
      <div class="col-md-4" *transloco="let t">
        <input type="text" placeholder="{{ t('flightNumber') }}" class="form-control" formControlName="flightNo">
      </div>
    </div>
  </div>
  <div *transloco="let t" class="g-col-12 mt-4">
    <!-- <h4 class="mb-4">{{ t('termsAndConditions') }}</h4> -->
    <div class="mb-2 d-flex align-items-start">
      <p-checkbox [binary]="true" inputId="term-cond" formControlName="agree"></p-checkbox>
      <label class="ms-2 lh-1" for="term-cond">{{t('tnc_split')}} <a href="javascript:void(0)"
          (click)="openTermsConditionModal()">{{t('termsAndConditions')}}</a></label>
    </div>
    <div class="mb-2 d-flex align-items-start" *ngIf="!isLoggedIn">
      <p-checkbox [binary]="true" inputId="receive-promotion" formControlName="termAgreeToJoinDCH"></p-checkbox>
      <label class="ms-2 lh-1" for="receive-promotion" *transloco="let t">{{ t('iAgreeToJoinDCHMembership') }}
        (四個轆之友)</label>
    </div>
  </div>
  <!-- <div class="divider"></div> -->
  <div class="g-col-12 pt-10" style="padding-top: 25px;">
    <p-button type="submit" [loading]="submitting"
      [label]="isEnquiry ? ('Submit' | transloco) : ('confirm' | transloco)" styleClass="px-5"
      [disabled]="customerForm.invalid || !customerForm.controls['agree'].value || (!isLoggedIn && !customerForm.controls['termAgreeToJoinDCH'].value) || submitting"></p-button>
  </div>
  <app-terms-condition-dialog [isDisplayed]="displayTermsAndConditions"
    (onClosed)="closeTermsAndConditions()"></app-terms-condition-dialog>
</form>