/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { UserLogin } from 'src/app/model/user';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ROUTE } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-signin-dialog',
  templateUrl: './signin-dialog.component.html',
  styleUrls: ['./signin-dialog.component.scss'],
})
export class SigninDialogComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Output() onShowRegister = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  signInSMS: boolean = false;
  messages: Message[] = [];
  isLoading: boolean = false;
  loginForm: FormGroup | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private notificationService: NotificationsService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onLogin(event: UserLogin) {
    this.isLoading = true;
    if (event.otp) {
      event.otp = event.otp.trim();
    }
    this.authService.login(event).subscribe(
      (res) => {
        this.closeModal();
        if (res && res.isFirstLogin) {
          this.router.navigate([ROUTE.RESET_PASSWORD]);
          this.notificationService.showAlerts(
            'Please reset your password to secure your account!!',
            401
          );
        } else {
          this.router.navigate([ROUTE.HOME_PAGE]);
        }
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.messages = err.error.errors.map((x) => ({
          severity: 'error',
          summary: x.msg,
        }));
      }
    );
  }

  private initForm() {
    this.loginForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  closeModal() {
    this.onClose.emit();
  }

  showRegister() {
    this.closeModal();
    this.onShowRegister.emit();
  }
}
