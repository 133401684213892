<div class="car-item" [class.opened]="show">
  <div class="grid gap-3 p-3" *ngIf="car">
    <div class="g-col-12 content">
      <div class="left" (click)="show = !show" >
        <div class="image" >
          <div class="image-info" [class.opened]="show" [style.backgroundImage]="'url('+ car.images[0] +')'"></div>
          <div class="car-info" [class.opened]="show">
            <div class="content-car-info" [class.opened]="show">
              <div class="row" style="text-align: left;">
                <div class="col-6">
                  <p><img src="assets/images/door.png"><label class="size-label-icon">N/A</label></p>
                  <p><img src="assets/images/a_c.png"><label class="size-label-icon" *transloco="let t">{{ t('A_C') }}</label></p>
                  <p><img src="assets/images/small_bag.png"><label class="size-label-icon" *transloco="let t">{{ car.largeSuitcases }} {{ t('largeBags') }}</label></p>
                  <p><img src="assets/images/gas.png"><label class="size-label-icon">N/A</label></p>
                </div>
                <div class="col-6">
                  <p class="ms-1"><i class="fa-solid fa-user"><label class="size-label-icon" *transloco="let t">{{ car.seats }} {{ t('seats') }}</label> </i></p>
                  <p><img style="width: 20px !important; height: 20px !important;" src="assets/images/small_bag.png"><label class="size-label-icon" *transloco="let t">{{ car.smallSuitcases }} {{ t('smallBags') }}</label></p>
                  <p><img src="assets/images/auto.png"><label class="size-label-icon">N/A</label></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right content">
        <div class="px-4 mb-3">
          <h4>{{ car.name }}</h4>
          <i class="fa-solid fa-user"><label class="size-label-icon">{{ car.seats }}</label> </i>
          <i class="fas fa-briefcase ms-3"><label class="size-label-icon">{{ car.largeSuitcases }}</label></i>
          <i class="fas fa-briefcase ms-3" style="font-size: 12px;"><label class="size-label-icon">{{ car.smallSuitcases }}</label></i>
          <p class="view-info" (click)="show = !show" *transloco="let t">{{ !show ? t('viewVehicleInformation') : t('closeVehicleInformation')}}</p>
        </div>
        <div class="px-4" *transloco="let t">
          <h4>{{ car.systemCurrencyCode }} {{ car.estimatedPrice | number }}</h4>
          <p-button type="button" label="{{ t('select') }}" styleClass="px-5" (click)="onSelectCar($event)"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>
