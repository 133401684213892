import { Component, Input, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ROUTE } from 'src/app/shared/constant/constant';
import { Router } from '@angular/router';
import { IOrderData } from '../../model/order';
import { Subject, Subscription, timer } from 'rxjs';
import { OrderService } from '../../services/order/order.service';
import { BrowserService } from '../../services/browser/browser.service';
import { takeUntil } from 'rxjs/operators';
import { CarService } from '../../services/car/car.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-custom-steps',
  templateUrl: './custom-steps.component.html',
  styleUrls: ['./custom-steps.component.scss']
})
export class CustomStepsComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private browserService: BrowserService,
    private carService: CarService,
    private translocoService: TranslocoService,
    private orderService: OrderService) {
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }
  items: MenuItem[];
  private _localStorage: Storage | undefined;

  @Input() activeIndex: number = 0;
  @Input() counter: number = 600; // default 10 minutes
  @Input() type: string;
  order: IOrderData | undefined;
  countDown: Subscription;
  tick = 1000;
  arrivalTime: boolean = false;
  showCountDown: boolean = false;
  stopCounter: number;
  private destroy$ = new Subject();
  ngOnInit() {
    console.log(this.type);
    if (this.type == 'limo') {
      this.items = [
        { label: this.translocoService.translate('selectCar'), routerLink: ROUTE.LIMO_CAR_SELECTION },
        { label: this.translocoService.translate('rentalOptions'), routerLink: ROUTE.CAR_OPTIONAL_ITEM },
      ];
    } else {
      this.items = [
        { label: this.translocoService.translate('selectCar'), routerLink: ROUTE.CAR_SELECTION },
        { label: this.translocoService.translate('rentalOptions'), routerLink: ROUTE.CAR_OPTIONAL_ITEM },
        { label: this.translocoService.translate('paymentInfo') }
      ];
      this.order = this.orderService.getOrderData();
      if (this.order
        && this.order?.selectedCarGrade
        && (this.router.url.includes(ROUTE.CAR_OPTIONAL_ITEM) || this.router.url.includes(ROUTE.ORDER_REVIEW))
        && this.order?.place?.region?.code === 'HK'
      ) {
        this.showCountDown = true;
        this.stopCounter = this.counter - 1;

        this.countDown = timer(0, this.tick)
          .pipe(takeUntil(this.destroy$))
          .subscribe((t) => {
            if (t >= (this.stopCounter)) {
              this.countDown.unsubscribe();
              this.arrivalTime = true;
              const body = {
                tempBookingNo: this.order?.carGrade?.tempBookingNo,
                pickupTime: this.order?.place.pickupTime,
                dropOffTime: this.order?.place.dropOffTime,
                mlsVehicleLicNo: this.order?.carGrade?.mlsVehicleLicNo,

                tempBookingNoOld: this.order?.selectedCarGrade?.tempBookingNo || '',
                pickupTimeOld: this.order?.selectedCarGrade?.pickupTime || '',
                dropOffTimeOld: this.order?.selectedCarGrade?.dropOffTime || '',
                mlsVehicleLicNoOld: this.order?.selectedCarGrade?.mlsVehicleLicNo || '',
              };
              this.carService.selectCar(body).subscribe();
            }
            --this.counter;
            this._localStorage.setItem('counter', String(this.counter));
          });
      }
    }
  }

  ngOnDestroy() {
    this.countDown = null;
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onClick(index: number, item: MenuItem) {
    if (index < this.activeIndex) {
      this.activeIndex = index;
      this.router.navigate([item?.routerLink]);
    }
  }

  onCloseModal() {
    this.arrivalTime = false;
    this.router.navigate([ROUTE.CAR_SELECTION]);
    this.orderService.saveOrderData({ ...this.order, selectedCarGrade: null });
  }
}

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
