import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OptionalItemComponent } from './../optional-item/optional-item.component';
import { ButtonModule } from 'primeng/button';
import { CarItemComponent } from '../car-item/car-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarDetailComponent } from '../car-detail/car-detail.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { RouterModule } from '@angular/router';
import { LocationSummaryComponent } from '../../components/location-summary/location-summary.component';
import { SummaryComponent } from '../../components/summary/summary.component';
import { SignInFormComponent } from '../../components/sign-in-form/sign-in-form.component';
import { RegistrationFormComponent } from '../registration-form/registration-form.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import {
  CustomStepsComponent,
  FormatTimePipe,
} from '../custom-steps/custom-steps.component';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { DialogModule } from 'primeng/dialog';
import { CarItemModalComponent } from '../car-item-for-modal/car-item-for-modal.component';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { QRCodeModule } from 'angularx-qrcode';
import { TooltipModule } from 'primeng/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ContactUsBottomComponent } from '../contact-us-bottom/contact-us-bottom.component';
import { GeneralEnquiryComponent } from '../general-enquiry/general-enquiry.component';
import { EnquiryFormComponent } from '../enquiry-form/enquiry-form.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { TermsConditionDialog } from '../terms-condition-dialog/terms-condition-dialog.component';
import { FeaturesCardComponent } from 'src/app/components/features-card/features-card.component';
import { CarSearchSummaryComponent } from '../car-search-summary/car-search-summary.component';
import { GMapModule } from 'primeng/gmap';
import { ContactUsBottomNewComponent } from '../contact-us-bottom-new/contact-us-bottom-new';
import { CarSearchFormComponent } from '../car-search/car-search-form/car-search-form.component';
import { LimoSearchFormComponent } from '../limo-search-form/limo-search-form.component';
import { LimoOrderDetailComponent } from '../limo-order-detail/limo-order-detail.component';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [
    CarItemComponent,
    CarDetailComponent,
    OptionalItemComponent,
    CustomerFormComponent,
    LocationSummaryComponent,
    SummaryComponent,
    SignInFormComponent,
    RegistrationFormComponent,
    CustomStepsComponent,
    OrderDetailComponent,
    CarItemModalComponent,
    TimePickerComponent,
    FormatTimePipe,
    ContactUsBottomComponent,
    GeneralEnquiryComponent,
    EnquiryFormComponent,
    TermsConditionDialog,
    FeaturesCardComponent,
    CarSearchSummaryComponent,
    ContactUsBottomNewComponent,
    CarSearchFormComponent,
    LimoSearchFormComponent,
    LimoOrderDetailComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    DropdownModule,
    DialogModule,
    CalendarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    QRCodeModule,
    TooltipModule,
    TranslocoModule,
    RecaptchaModule,
    GMapModule,
    MessagesModule,
  ],
  exports: [
    CarItemComponent,
    CarDetailComponent,
    OptionalItemComponent,
    CustomerFormComponent,
    LocationSummaryComponent,
    SummaryComponent,
    SignInFormComponent,
    RegistrationFormComponent,
    CustomStepsComponent,
    OrderDetailComponent,
    CarItemModalComponent,
    TimePickerComponent,
    ContactUsBottomComponent,
    GeneralEnquiryComponent,
    EnquiryFormComponent,
    TermsConditionDialog,
    FeaturesCardComponent,
    CarSearchSummaryComponent,
    ContactUsBottomNewComponent,
    CarSearchFormComponent,
    LimoSearchFormComponent,
    LimoOrderDetailComponent,
  ],
})
export class SharedComponentsModule {}
