/* eslint-disable @typescript-eslint/indent */
import { Observable, of } from 'rxjs';
import { ROUTE } from './../../shared/constant/constant';
import { Component, OnInit } from '@angular/core';
import { MenuItem, Message } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userMenuItems: MenuItem[] = [];
  isLoggedIn$: Observable<boolean> = of(false);
  msgs: Message[] = [];
  userNameLogged$: Observable<string> = of('');
  ROUTE = ROUTE;
  visibleRightSideBar: boolean = false;
  topMenuItems: MenuItem[] = [];
  // loggedInMenuItems: MenuItem[] = [];
  bottomMenuItems: MenuItem[] = [];
  selectedLanguage = 'ENGLISH';
  userLabel: string = '';
  signoutLabel: string = '';
  menuInitialized: boolean = false;
  currentLang: string = localStorage.getItem('currentLang') || 'EN';
  currentLangDisplay: string = 'EN';
  showLogin: boolean = false;
  showRegister: boolean = false;
  // sorry if this is not very much readable :((
  labelObject = {
    label_news:
      this.currentLang === 'TC'
        ? '推廣優惠及最新動向'
        : this.currentLang === 'SC'
          ? '推广优惠及最新动向'
          : 'PROMOTION & NEWS',
    label_pricing:
      this.currentLang === 'TC'
        ? '價格'
        : this.currentLang === 'SC'
          ? '价格'
          : 'Pricing',
    label_rac_limo:
      this.currentLang === 'TC'
        ? '租車/豪華轎車'
        : this.currentLang === 'SC'
          ? '租车/豪华轿车'
          : 'RAC/ LIMO',
    label_rent_car:
      this.currentLang === 'TC'
        ? '自駕租賃'
        : this.currentLang === 'SC'
          ? '自驾租赁'
          : 'Rent a Car',
    label_overseas:
      this.currentLang === 'TC'
        ? '海外'
        : this.currentLang === 'SC'
          ? '海外'
          : 'Overseas',
    label_limousine_services:
      this.currentLang === 'TC'
        ? '豪華轎車接載'
        : this.currentLang === 'SC'
          ? '豪华轿车接载'
          : 'Limousine Services',
    label_hk_cross_border_limousine_services:
      this.currentLang === 'TC'
        ? '香港及跨境豪華轎車服務'
        : this.currentLang === 'SC'
          ? '香港及跨境豪华轿车服务'
          : 'HK & Cross Border Limousine Services',
    label_china_limousine_service:
      this.currentLang === 'TC'
        ? '中國豪華轎車服務'
        : this.currentLang === 'SC'
          ? '中国豪华轿车服务'
          : 'China Limousine Service',
    label_long_term_leasing:
      this.currentLang === 'TC'
        ? '長期汽車租賃'
        : this.currentLang === 'SC'
          ? '长期汽车租赁'
          : 'Long Term Leasing',
    label_corporate:
      this.currentLang === 'TC'
        ? '企業'
        : this.currentLang === 'SC'
          ? '企业'
          : 'Corporate',
    label_individual:
      this.currentLang === 'TC'
        ? '個人'
        : this.currentLang === 'SC'
          ? '个人'
          : 'Individual',
    label_car_share:
      this.currentLang === 'TC'
        ? '汽車共享'
        : this.currentLang === 'SC'
          ? '汽车共享'
          : 'CarShare',
    label_property:
      this.currentLang === 'TC'
        ? '物業'
        : this.currentLang === 'SC'
          ? '物业'
          : 'Property',
    label_aprongo:
      this.currentLang === 'TC'
        ? 'ApronGo'
        : this.currentLang === 'SC'
          ? 'ApronGo'
          : 'ApronGo',
    label_van_logistics:
      this.currentLang === 'TC'
        ? '客貨車物流'
        : this.currentLang === 'SC'
          ? '客货车物流'
          : 'Van Logistics',
    label_advertising_truck:
      this.currentLang === 'TC'
        ? '廣告車租賃'
        : this.currentLang === 'SC'
          ? '广告车租赁'
          : 'Advertising Truck',
    label_check_your_booking:
      this.currentLang === 'TC'
        ? '檢查您的預訂'
        : this.currentLang === 'SC'
          ? '检查您的预订'
          : 'CHECK YOUR BOOKING',
    label_language:
      this.currentLang === 'TC'
        ? '語言'
        : this.currentLang === 'SC'
          ? '语言'
          : 'LANGUAGE',
    label_sign_out:
      this.currentLang === 'TC'
        ? '登出'
        : this.currentLang === 'SC'
          ? '登出'
          : 'SIGN OUT',
    label_user:
      this.currentLang === 'TC'
        ? '用戶資料'
        : this.currentLang === 'SC'
          ? '用户资料'
          : 'USER PROFILE',
    label_signIn:
      this.currentLang === 'TC'
        ? '登入/ 登記'
        : this.currentLang === 'SC'
          ? '登入/ 登记'
          : 'SIGN IN / REGISTER',
    label_about_us:
      this.currentLang === 'TC'
        ? '關於我們'
        : this.currentLang === 'SC'
          ? '关于我们'
          : 'ABOUT US',
    label_contact_us:
      this.currentLang === 'TC'
        ? '聯絡我們'
        : this.currentLang === 'SC'
          ? '联络我们'
          : 'CONTACT US',
  };
  userIcon = '../../../assets/images/icons/user-default.png';
  burgerIcon = '../../../assets/images/icons/hamburger-default.svg';
  colorDynamic = '#203e5b';

  isHomePage = false;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private translocoService: TranslocoService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        switch (val.url) {
          case '/property':
            this.userIcon = '../../../assets/images/icons/user-white.png';
            this.burgerIcon = '../../../assets/images/icons/hamburger-white.svg';
            this.colorDynamic = 'white';
            break;

          case '/about-us':
            this.userIcon = '../../../assets/images/icons/user-white.png';
            this.burgerIcon = '../../../assets/images/icons/hamburger-white.svg';
            this.colorDynamic = 'white';
            break;

          case '/rent-car':
            this.userIcon = '../../../assets/images/icons/user-white.png';
            this.burgerIcon = '../../../assets/images/icons/hamburger-white.svg';
            this.colorDynamic = 'white';
            break;

          case '/long-term-leasing':
            this.userIcon = '../../../assets/images/icons/user-white.png';
            this.burgerIcon = '../../../assets/images/icons/hamburger-white.svg';
            this.colorDynamic = 'white';
            break;

          default:
            this.userIcon = '../../../assets/images/icons/user-default.png';
            this.burgerIcon = '../../../assets/images/icons/hamburger-default.svg';
            this.colorDynamic = '#203e5b';
            break;
        }
        
        this.isHomePage = val.url == ROUTE.HOME_PAGE || val.url == '/';
        // console.log({
        //   isHomePage : this.isHomePage,
        //   currentRoute : val.url,
        //   routeHomepage : ROUTE.HOME_PAGE
        // });
      }

    });
  }

  ngOnInit(): void {
    this.setCurrentLangDisplay();
    // this.changeLanguage(this.translocoService.getActiveLang());
    this.isLoggedIn$ = this.authService.isLoggedIn();
    this.userNameLogged$ = this.authService.userNameLoggedIn();
    const user = this.authService.getUserFromLocalStorage();
    if (user?.token) {
      this.authService.updateLoginStatus(true);
      this.authService.updateUserNameLoggedIn(user.name);
    }    

    this.topMenuItems = [
      {
        label: this.labelObject.label_news,
        routerLink: ROUTE.NEWS,
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_rent_car,
        expanded: false,
        items: [
          {
            label: this.labelObject.label_rent_car,
            routerLink: ROUTE.RENT_CAR,
            command: () => (this.visibleRightSideBar = false),
          },
          {
            label: this.labelObject.label_pricing,
            routerLink: ROUTE.RATE_CARD,
            command: () => (this.visibleRightSideBar = false),
          },
        ],
      },

      // {
      //   label: this.labelObject.label_pricing,
      //   items: [
      //     {
      //       label: this.labelObject.label_overseas,
      //       routerLink: ROUTE.OVERSEAS,
      //       command: () => (this.visibleRightSideBar = false),
      //     },
      //   ],
      // },
      {
        label: this.labelObject.label_limousine_services,
        routerLink: ROUTE.OVERSEAS,
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_van_logistics,
        routerLink: ROUTE.VAN_LOGISTICS,
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_long_term_leasing,
        routerLink: ROUTE.LONG_TERM_LEASING,
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_advertising_truck,
        routerLink: ROUTE.ADVERTISING_TRUCK,
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_car_share,
        routerLink: ROUTE.PROPERTY,
        command: () => (this.visibleRightSideBar = false),
      },
      // {
      //   label: this.labelObject.label_limousine_services,
      //   expanded: false,
      //   items: [
      //     {
      //       label: this.labelObject.label_hk_cross_border_limousine_services,
      //       routerLink: `${ROUTE.LIMOUSINE_SERVICE}/hk`,
      //       command: () => (this.visibleRightSideBar = false),
      //     },
      //     {
      //       label: this.labelObject.label_china_limousine_service,
      //       routerLink: `/${ROUTE.LIMOUSINE_SERVICE}/cn`,
      //       command: () => (this.visibleRightSideBar = false),
      //     },
      //   ],
      // },
      // {
      //   label: this.labelObject.label_language,
      //   expanded: false,
      //   items: [
      //     {
      //       label: 'English',
      //       command: () => this.changeLanguage('EN'),
      //     },
      //     {
      //       label: '繁體中文',
      //       command: () => this.changeLanguage('TC'),
      //     },
      //     {
      //       label: '简体中文',
      //       routerLink: ROUTE.APRON_GO,
      //       command: () => this.changeLanguage('SC'),
      //     },
      //   ],
      // },
    ];

    this.bottomMenuItems = [
      {
        label: this.labelObject.label_about_us,
        routerLink: ROUTE.ABOUT_US,
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_contact_us,
        routerLink: ROUTE.CONTACT_US,
        command: () => (this.visibleRightSideBar = false),
      },
    ];

    this.userMenuItems = [
      {
        label: this.labelObject.label_user,
        routerLink: ROUTE.USER_PROFILE,
        icon: 'pi pi-fw pi-user',
        command: () => (this.visibleRightSideBar = false),
      },
      {
        label: this.labelObject.label_check_your_booking,
        routerLink: null,
        icon: 'pi pi-fw pi-book',
        command: () => {
          this.isLoggedIn$.subscribe((loggedIn) => {
            if (loggedIn) {
              this.router.navigate([ROUTE.LOOKUP]);
              this.visibleRightSideBar = false;
            } else {
              this.visibleRightSideBar = false;
              this.showLogin = true;
            }
          });
        },
      },
      {
        label: this.labelObject.label_sign_out,
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
          this.signOut();
        },
      },
    ];
  }

  setCurrentLangDisplay() {
    this.currentLangDisplay = this.currentLang;
    
    if (this.currentLang === 'TC') {
      this.currentLangDisplay = '繁';
    } else if (this.currentLang === 'SC') {
      this.currentLangDisplay = '简';
    }
  }

  onShowMenu() {
    this.visibleRightSideBar = true;
    const user = this.authService.getUserFromLocalStorage();

    if (user) {
      const menuHasLogOut = this.topMenuItems.find(
        (e) =>
          e.label.toLocaleLowerCase() ===
          this.labelObject.label_sign_out.toLocaleLowerCase()
      );
      if (!menuHasLogOut) {
        this.topMenuItems = [
          ...this.topMenuItems.filter((e) => e.routerLink !== ROUTE.SIGN_IN),
          // {
          //   label: this.labelObject.label_user,
          //   routerLink: ROUTE.USER_PROFILE,
          //   command: () => (this.visibleRightSideBar = false),
          // },
          // {
          //   label: this.labelObject.label_sign_out,
          //   command: () => {
          //     this.signOut();
          //     this.visibleRightSideBar = false;
          //   },
          // },
        ];
      }
    } else {
      const menuHasLogIn = this.topMenuItems.find(
        (e) => e.routerLink === ROUTE.SIGN_IN
      );
      if (!menuHasLogIn) {
        this.topMenuItems = [
          ...this.topMenuItems.filter(
            (e) =>
              e.label.toLocaleLowerCase() !==
              this.labelObject.label_sign_out.toLocaleLowerCase() &&
              e.routerLink !== ROUTE.USER_PROFILE
          ),
          // {
          //   label: this.labelObject.label_signIn,
          //   routerLink: ROUTE.SIGN_IN,
          //   command: () => {
          //     this.visibleRightSideBar = false;
          //     this.handleShowLogin();
          //   },
          // },
        ];
      }
    }

    this.menuInitialized = true;
    this.topMenuItems.forEach((item) => {
      if (item.items) {
        item.expanded = false;
        item.items.forEach((sub) => (sub.expanded = false));
      }
    });
  }

  signOut() {
    this.authService.logout();
    this.router.navigate([ROUTE.HOME_PAGE]);
    location.reload();
  }

  login() {
    this.router.navigate([ROUTE.SIGN_IN]);
  }

  handleShowLogin() {
    this.showLogin = true;
  }

  handleCloseLogin() {
    this.showLogin = false;
  }

  handleShowRegister() {
    this.showRegister = true;
  }

  handleCloseRegister() {
    this.showRegister = false;
  }

  public changeLanguage(languageCode: string): void {
    localStorage.setItem('currentLang', languageCode);
    this.translocoService.setActiveLang(languageCode);
    location.reload();
  }
}
