<section class="wrapper">
  <div class="content-container">
    <div class="content">
      <img src="../../../assets/images/contact-us/mail.svg" />
      <p>
        <a href="mailto:{{contactUsData.email}}">
          {{contactUsData.email}}</a
        >
      </p>
    </div>
  </div>
  <div class="content-container">
    <div class="content">
      <img src="../../../assets/images/contact-us/phone.svg" />
      <div>
        <p>{{contactUsData.contactNo}}</p>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="content">
      <img src="../../../assets/images/contact-us/location.svg" />
      <p>
        {{contactUsData.address}}
      </p>
    </div>
  </div>
</section>
