import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

export function encryptStringValue(value: string) {
  return CryptoJS.AES.encrypt(value, environment.secretKey).toString();
}

export function decryptStringValue(value: string) {
  return CryptoJS.AES.decrypt(value, environment.secretKey).toString(CryptoJS.enc.Utf8);
}

export function initTimePicker() {
  let hours: number;
  let minutes: string | number;
  let ampm: string;
  const time = [];
  for (var i = 0; i <= 1440; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    ampm = hours % 24 < 12 ? 'AM' : 'PM';
    hours = hours % 12;
    if (hours === 0) {
      hours = 12;
    }
    var fullTime = hours + ':' + minutes + ' ' + ampm;
    var timeValue = { value: fullTime, name: fullTime };
    if (timeValue.value == '12:00 AM') {
      time.push({ value: fullTime, name: 'midnight' });
    } else if (timeValue.value == '12:00 PM') {
      time.push({ value: fullTime, name: 'noon' });
    } else {
      time.push(timeValue); 
    }
  }
  return time;
}

export function convertTime12to24(timeString) {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours) + 12;
  }
  return `${hours}:${minutes}`;
}

export const OptionalItemTypes = {
  DAY_PRICE: 'Day price',
  FIXED_PRICE: 'Fixed price',
};