import { ROUTE } from './../../shared/constant/constant';
import { OrderService } from 'src/app/services/order/order.service';
import { ICarGradeItem } from './../../model/car-selection';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarService } from '../../services/car/car.service';
import { IOrderData } from '../../model/order';
import { BrowserService } from '../../services/browser/browser.service';
import { RegionCode } from '../../model/region';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { s3Static } from 'src/app/services/s3-files/s3-files.service';
import { ILocation } from 'src/app/model/location';


@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html',
  styleUrls: ['./car-item.component.scss']
})
export class CarItemComponent implements OnInit {

  _carGrade: ICarGradeItem;
  show: boolean = false;

  @Input() order: IOrderData | undefined;
  @Input()
  get car(): ICarGradeItem {
    return this._carGrade;
  }

  set car(car: ICarGradeItem) {
    this._carGrade = {
      ...car,
      images: car.images.map(img => {
        if (img.includes('http')) {
          return img;
        } else {
          return `${s3Static}${img}`;
        }
      }),
    };
  }
  private _localStorage: Storage | undefined;

  constructor(private orderService: OrderService,
    private carService: CarService,
    private browserService: BrowserService,
    private notificationService: NotificationsService,
    private router: Router) { 
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }

  itemLocation: ILocation;
  map: google.maps.Map;
  showMap = false;

  setMap(event) {
    this.map = event.map;
  }

  ngOnInit() {
    console.log(this.car);
  }

  onSelectCar() {
    if (this.order?.place?.region?.code === RegionCode.HK) {
      const body = {
        tempBookingNo: this._carGrade.tempBookingNo,
        pickupTime: this.order?.place.pickupTime,
        dropOffTime: this.order?.place.dropOffTime,
        mlsVehicleLicNo: this._carGrade.mlsVehicleLicNo,

        tempBookingNoOld: this.order?.selectedCarGrade?.tempBookingNo || '',
        pickupTimeOld: this.order?.selectedCarGrade?.pickupTime || '',
        dropOffTimeOld: this.order?.selectedCarGrade?.dropOffTime || '',
        mlsVehicleLicNoOld: this.order?.selectedCarGrade?.mlsVehicleLicNo || '',
      };
      this.carService.selectCar(body).subscribe({
        next: () => {
          this.orderService.saveSelectCarGradeToOrder({
            tempBookingNo: this._carGrade.tempBookingNo,
            pickupTime: this.order?.place.pickupTime,
            dropOffTime: this.order?.place.dropOffTime,
            mlsVehicleLicNo: this._carGrade.mlsVehicleLicNo,
          });
          this._localStorage.removeItem('counter');
          this.orderService.saveCarGradeToOrder(this._carGrade);
          this.router.navigate([ROUTE.CAR_OPTIONAL_ITEM]);
        },
        error: err => {
          const errorr: { msg: string }[] = err.error?.errors;
          errorr.forEach((item, index) => {
            if (index === 0) {
              this.notificationService.showAlerts(item.msg, 404);
            }
          });
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(()=> this.router.navigate([ROUTE.CAR_SELECTION]));
        }
      });
    } else if (this.order?.place?.region?.code === RegionCode.JP) {
      const order: IOrderData = JSON.parse(this._localStorage?.getItem('order')) || null;
      order.place.pickupRentalLocation = {
        ...order.place.pickupLocation,
        _id: this._carGrade.pickUpLocation._id,
        name: this._carGrade.pickUpLocation.name,
        address: this._carGrade.pickUpLocation.address
      };
      order.place.dropOffRentalLocation = {
        ...order.place.dropOffLocation,
        _id: this._carGrade.pickUpLocation._id,
        name: this._carGrade.pickUpLocation.name,
        address: this._carGrade.pickUpLocation.address
      };
      this._localStorage?.setItem('order', JSON.stringify(order));
      this.orderService.saveCarGradeToOrder(this._carGrade);
      this.router.navigate([ROUTE.CAR_OPTIONAL_ITEM]);
    } else {
      this.orderService.saveCarGradeToOrder(this._carGrade);
      this.router.navigate([ROUTE.CAR_OPTIONAL_ITEM]);
    }
    
  }


  hoveredLocation: ILocation;

  onHoverLocation(location: ILocation) {
    this.hoveredLocation = location;
  }

  onCloseMap() {
    this.hoveredLocation = null;
  }

  onMouseOver(location) {
    console.log('hovered');
    let bounds = new google.maps.LatLngBounds();
  
    // Create a deep copy of the location object
    const locationWithoutCircularRefs = JSON.parse(JSON.stringify(location));
  
    locationWithoutCircularRefs.options = {
      center: { lat: Number(locationWithoutCircularRefs.latitude), lng: Number(locationWithoutCircularRefs.longitude) },
      zoom: 12,
    };
  
    locationWithoutCircularRefs.overlays = [
      new google.maps.Marker({
        position: { lat: Number(locationWithoutCircularRefs.latitude), lng: Number(locationWithoutCircularRefs.longitude) },
      }),
    ];
  
    locationWithoutCircularRefs.overlays.forEach((marker) => {
      bounds.extend(marker.getPosition());
    });
  
    setTimeout(() => {
      this.map.fitBounds(bounds);
      this.map.setZoom(12);
      const contentMap = document.querySelector('.content-map') as HTMLElement;
      const contentMapRect = contentMap.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const scrollPosition = contentMapRect.bottom - windowHeight + window.scrollY;
      if (scrollPosition > window.scrollY) {
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    }, 200);
  
    this.itemLocation = locationWithoutCircularRefs;
    this.showMap = true;
  }

  onMouseOut(event: MouseEvent) {
    const target = event.target as HTMLElement; 
    const locationNameElement = target.closest('.fa-location');
    const mapElement = target.closest('.content-map');
  
    if (!locationNameElement && !mapElement) {
      this.showMap = false;
      this.itemLocation = null;
    }
  }
}
