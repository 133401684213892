import { IOptionalItem, PriceType } from './../../model/optional-item';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-optional-item',
  templateUrl: './optional-item.component.html',
  styleUrls: ['./optional-item.component.scss']
})
export class OptionalItemComponent {

  @Input() item: IOptionalItem | undefined;
  @Input() checkbox: boolean = true;
  @Output() checkItem: EventEmitter<string> = new EventEmitter();

  PriceType = PriceType;

  onCheck() {
    this.checkItem.emit(this.item.optionalItemId);
  }
}
