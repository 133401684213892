<div class="contact-us-card">
    <!-- <div class="contact_us_card__item">
        <h2>CONTACT US</h2>
        <p class="contact_note">Please feel free to contact us with the following information</p>
    </div>
    <div class="contact_us_card__item">
        <h3>OFFICE HOURS</h3>
        <p class="office_hours">Mon - Sat <span>9:00am - 5:30pm</span></p>
    </div>
    <div class="contact_us_card__item">
        <p><strong>TEL</strong><span>2216 9886</span></p>
        <p><strong>FAX</strong><span>2753 6768</span></p>
        <p><strong>EMAIL</strong><span style="text-decoration: underline
        ;">motorleasing@dchml.com.hk</span></p>
    </div> -->
    <div class="ck-content" [innerHTML]="content"></div>
</div>