import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { NotificationsService } from '../notifications/notifications.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class CustomInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
    private authService: AuthService,
    private notificationService: NotificationsService,
    private translocoService: TranslocoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const currentLang = localStorage.getItem('currentLang') || 'EN'
    if (token) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json',
          'Accept-Language': currentLang,
        }
      });
    } else {
      req = req.clone({
        setHeaders: {
          'Content-type': 'application/json',
          'Accept-Language': currentLang,
        }
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.authService.logout();
          this.notificationService.showAlerts('Login was expired', 401);
          this.router.navigate(['/sign-in']);
        }
        return throwError(error);
      })
    );
  }
}
