import { Component, OnInit } from '@angular/core';
import { FooterService } from 'src/app/services/footer/footer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss'],
})
export class FooterNewComponent implements OnInit {
  backgroundColor: string;
  isShow: boolean = true;

  urlFooterBlacklist = ['/car-optional-item'];

  constructor(private footerService: FooterService, private router: Router) {
    this.router.events.subscribe(() => {
      // if (this.urlFooterBlacklist.includes(this.router.url.toString())) {
      //   this.isShow = false;
      // } else {
      //   this.isShow = true;
      // }
    });
  }

  ngOnInit() {
    this.footerService.getBackgroundColor().subscribe((color) => {
      this.backgroundColor = color;
    });
  }
}
