<div *transloco="let t" style="justify-content: space-between;display: flex;" class="mb-3">
  <div class="booking-id" *transloco="let t">{{order?.orderNo ? t('booking_ID') + ': ' + order?.orderNo : ''}}</div>
  <a *ngIf="showMoreDetail" routerLink="/order-detail/{{order._id}}">{{t('moreDetail')}}</a>
</div>
<ng-container *ngIf="screenDisplay === 'mobile'">
  <div class="row" *transloco="let t">
    <div class="col-md-12 order-summary">
      <div class="row order-summary-info">
        <div class="col-md-12">
          <p class="text-label">{{t('country')}}</p>
          <p class="text-value">{{order.place.region.name}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('pickupLocation')}}
          </p>
          <p class="text-value">{{order.place.pickupLocation.name}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('returnTo')}}
          </p>
          <p class="text-value">{{order.place.dropOffLocation.name}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('pickupTime')}}
          </p>
          <p class="text-value">{{pickupDate}}, {{order.place.pickupTimePrototype}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('dropOffTime')}}
          </p>
          <p class="text-value">{{dropOffDate}}, {{order.place.dropOffTimePrototype}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row car-info" *transloco="let t">
    <div class="col-md-6 left">
      <img *ngIf="order.carGrade.images.length" [src]="order.carGrade.images[0]" alt="Car Image" class="car-img">
    </div>
    <div class="col-md-6 right">
      <h4 class="car-name">{{ order.carGrade.name }}</h4>
      <h6 class="car-model" *ngIf="order.carGrade.carModels">{{
        order.carGrade.carModels[0].carModelName}}
        {{t('orSimilar')}}</h6>
      <div class="car-feature">
        <i *ngIf="order.carGrade.gearbox">
          <img src="assets/images/rac/steering_wheel.svg">
          <label class="size-label-icon">{{t(order.carGrade.gearbox)}}</label>
        </i>
        <i *ngIf="order.carGrade.doors">
          <img src="assets/images/rac/car_door.svg">
          <label class="size-label-icon">{{order.carGrade.doors}} {{t('doors')}}</label>
        </i>
        <i class="fa-solid fa-user" *ngIf="order.carGrade.seats"><label class="size-label-icon">{{order.carGrade.seats}}
            {{t('seats')}}</label>
        </i>
        <i class="fas fa-briefcase" *ngIf="order.carGrade.largeSuitcases"><label
            class="size-label-icon">{{order.carGrade.largeSuitcases}}
            {{t('largeSuitcase')}}</label></i>
        <i class="fas fa-briefcase" *ngIf="order.carGrade.smallSuitcases"><label
            class="size-label-icon">{{order.carGrade.smallSuitcases}}
            {{t('smallSuitcases')}}</label></i>
        <i class="fa-regular fa-snowflake" *ngIf="order.carGrade.hasAirConditional"><label
            class="size-label-icon">{{t('airConditioning')}}</label></i>
        <i class="fa-solid fa-gas-pump" *ngIf="order.carGrade.fuel"><label
            class="size-label-icon">{{t(order.carGrade.fuel)}}</label>
        </i>
      </div>
    </div>
  </div>
  <div class="row order-summary-info extra" *transloco="let t">
    <h3>
      {{t('recommendedExtras')}}
    </h3>
    <div *ngIf="optionalItems.length" class="row">
      <ng-container *ngFor="let val of optionalItems">
        <p-checkbox [binary]="true" [(ngModel)]="val.checked" (onChange)="calculateTotal()"></p-checkbox>
        <!-- <div class="radio-button">
        </div> -->
        <div class="col-md-8 extra-desc">
          <h6>
            {{val.optionalItemName}}
          </h6>
          <p *ngIf="val.sanitizedDescription; else descriptionBlock" [innerHTML]="val.sanitizedDescription"></p>
          <ng-template #descriptionBlock>
            <p>{{ val.description }}</p>
          </ng-template>
        </div>
        <div class="col-md-4 button-box">
          <button class="btn__primary select_car">{{val.originalCurrencyName}} {{val.originalPrice}} {{ val.priceType == "DAY_PRICE" ? "/day" : "" }} </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row order-price" *transloco="let t">
    <div class="col-md-6 order-days">
      <p class="text-order-price">{{ t('carRentalFee') }} x {{ order.totalDays }} {{ t('days') }}
      </p>
      <ng-container *ngFor="let val of optionalItemsChecked">
        <p class="text-order-price" *transloco="let t">
          {{ val.optionalItemName }} 
          {{ val.priceType == "DAY_PRICE" ? ( "x " + order.totalDays + " " + t('days') ) : "" }}
        </p>
      </ng-container>
      <div class="text-order-price">{{ t('estimatedTotal') }}</div>
    </div>
    <div class="col-md-2 order-price-total">
      <p>{{ order.carGrade.systemCurrencyCode }} {{ order.carGrade.estimatedPrice + optionalItemPrices }}</p>
    </div>
    <div class="col-md-4">
      <p-button class="button-confirm" (onClick)="onConfirm()" label="{{t('confirm')}}"></p-button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="screenDisplay === 'desktop'">
  <div class="row" *transloco="let t">
    <div class="col-md-6 order-summary">
      <div class="row order-summary-info">
        <div class="col-md-12">
          <p class="text-label">{{t('country')}}</p>
          <p class="text-value">{{order.place.region.name}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('pickupLocation')}}
          </p>
          <p class="text-value">{{order.place.pickupLocation.name}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('returnTo')}}
          </p>
          <p class="text-value">{{order.place.dropOffLocation.name}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('pickupTime')}}
          </p>
          <p class="text-value">{{pickupDate}}, {{order.place.pickupTimePrototype}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            {{t('dropOffTime')}}
          </p>
          <p class="text-value">{{dropOffDate}}, {{order.place.dropOffTimePrototype}}</p>
        </div>
      </div>
      <div class="row order-summary-info extra">
        <h3>
          {{t('recommendedExtras')}}
        </h3>
        <div *ngIf="optionalItems.length" class="pt-2">
          <ng-container *ngFor="let val of optionalItems">
            <div class="row mx-0 optional-item-container">
              <p-checkbox [binary]="true" [(ngModel)]="val.checked" (onChange)="calculateTotal()" class="radio-button p-0"></p-checkbox>
              <!-- <div class="radio-button p-0">
              </div> -->
              <div class="col-md-8 extra-desc">
                <h6>
                  {{val.optionalItemName}}
                </h6>
                <p *ngIf="val.sanitizedDescription; else descriptionBlock" [innerHTML]="val.sanitizedDescription"></p>
                <ng-template #descriptionBlock>
                  <p>{{ val.description }}</p>
                </ng-template>
              </div>
              <div class="col-md-4 button-box">
                <button class="btn__primary select_car">{{val.systemCurrencyName}} {{val.price}} {{ val.priceType == "DAY_PRICE" ? "/day" : "" }}</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>

    <div class="col-md-6 car-info-container">
      <div class="row car-info">
        <div class="col-md-6 left">
          <img *ngIf="order.carGrade.images.length" [src]="order.carGrade.images[0]" alt="Car Image" class="car-img">
        </div>
        <div class="col-md-6 right">
          <h4 class="car-name" style="padding-left: 10px;">{{ order.carGrade.name }}</h4>
          <h6 class="car-model" *ngIf="order.carGrade.carModels" style="padding-left: 10px;">{{
            order.carGrade.carModels[0].carModelName}}
            {{t('orSimilar')}}</h6>
          <div class="car-feature">
            <i *ngIf="order.carGrade.gearbox">
              <img src="assets/images/rac/steering_wheel.svg">
              <label class="size-label-icon">{{t(order.carGrade.gearbox)}}</label>
            </i>
            <i *ngIf="order.carGrade.doors">
              <img src="assets/images/rac/car_door.svg">
              <label class="size-label-icon">{{order.carGrade.doors}} {{t('doors')}}</label>
            </i>
            <i class="fa-solid fa-user" *ngIf="order.carGrade.seats"><label
                class="size-label-icon">{{order.carGrade.seats}}
                {{t('seats')}}</label>
            </i>
            <i class="fas fa-briefcase" *ngIf="order.carGrade.largeSuitcases"><label
                class="size-label-icon">{{order.carGrade.largeSuitcases}}
                {{t('largeSuitcase')}}</label></i>
            <i class="fas fa-briefcase" *ngIf="order.carGrade.smallSuitcases"><label
                class="size-label-icon">{{order.carGrade.smallSuitcases}}
                {{t('smallSuitcases')}}</label></i>
            <i class="fa-regular fa-snowflake" *ngIf="order.carGrade.hasAirConditional"><label
                class="size-label-icon">{{t('airConditioning')}}</label></i>
            <i class="fa-solid fa-gas-pump" *ngIf="order.carGrade.fuel"><label
                class="size-label-icon">{{t(order.carGrade.fuel)}}</label>
            </i>
          </div>
        </div>
      </div>
      <div class="row order-price">
        <div class="col-md-6 order-days">
          <p class="text-order-price" *transloco="let t">{{ t('carRentalFee') }} x {{ order.totalDays }} {{ t('days') }}
          </p>
          <ng-container *ngFor="let val of optionalItemsChecked">
            <p class="text-order-price" *transloco="let t">
              {{ val.optionalItemName }} 
              {{ val.priceType == "DAY_PRICE" ? ( "x " + order.totalDays + " " + t('days') ) : "" }}
            </p>
          </ng-container>
          <div class="text-order-price" *transloco="let t">{{ t('estimatedTotal') }}</div>
        </div>
        <div class="col-md-2 order-price-total">
          <p>{{ order.carGrade.systemCurrencyCode }} {{ order.carGrade.estimatedPrice + optionalItemPrices }}</p>
        </div>
        <div class="col-md-4">
          <p-button class="button-confirm" (onClick)="onConfirm()" label="{{t('confirm')}}"></p-button>
        </div>
      </div>
    </div>

  </div>
</ng-container>