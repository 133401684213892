export const environment = {
  "production": false,
  "api": "https://uat-api.dchml.com.hk/api/",
  "host": "0.0.0.0",
  "port": 3033,
  "facebookAppId": "615967776044204",
  "mapbox": {
    "accessToken": "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
  },
  "configApiSendOTP": {
    "apiSendOTP": "https://basic.accessyou-api.com/sms/sendsms-utf8.php",
    "accountno": "11034438",
    "pwd": "96868415"
  },
  "configOTP": {
    "issuer": "emma",
    "apiUrl": "https://api.emma.hk/sms/APIServiceMulti",
    "username": "dch-motorleasing-otp",
    "password": "dmlo@2190"
  },
  "timeoutVerifyOtp": 60,
  "staticPath": "https://dch-motor-uat-motor-leasing-content-01.s3.ap-east-1.amazonaws.com/",
  "mapApiKey": "5WlTxhFG98FfJR5hJoudvAAtdMqRJ2RA",
  "secretKey": "DCH-MLS@2022",
  "urlSocket": "https://uat-api.dchml.com.hk",
  "googleMapApiKey": "AIzaSyCKQH2JJBdejJ8a1ofXPLzS745Fx96Klsg",
  "recaptcha": {
    "siteKey": "6LfgIhsmAAAAAGvKzDL2t_uS5AQbjf4FrUbJTNhC",
    "secretKey": "6LfgIhsmAAAAAMV-byqwguuVYgccF9X4L2aCqF5P"
  },
  "paymentUrl": "https://uat-www.dchml.com.hk/order-detail",
  "checkOutPaymentUrl": "https://ap-gateway.mastercard.com/checkout/version/62/checkout.js",
  "envName": "uat"
}
