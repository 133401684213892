<form [formGroup]="pickerTimeForm">
    <select class="form-control select-time" formControlName="pickTime" (change)="onChangeItem($event.target)">
        <option
            *ngFor="let time of timePicker"
            [value]="time.value"
        >
            {{ time.name }}
        </option>
    </select>
    <small class="text-danger">{{label}} is required.</small>
</form>