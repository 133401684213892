import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStaticCarShareResponse } from '../../model/car-share';
import { environment } from '../../../environments/environment';
import { ILimoPriceResponse, ILimoZoneResponse } from 'src/app/model/zone';
import { s3Static } from '../s3-files/s3-files.service';
import { IRentalFleetsResponse } from 'src/app/model/rate-card';
import { IOverseasResponse } from 'src/app/model/overseas';
import { ILimoActiveLocationHK } from 'src/app/model/limo';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class OverseasService {

  url: string = `${environment.api}web/static-page/`;

  constructor(private http: HttpClient) { }

  getDetail(type: string): Observable<IOverseasResponse> {
    return this.http.get<IOverseasResponse>(`${this.url}rac-limo-page/get-by-page-type?pageType=${type}`);
  }

  getRentalFleet(): Observable<IRentalFleetsResponse> {
    return this.http.get<IRentalFleetsResponse>(`${environment.api}web/get-car-grades`);
  }

  getImages(endpoint: string): string {
    return `${s3Static}` + endpoint;
  }

  getLimoZoneByType(type: string): Observable<ILimoZoneResponse> {
    return this.http.get<ILimoZoneResponse>(`${this.url}limo-zone/get-by-type?type=${type}`, httpOptions);
  }

  getLimoZoneToZonePrice(limoZoneAId: string, limoZoneBId: string, type: string, additoinalLocationId: string): Observable<ILimoPriceResponse> {
    const withAdditionalLocation = (additoinalLocationId != null && type != 'DISTRICT') ? `&extraAreaId=${additoinalLocationId}` : ''
    return this.http.get<ILimoPriceResponse>(`${this.url}limo-zone-to-zone/price/get-by-type?limoZoneAId=${limoZoneAId}&limoZoneBId=${limoZoneBId}&type=${type}${withAdditionalLocation}`, httpOptions);
  }

  getLimoZoneByArea(limoZoneId: string): Observable<ILimoZoneResponse> {
    return this.http.get<ILimoZoneResponse>(`${this.url}limo-area/get-by-zone?limoZoneId=${limoZoneId}`, httpOptions);
  }

  getActiveLocationHK(): Observable<ILimoActiveLocationHK> {
    return this.http.get<ILimoActiveLocationHK>(`${this.url}limo-zone`, httpOptions);
  }
}
