<div class="car-item" *transloco="let t">
  <div class="row car-item-row" *ngIf="car">
    <div class="col-md-4 car-img-container">
      <img [src]="car.images[0]" alt="car-rental">
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-8 car-info-container">
          <h4 class="car-name">{{ car.name }}</h4>
          <h6 class="car-model" *ngIf="car.carModels">{{ car.carModels[0]?.carModelName }} or similar</h6>
          <div class="car-feature">
            <i *ngIf="car.gearbox">
              <img src="assets/images/rac/steering_wheel.svg">
              <label class="size-label-icon">{{t(car.gearbox)}}</label>
            </i>
            <i *ngIf="car.doors">
              <img src="assets/images/rac/car_door.svg">
              <label class="size-label-icon">{{car.doors}} {{t('doors')}}</label>
            </i>
            <i class="fa-solid fa-user" *ngIf="car.seats"><label class="size-label-icon">{{car.seats}}
                {{t('seats')}}</label>
            </i>
            <i class="fas fa-briefcase" *ngIf="car.largeSuitcases"><label class="size-label-icon">{{car.largeSuitcases}}
                {{t('largeSuitcase')}}</label></i>
            <i class="fas fa-briefcase" *ngIf="car.smallSuitcases"><label class="size-label-icon">{{car.smallSuitcases}}
                {{t('smallSuitcases')}}</label></i>
            <i class="fa-regular fa-snowflake" *ngIf="car.hasAirConditional"><label
                class="size-label-icon">{{t('airConditioning')}}</label></i>
            <i class="fa-solid fa-gas-pump" *ngIf="car.fuel"><label class="size-label-icon">{{t(car.fuel)}}</label>
            </i>
          </div>
          <div class="" *ngIf="car.provider">
            <div (mouseenter)="onMouseOver(car.pickUpLocation)" (mouseleave)="onMouseOut($event)">
              <i class="fas fa-location" >
                <label class="size-label-icon">{{ car.pickUpLocation.name }}</label>
              </i>
              <div class="size-label-icon address">{{ car.pickUpLocation.address }}</div>
              <div *ngIf="showMap" class="content-map">
                <p style="font-size: 20px; font-weight: bold">
                  Location: {{ car.pickUpLocation.name }}
                </p>
                <p style="font-size: 14px">
                  Address: {{ car.pickUpLocation.address }}
                </p>
                <p-gmap (onMapReady)="setMap($event)" [options]="itemLocation?.options"
                  [overlays]="itemLocation?.overlays" [style]="{
                    width: '100%',
                    height: '320px',
                    'margin-top': '30px'
                  }"></p-gmap>
              </div>
            </div>
            <img [src]="car.provider.logo" alt="car-rental" style="width: 50%;">
          </div>
        </div>
        <div class="col-md-4 price-container">
          <h4 class="car-price">{{ car.systemCurrencyCode }} {{ car.estimatedPrice | number }}</h4>
          <button (click)="onSelectCar()" class="btn__primary select_car">{{t('select')}}</button>
        </div>
      </div>
    </div>
  </div>

</div>