<div class="contact-us-card">
    <div class="contact_us_card__item">
        <h2>GENERAL ENQUIRY</h2>
    </div>
    <div class="contact_us_card__item">
        <P>2/F, DCH Building, 20 Kai Cheung Road, Kowloon Bay</P>
    </div>
    <div class="contact_us_card__item">
        <p><strong>TEL</strong><span>2216 9886</span></p>
        <p><strong>FAX</strong><span>2753 6768</span></p>
        <p><strong>EMAIL</strong><span style="text-decoration: underline
        ;">motorleasing@dchml.com.hk</span></p>
    </div>
</div>