import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Socket } from 'ngx-socket-io';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { IEmergencyMessage } from './model/emergency-message';
import { CarService } from './services/car/car.service';
import { OrderService } from './services/order/order.service';
import { EMERGENCY_MESSAGE_TYPE } from './shared/constant/constant';
import { S3FilesDomainService } from './services/s3-files/s3-files.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  beforeunload() {
    const order = this.orderService.getOrderData();
    if (order && order?.selectedCarGrade) {
      const body = {
        tempBookingNo: order?.carGrade?.tempBookingNo,
        pickupTime: order?.place.pickupTime,
        dropOffTime: order?.place.dropOffTime,
        mlsVehicleLicNo: order?.carGrade?.mlsVehicleLicNo,

        tempBookingNoOld: order?.selectedCarGrade?.tempBookingNo || '',
        pickupTimeOld: order?.selectedCarGrade?.pickupTime || '',
        dropOffTimeOld: order?.selectedCarGrade?.dropOffTime || '',
        mlsVehicleLicNoOld: order?.selectedCarGrade?.mlsVehicleLicNo || '',
      };
      this.carService.selectCar(body).subscribe();
    }
    var start = new Date().getTime();
    while (new Date().getTime() < start + 1000);
    localStorage.removeItem('order');
  }

  title = 'CMS';
  titleNotify: string = '';
  display: boolean = false;
  contentNotify: string = '';
  classNameOfDialog: string = 'success-dialog';
  currentLang = localStorage.getItem('currentLang') || 'EN';
  constructor(
    private translate: TranslateService,
    private socket: Socket,
    private messageService: MessageService,
    private orderService: OrderService,
    private carService: CarService,
    private fileService: S3FilesDomainService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.getMessage();
    this.fileService.getFileDomain();
    this.translocoService.setActiveLang(this.currentLang);
    // this.translocoService.selectTranslation().subscribe((trans) => {
    //   const selectedLang = trans.transloco_current_lang;
    //   if (this.currentLang != selectedLang) {
    //     this.currentLang = selectedLang;
    //     localStorage.setItem('currentLang', selectedLang);
    //     location.reload();
    //   }
    // });
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

  getMessage() {
    return this.socket
      .fromEvent('emergencyMessage')
      .pipe(map((data) => data))
      .subscribe({
        next: (response: IEmergencyMessage) => {
          console.log('getMessage response---', response);
          if (response) {
            // Handle for show popup on top right page
            if (response.displayType === 'Static Bar') {
              switch (response.messageType) {
                case EMERGENCY_MESSAGE_TYPE.INFORMATION:
                  this.showStatusBarMessage('success', response);
                  break;
                case EMERGENCY_MESSAGE_TYPE.WARNING:
                  this.showStatusBarMessage('warn', response);
                  break;
                case EMERGENCY_MESSAGE_TYPE.ERROR:
                  this.showStatusBarMessage('error', response);
                default:
                  break;
              }
            } else {
              // Handle for show modal
              switch (response.messageType) {
                case EMERGENCY_MESSAGE_TYPE.INFORMATION:
                  this.classNameOfDialog = 'success-dialog';
                  break;
                case EMERGENCY_MESSAGE_TYPE.WARNING:
                  this.classNameOfDialog = 'warning-dialog';
                  break;
                case EMERGENCY_MESSAGE_TYPE.ERROR:
                  this.classNameOfDialog = 'error-dialog';
                default:
                  break;
              }
              this.display = true;
              this.contentNotify = response.content;
              this.titleNotify = response.title;
            }
          }
        },
        error: (err) => {
          console.log('error---', err);
        },
      });
  }

  showStatusBarMessage(severity: string, data: IEmergencyMessage) {
    this.messageService.clear();
    this.messageService.add({
      severity,
      summary: data.title,
      detail: data.content,
      sticky: true,
    });
  }

  onCloseModal() {
    this.display = false;
  }
}
