import { ISearchParams, ISelectCarParams } from './../../model/car';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICarSearchResult } from 'src/app/model/car-selection';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  url: string = `${environment.api}web`;

  constructor(private http: HttpClient) { }

  searchCars(params: ISearchParams, limit?: number, offset?: number): Observable<ICarSearchResult> {
    let queryString = '';
    const { carGrades, prices, ...otherParams } =  params;
    Object.keys(otherParams).forEach(key => {
      if (otherParams[key]) {
        queryString += `&${key}=${otherParams[key]}`;
      }
    });
    carGrades.forEach(grade => {
      queryString += `&carGrades[]=${grade}`;
    });
    prices.forEach(price => {
      queryString += `&prices[]=${price}`;
    });

    if (limit && offset >= 0) {
      queryString += `limit=${limit}&offset=${offset}`;
    }
    return this.http.get<ICarSearchResult>(`${this.url}/order/search-cars?${queryString}`);
  }

  searchCarsNicoNico(params: ISearchParams, limit?: number, offset?: number): Observable<ICarSearchResult> {
    let queryString = '';
    const { carGrades, prices, ...otherParams } =  params;
    Object.keys(otherParams).forEach(key => {
      if (otherParams[key]) {
        queryString += `&${key}=${otherParams[key]}`;
      }
    });
    carGrades.forEach(grade => {
      queryString += `&carGrades[]=${grade}`;
    });
    prices.forEach(price => {
      queryString += `&prices[]=${price}`;
    });

    if (limit && offset >= 0) {
      queryString += `limit=${limit}&offset=${offset}`;
    }
    return this.http.get<ICarSearchResult>(`${this.url}/order/search-cars-niconico?${queryString}`);
  }

  selectCar(params: ISelectCarParams): Observable<ISelectCarParams> {
    return this.http.post<ISelectCarParams>(`${this.url}/order/select-car`, params);
  }

  cancelOrder(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/order/cancel?id=${id}`);
  }

  releaseCar(params: ISelectCarParams): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}/order/release-car`, params);
  }
}
