import { PriceType } from './../../model/optional-item';
import { DiscountType, IOrderData } from 'src/app/model/order';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  @Input()
  order: IOrderData | undefined;
  @Output() rateTerms: EventEmitter<boolean> = new EventEmitter();

  PriceType = PriceType;
  DiscountType = DiscountType;

  isShowRateTerms: boolean = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.router.url === ROUTE.LOOKUP) {
      this.isShowRateTerms = false;
    }
  }

  onClick() {
    this.rateTerms.emit();
  }
}
