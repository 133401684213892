import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ILimoPriceResponse, ILimoZoneResponse } from 'src/app/model/zone';
import { IChinaLimoPriceResponse, IChinaLimoResponse, ILimoCarSearchParams, ILimoCarSearchResponse, IStaticLimoResponse } from 'src/app/model/limo';
import { s3Static } from '../s3-files/s3-files.service';
import { ICorporate } from 'src/app/model/corporate';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LimoService {

  url: string = `${environment.api}web/static-page/`;

  constructor(private http: HttpClient) { }

  getDetail(type: string): Observable<IStaticLimoResponse> {
    return this.http.get<IStaticLimoResponse>(`${this.url}rac-limo-page/get-by-page-type?pageType=${type}`);
  }

  getImages(endpoint: string): string {
    return `${environment.staticPath}` + endpoint;
    return `${s3Static}` + endpoint;
  }

  getLimoZoneByType(type: string): Observable<ILimoZoneResponse> {
    return this.http.get<ILimoZoneResponse>(`${this.url}limo-zone/get-by-type?type=${type}`, httpOptions);
  }

  getLimoZoneToZonePrice(limoZoneAId: string, limoZoneBId: string, type: string, additoinalLocationId: string): Observable<ILimoPriceResponse> {
    const withAdditionalLocation = (additoinalLocationId != null && type != 'DISTRICT') ? `&extraAreaId=${additoinalLocationId}` : '';
    return this.http.get<ILimoPriceResponse>(`${this.url}limo-zone-to-zone/price/get-by-type?limoZoneAId=${limoZoneAId}&limoZoneBId=${limoZoneBId}&type=${type}${withAdditionalLocation}`, httpOptions);
  }

  getLimoZoneByArea(limoZoneId: string): Observable<ILimoZoneResponse> {
    return this.http.get<ILimoZoneResponse>(`${this.url}limo-area/get-by-zone?limoZoneId=${limoZoneId}`, httpOptions);
  }

  getLimoChineseList(): Observable<IChinaLimoResponse> {
    return this.http.get<IChinaLimoResponse>(`${this.url}limo-chinese/list?isActive=true`);
  }

  getLimoChinesePriceDetail(limoChineseId: string): Observable<IChinaLimoPriceResponse> {
    return this.http.get<IChinaLimoPriceResponse>(`${this.url}limo-chinese-rate/list?limoChineseId=${limoChineseId}`);
  }

  onSubmitEnquiryForm(data: ICorporate): Observable<ICorporate> {
    return this.http.post<ICorporate>(environment.api + 'web/customer/enquiry', { ...data, from: 'Limousine' });
  }

  searchCars(params: ILimoCarSearchParams, limit?: number, offset?: number): Observable<ILimoCarSearchResponse> {
    let queryString = '';
    const { ...otherParams } =  params;
    
    Object.keys(otherParams).forEach(key => {
      if (otherParams[key]) {
        queryString += `&${key}=${otherParams[key]}`;
      }
    });

    if (limit && offset >= 0) {
      queryString += `limit=${limit}&offset=${offset}`;
    }

    return this.http.get<ILimoCarSearchResponse>(`${environment.api}web/order/search-limo-cars?${queryString}`);
  }
}
