import { ICarGradeItem } from './../../model/car-selection';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-car-detail',
  templateUrl: './car-detail.component.html',
  styleUrls: ['./car-detail.component.scss']
})
export class CarDetailComponent {

  @Input() carGrade: ICarGradeItem | undefined;

}
