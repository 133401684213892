<ul *transloco="let t">
    <li>
        <img src="assets/images/sm_1.png">
        <p>{{t('features_1')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_2.png">
        <p>{{t('features_2')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_3.png">
        <p>{{t('features_3')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_4.png">
        <p>{{t('features_4')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_5.png">
        <p>{{t('features_5')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_6.png">
        <p>{{t('features_6')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_7.png">
        <p>{{t('features_7')}}</p>
    </li>
    <li>
        <img src="assets/images/sm_8.png">
        <p>{{t('features_8')}}</p>
    </li>
</ul>