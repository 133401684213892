import { Component, Input, OnInit } from '@angular/core';
import { IContactUsAdTruck } from 'src/app/model/contact-us';

@Component({
  selector: 'app-contact-us-new',
  templateUrl: './contact-us-bottom-new.html',
  styleUrls: ['./contact-us-bottom-new.scss'],
})
export class ContactUsBottomNewComponent implements OnInit {

  @Input() contactData?: IContactUsAdTruck;

  contactUsData: IContactUsAdTruck = {
    email: 'motorleasing@dchml.com.hk',
    contactNo: '2216 9888 /  2753 6593 [FAX]',
    address: '2/F, DCH Building, 20 Kai Cheung Road, Kowloon Bay'
  };

  ngOnInit() {
    this.contactUsData = this.contactData ? this.contactData : this.contactUsData;
  }

}
