/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private backgroundColorSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('rgb(245, 240, 241)');

  setBackgroundColor(color: string) {
    this.backgroundColorSubject.next(color);
  }

  getBackgroundColor(): Observable<string> {
    return this.backgroundColorSubject.asObservable();
  }
}
