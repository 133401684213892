export interface IOptionalItem {
  description: string;
  optionalItemId: string;
  optionalItemName: string;
  price: number;
  priceType: PriceType;
  systemCurrencyId: string;
  systemCurrencyName: string;
  checked?: boolean;
  originalCurrencyName: string;
  originalPrice: number;
  totalDays?: number;
}

export enum PriceType {
  DAY_PRICE = 'DAY_PRICE',
  FIXED_PRICE = 'FIXED_PRICE',
}
