<div class="row justify-content-start justify-content-md-center mt-3" *ngIf="place">
  <strong class="mb-1" *transloco="let t">{{ t('region') }}: {{ place.region.name }}</strong>
  <div class="wrapper-content">
    <div class="left">
      <div class="title-color" *transloco="let t">{{ t("pickUp") }}</div>
      <div class="sub-title" *transloco="let t">{{ place.pickupLocation?.name || '--' }} 
        <i tooltipPosition="bottom"
        [escape]="false" pTooltip="<div style='font-family: Open Sans,Arial,sans-serif;padding-left: 10px;padding-right: 10px; color: #000 !important;background: white; border: 1px solid #ccc;'>
          <label style='color: #d4002a; margin-bottom: 10px;font-weight: 600;'>{{ t('pickUp') }}</label>
          <label style='font-weight: bold;'>{{ place.pickupLocation?.name }}</label>
          <div>{{place.pickupLocation?.address}}</div>
        </div>" 
        class="fa-solid fa-circle-info"></i>
      </div>
      <div class="label-time">{{ place.pickupTime | date: DATE_TIME_FORMAT }}</div>
    </div>
    <div class="right">
      <div class="title-color" *transloco="let t">{{  t("return") }}</div>
      <div class="sub-title"  *transloco="let t">{{ place.dropOffLocation?.name || '--' }} 
        <i tooltipPosition="bottom" *ngIf="place.dropOffLocation"
        [escape]="false" pTooltip="<div style='font-family: Open Sans,Arial,sans-serif;padding-left: 10px;padding-right: 10px; color: #000 !important;background: white; border: 1px solid #ccc;'>
          <label style='color: #d4002a; margin-bottom: 10px;font-weight: 600;'>{{  t('return') }}</label>
          <label style='font-weight: bold;'>{{ place.dropOffLocation?.name }}</label>
          <div>{{place.dropOffLocation?.address}}</div>
        </div>" 
        class="fa-solid fa-circle-info"></i>
      </div>
      <div class="label-time">{{ place.dropOffTime ? (place.dropOffTime | date: DATE_TIME_FORMAT) : '--' }}</div>
    </div>
    <div *ngIf="isShowIconPen" (click)="onEditLocation()" class="pen">
      <i class="fa-solid fa-pen"></i>
    </div>
  </div>
</div>


<p-dialog [header]="'modifyRentalDetails' | transloco" [modal]="true" [(visible)]="showModifyLocation">
  <form *ngIf="rentCarForm && currentRentType=== RentType.RentCar" [formGroup]="rentCarForm" class="d-grid gap-3 m-3" (ngSubmit)="onSubmit()">
    <p class="title-detail">Pickup Details</p>
    <div class="d-flex row">
      <div class="col-md-6 nopadding">
        <div class="p-inputgroup w-100 flex-wrap" *transloco="let t">
          <span class="p-inputgroup-addon bg-transparent"><i class="pi pi-map"></i></span>
          <p-autoComplete #pickup [suggestions]="locations$ | async"
            (completeMethod)="onSearchLocation($event.query)"
            formControlName="pickupLocation"
            field="name"
            [forceSelection]="true"
            class="d-flex w-0"
            appendTo="body"
            placeholder="{{ t('pickupLocation') }}"
            [showEmptyMessage]="true"></p-autoComplete>
            <p-progressSpinner
              *ngIf="locations$ === undefined && pickup.focus"
              [style]="{width: '30px', height: '30px'}">
            </p-progressSpinner>
        </div>
      </div>
      <div class="col-md-3 nopadding">
        <p-calendar
          placeholder="Pick-up Date"
          formControlName="pickupDate"
          styleClass="d-flex"
          [showIcon]="true"
          appendTo="body"
          (onSelect)="onSelectPickupDate($event)"
          [minDate]="pickupMinDate"
          icon="fas fa-calendar-alt"
          [dateFormat]="DATE_PICKER_FORMAT"
          inputId="pickup-date">
        </p-calendar>
        <small class="text-danger">Pickup date is required.</small>
      </div>
      <div class="col-md-3 nopadding">
        <app-time-picker label="Pick-up Time" [defaultValue]="rentCarForm.controls['pickupTime'].value" (selected)="onSelectPickupTime($event)"></app-time-picker>
      </div>
    </div>
    <p class="title-detail">Drop-off Details</p>
    <div class="d-flex row">
      <div class="col-md-6 nopadding">
        <div class="p-inputgroup w-100 flex-wrap" *transloco="let t">
          <span class="p-inputgroup-addon bg-transparent"><i class="pi pi-map"></i></span>
          <p-autoComplete #dropoff [suggestions]="locations$ | async"
            (completeMethod)="onSearchLocation($event.query)"
            formControlName="dropOffLocation"
            field="name"
            [forceSelection]="true"
            class="d-flex w-0"
            appendTo="body"
            placeholder="{{ t('returnToSameLocation') }}"
            [showEmptyMessage]="true"></p-autoComplete>
            <p-progressSpinner
              *ngIf="locations$ === undefined && dropoff.focus"
              [style]="{width: '30px', height: '30px'}">
            </p-progressSpinner>
        </div>
      </div>
      <div class="col-md-3 nopadding">
        <p-calendar
          placeholder="Drop-off Date"
          formControlName="dropOffDate"
          styleClass="d-flex"
          [showIcon]="true"
          appendTo="body"
          [minDate]="rentCarForm.get('pickupDate').value"
          icon="fas fa-calendar-alt"
          [dateFormat]="DATE_PICKER_FORMAT"
          inputId="dropoff-date">
        </p-calendar>
          <small class="text-danger">Dropoff Date is required.</small>
      </div>
      <div class="col-md-3 nopadding">
        <app-time-picker label="Drop-off Time" [defaultValue]="rentCarForm.controls['dropOffTime'].value" (selected)="onSelectDropOffTime($event)"></app-time-picker>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-3" *transloco="let t">
        <p-button styleClass="w-100" class="p-button-search" type="submit" label="{{ t('update') }}" [disabled]="rentCarForm.invalid || rentCarForm.pristine"></p-button>
      </div>
    </div>
  </form>
  <form *ngIf="limousineForm && currentRentType === RentType.RentCarWithDriver" [formGroup]="limousineForm" class="d-grid gap-3 m-3" (ngSubmit)="onSubmit()">
    <!-- by time -->
    <div class="row gap-3 gap-md-0" *ngIf="order.timebase === TimeBase.ByTime">
      <div class="col-md-6" style="position: relative;">
        <div class="p-inputgroup w-100 flex-wrap">
          <span class="p-inputgroup-addon bg-transparent"><i class="pi pi-map"></i></span>
          <p-autoComplete #location [suggestions]="locations$ | async"
            (completeMethod)="onSearchLocation($event.query)"
            [formControl]="limousineForm.controls['pickupLocation']"
            field="name"
            [forceSelection]="true"
            class="d-flex w-0"
            appendTo="body"
            placeholder="Location"
            [showEmptyMessage]="true">
          </p-autoComplete>
          <p-progressSpinner
              *ngIf="locations$ === undefined && location.focus"
              [style]="{width: '30px', height: '30px'}">
          </p-progressSpinner>
        </div>
      </div>
      <div class="col-md-6">
        <input type="text" class="w-100 input-address"
          pInputText
          placeholder="Address"
          formControlName="pickupAddress"/>
        <small class="text-danger w-100">Address is required.</small>
      </div>
    </div>
    <!-- by trip -->
    <div class="row gap-3 gap-md-0" *ngIf="order.timebase === TimeBase.ByTrip">
      <div class="col-md-6" style="position: relative;">
        <div class="p-inputgroup w-100 flex-wrap" *transloco="let t">
          <span class="p-inputgroup-addon bg-transparent"><i class="pi pi-map"></i></span>
          <p-autoComplete #pickup [suggestions]="locations$ | async"
            (completeMethod)="onSearchLocation($event.query)"
            [formControl]="limousineForm.controls['pickupLocation']"
            field="name"
            [forceSelection]="true"
            class="d-flex w-0"
            appendTo="body"
            placeholder="{{ t('pickupLocation') }}"
            [showEmptyMessage]="true">
          </p-autoComplete>
          <p-progressSpinner
              *ngIf="locations$ === undefined && pickup.focus"
              [style]="{width: '30px', height: '30px'}">
          </p-progressSpinner>
        </div>
      </div>
      <div class="col-md-6">
        <input type="text" class="w-100 input-address"
          pInputText
          placeholder="Pickup Address"
          formControlName="pickupAddress"/>
        <small class="text-danger w-100">Pickup Address is required.</small>
      </div>
      <div class="col-md-6 mt-0 mt-md-3" style="position: relative;">
          <div class="p-inputgroup w-100 flex-wrap">
            <span class="p-inputgroup-addon bg-transparent"><i class="pi pi-map"></i></span>
            <p-autoComplete #dropoff [suggestions]="locations$ | async"
              (completeMethod)="onSearchLocation($event.query)"
              [formControl]="limousineForm.controls['dropOffLocation']"
              field="name"
              [forceSelection]="true"
              class="d-flex w-0"
              appendTo="body"
              placeholder="Dropoff Location"
              [showEmptyMessage]="true">
            </p-autoComplete>
            <p-progressSpinner
              *ngIf="locations$ === undefined && dropoff.focus"
              [style]="{width: '30px', height: '30px'}">
            </p-progressSpinner>
          </div>
      </div>
      <div class="col-md-6 mt-0 mt-md-3">
        <input type="text" class="w-100 input-address"
          pInputText
          placeholder="Dropoff Address"
          formControlName="dropOffAddress"/>
        <small class="text-danger w-100">Dropoff Address is required.</small>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <p-calendar
          [showIcon]="true"
          placeholder="Pick-up Date"
          formControlName="pickupDate"
          styleClass="d-flex"
          icon="fas fa-calendar-alt"
          [minDate]="pickupMinDate"
          appendTo="body"
          [dateFormat]="DATE_PICKER_FORMAT"
          (onSelect)="onSelectPickupDate($event)"
          inputId="pickup-date">
        </p-calendar>
        <small class="text-danger">Pickup date is required.</small>
      </div>
      <div class="w-50">
        <app-time-picker label="Pick-up Time" [defaultValue]="limousineForm.controls['pickupTime'].value" (selected)="onSelectPickupTime($event)"></app-time-picker>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="order.timebase === TimeBase.ByTime">
          <div class="col-6">
            <p-calendar
              placeholder="Drop-off Date"
              formControlName="dropOffDate"
              styleClass="d-flex"
              [showIcon]="true"
              appendTo="body"
              [minDate]="limousineForm.get('pickupDate').value"
              icon="fas fa-calendar-alt"
              [dateFormat]="DATE_PICKER_FORMAT">
            </p-calendar>
              <small class="text-danger">Dropoff Date is required.</small>
          </div>
          <div class="col-6">
            <app-time-picker label="Drop-off Time" [defaultValue]="limousineForm.controls['dropOffTime'].value" (selected)="onSelectDropOffTime($event)"></app-time-picker>
          </div>
      </ng-container>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-3" *transloco="let t">
        <p-button styleClass="w-100" class="p-button-search" type="submit" label="{{ t('update') }}" [disabled]="limousineForm.invalid || limousineForm.pristine"></p-button>
      </div>
    </div>
  </form>
  
</p-dialog>