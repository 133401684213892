import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./screens/sign-in/sign-in.module').then(
        (module) => module.SignInModule
      ),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./screens/registration/registration.module').then(
        (module) => module.RegistrationModule
      ),
  },
  {
    path: 'home-page',
    loadChildren: () =>
      import('./screens/home-page/home-page.module').then(
        (module) => module.HomePageModule
      ),
  },
  {
    path: 'car-selection',
    loadChildren: () =>
      import('./screens/car-selection/car-selection.module').then(
        (module) => module.CarSelectionModule
      ),
  },
  {
    path: 'limo-car-selection',
    loadChildren: () =>
      import('./screens/limo-car-selection/limo-car-selection.module').then(
        (module) => module.LimoCarSelectionModule
      ),
  },
  {
    path: 'limo-optional-item',
    loadChildren: () =>
      import('./screens/limo-optional-item/limo-optional-item.module').then(
        (module) => module.LimoOptionalItemModule
      ),
  },
  {
    path: 'limo-china',
    loadChildren: () =>
      import('./screens/limo-china/limo-china.module').then(
        (module) => module.LimoChinaModule
      ),
  },
  {
    path: 'limo-china-contact-us',
    loadChildren: () =>
      import('./screens/limo-china-contact-us/limo-china-contact-us.module').then(
        (module) => module.LimoChinaContactUsModule
      ),
  },
  {
    path: 'car-optional-item',
    loadChildren: () =>
      import('./screens/car-optional-item/car-optional-item.module').then(
        (module) => module.CarOptionalItemModule
      ),
  },
  {
    path: 'order-review',
    loadChildren: () =>
      import('./screens/order-review/order-review.module').then(
        (module) => module.OrderReviewModule
      ),
  },
  {
    path: 'order-confirm',
    loadChildren: () =>
      import('./screens/order-confirm/order-confirm.module').then(
        (module) => module.OrderConfirmModule
      ),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./screens/news/news.module').then((module) => module.NewsModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./screens/contact-us/contact-us.module').then(
        (module) => module.ContactUsModule
      ),
  },
  {
    path: 'rent-car',
    loadChildren: () =>
      import('./screens/rent-car/rent-car.module').then(
        (module) => module.RentCarModule
      ),
  },
  {
    path: 'limousine-service',
    loadChildren: () =>
      import('./screens/limousine-service/limousine-service.module').then(
        (module) => module.LimousineServiceModule
      ),
  },
  {
    path: 'overseas',
    loadChildren: () =>
      import('./screens/overseas/overseas.module').then(
        (module) => module.OverseasModule
      ),
  },
  {
    path: 'apron-go',
    loadChildren: () =>
      import('./screens/apron-go/apron-go.module').then(
        (module) => module.ApronGoModule
      ),
  },
  {
    path: 'property',
    loadChildren: () =>
      import('./screens/car-share-property/car-share-property.module').then(
        (module) => module.CarSharePropertyModule
      ),
  },
  {
    path: 'van-logistics',
    loadChildren: () =>
      import('./screens/van-logistics/van-logistics.module').then(
        (module) => module.VanLogisticsModule
      ),
  },
  {
    path: 'advertising-truck',
    loadChildren: () =>
      import('./screens/advertising-truck/advertising-truck.module').then(
        (module) => module.AdvertisingTruckModule
      ),
  },
  {
    path: 'lookup',
    loadChildren: () =>
      import('./screens/lookup/lookup.module').then(
        (module) => module.LookupModule
      ),
  },
  {
    path: 'rate-card',
    loadChildren: () =>
      import('./screens/rate-card/rate-cards.module').then(
        (module) => module.RateCardModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./screens/forgot-password/forgot-password.module').then(
        (module) => module.ForgotPasswordModule
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./screens/terms-of-use/terms-of-use.module').then(
        (module) => module.TermsOfUseModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./screens/reset-password/reset-password.module').then(
        (module) => module.ResetPasswordModule
      ),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./screens/about-us/about-us.module').then(
        (module) => module.AboutUsModule
      ),
  },
  {
    path: 'disclaimer',
    loadChildren: () =>
      import('./screens/disclaimer/disclaimer.module').then(
        (module) => module.DisclaimerModule
      ),
  },
  {
    path: 'long-term-leasing',
    loadChildren: () =>
      import('./screens/long-term-leasing/long-term-leasing.module').then(
        (module) => module.LongTermLeasingModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./screens/user-profile/user-profile.module').then(
        (module) => module.UserProfileModule
      ),
  },
  {
    path: 'order-detail',
    loadChildren: () =>
      import('./screens/order-detail/order-detail.module').then(
        (module) => module.OrderDetailModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./screens/payment-info/payment-info.module').then(
        (module) => module.PaymentInfoModule
      ),
  },
  {
    path: '',
    redirectTo: 'home-page',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
