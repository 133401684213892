import { Observable } from 'rxjs';
import { ICarGradeItem } from './../../model/car-selection';
import { CancelOrderResponse, IOrderData, IOrderDetail, IPromotionCodeParams, IPromotionCodeResponse, ISelectedCarGrade, OrderEnquireResponse } from './../../model/order';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOrderEnquire } from 'src/app/model/order';
import { BrowserService } from '../browser/browser.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  url: string = `${environment.api}web/order`;
  private _localStorage: Storage | undefined;

  constructor(private http: HttpClient, private browserService: BrowserService) {
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }

  saveOrderData(order: IOrderData) {
    this._localStorage?.setItem('order', JSON.stringify(order));
  }

  getOrderData(): IOrderData {
    return JSON.parse(this._localStorage?.getItem('order') || null);
  }

  clearOrderData() {
    this._localStorage?.removeItem('order');
  }

  saveCarGradeToOrder(carGrade: ICarGradeItem) {
    const order: IOrderData = JSON.parse(this._localStorage?.getItem('order')) || null;
    if (order) {
      order.carGrade = carGrade;
      order.totalPrice = carGrade.estimatedPrice;
      order.discountedPrice = carGrade.estimatedPrice;
      order.optionalItems = [],
      this._localStorage?.setItem('order', JSON.stringify(order));
    }
  }

  saveSelectCarGradeToOrder(carGrade: ISelectedCarGrade) {
    const order: IOrderData = JSON.parse(this._localStorage?.getItem('order')) || null;
    if (order) {
      order.selectedCarGrade = carGrade;
      this._localStorage?.setItem('order', JSON.stringify(order));
    }
  }

  enquireOrder(data: IOrderEnquire): Observable<OrderEnquireResponse> {
    return this.http.post<OrderEnquireResponse>(`${this.url}/enquire`, data);
  }

  cancelOrder(id: string): Observable<CancelOrderResponse> {
    return this.http.get<CancelOrderResponse>(`${this.url}/cancel?id=`.concat(id));
  }

  validatePromotionCode(params: IPromotionCodeParams): Observable<IPromotionCodeResponse> {
    return this.http.post<IPromotionCodeResponse>(`${this.url}/validate-promotion-code`, params);
  }

  getOrderDetail(id: string): Observable<IOrderDetail> {
    return this.http.get<IOrderDetail>(`${this.url}?id=${id}`);
  }
}
