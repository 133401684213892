import { Observable } from 'rxjs';
import { ICarGradeItem } from './../../model/car-selection';
import { IOrderData, IOrderDetail, IPromotionCodeParams, IPromotionCodeResponse, ISelectedCarGrade, OrderEnquireResponse } from './../../model/order';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOrderEnquire } from 'src/app/model/order';
import { BrowserService } from '../browser/browser.service';

@Injectable({
  providedIn: 'root'
})
export class LimoHkService {

  url: string = `${environment.api}web/order`;
  private _localStorage: Storage | undefined;

  constructor(private http: HttpClient, private browserService: BrowserService) {
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }

  saveLimoData(limo: any) {
    this._localStorage?.setItem('limo', JSON.stringify(limo));
  }

  getLimoData() {
    return JSON.parse(this._localStorage?.getItem('limo') || null);
  }

  clearLimoData() {
    this._localStorage?.removeItem('limo');
  }

  saveLimoOrderData(limo: any) {
    this._localStorage?.setItem('limoOrder', JSON.stringify(limo));
  }

  getLimoOrderData() {
    return JSON.parse(this._localStorage?.getItem('limoOrder') || null);
  }

  clearLimoOrderData() {
    this._localStorage?.removeItem('limoOrder');
  }

  //   saveCarGradeToOrder(carGrade: ICarGradeItem) {
  //     const order: IOrderData = JSON.parse(this._localStorage?.getItem('order')) || null;
  //     if (order) {
  //       order.carGrade = carGrade;
  //       order.totalPrice = carGrade.estimatedPrice;
  //       order.discountedPrice = carGrade.estimatedPrice;
  //       order.optionalItems = [],
  //       this._localStorage?.setItem('order', JSON.stringify(order));
  //     }
  //   }

  //   saveSelectCarGradeToOrder(carGrade: ISelectedCarGrade) {
  //     const order: IOrderData = JSON.parse(this._localStorage?.getItem('order')) || null;
  //     if (order) {
  //       order.selectedCarGrade = carGrade;
  //       this._localStorage?.setItem('order', JSON.stringify(order));
  //     }
  //   }

  //   enquireOrder(data: IOrderEnquire): Observable<OrderEnquireResponse> {
  //     return this.http.post<OrderEnquireResponse>(`${this.url}/enquire`, data);
  //   }

  //   validatePromotionCode(params: IPromotionCodeParams): Observable<IPromotionCodeResponse> {
  //     return this.http.post<IPromotionCodeResponse>(`${this.url}/validate-promotion-code`, params);
  //   }

//   getOrderDetail(id: string): Observable<IOrderDetail> {
//     return this.http.get<IOrderDetail>(`${this.url}?id=${id}`);
//   }
}
