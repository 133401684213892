import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-terms-condition-dialog',
  templateUrl: './terms-condition-dialog.component.html',
  styleUrls: ['./terms-condition-dialog.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TermsConditionDialog {
  @Input() isDisplayed: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter();

  closeModal() {
    console.log('closing');
    this.onClosed.emit();
  }
}
