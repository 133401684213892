import { IOrderData } from './../../model/order';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { IDropdownItem } from 'src/app/model/general';
import { map, take } from 'rxjs/operators';
import { IOrderEnquire } from 'src/app/model/order';
import { OrderService } from 'src/app/services/order/order.service';
import { PaymentType } from 'src/app/model/car-selection';
import { RentType, TimeBase } from 'src/app/model/home-page';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnDestroy {

  @Input() submitting: boolean = false;
  @Output() submitForm: EventEmitter<IOrderEnquire> = new EventEmitter();

  customerForm: FormGroup | undefined;
  isLoggedIn: boolean = false;
  questions$: Observable<{ title: string, options: IDropdownItem[] }> | undefined;
  areaCodes: string[] = [];
  order: IOrderData | undefined;
  isEnquiry: boolean = false;
  displayTermsAndConditions: Boolean = false;

  PaymentType = PaymentType;

  private _sub: Subscription = new Subscription();

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private customerService: CustomerService,
    private orderService: OrderService,) { }


  ngOnInit(): void {
    this.order = this.orderService.getOrderData();
    this.setupCheckoutButton();
    this.initForm();
    this.getQuestions();
    this.setCustomerForm();
  }

  onSubmit() {
    const { name, surName, email, areaCode, phone, HOW_TO_KNOW_MLS, becomeMember, termAgreeToJoinDCH, termFriendOfDCH, arrivalTime, flightNo } = this.customerForm.value;
    console.log(this.order);
    let data: IOrderEnquire = {
      region: this.order.place.region._id,
      carGrade: this.order.carGrade._id,
      pickupLocation: this.order.place.pickupLocation?._id || '',
      pickupAddress: this.order.place.pickupLocation?.address || '',
      pickupTime: this.order.place.pickupTime,
      dropOffTime: this.order.place.dropOffTime,
      timebase: this.order.timebase,
      rentType: this.order.rentType,
      becomeMember: termAgreeToJoinDCH,
      termFriendOfDCH,
      HOW_TO_KNOW_MLS,
      termAgreeToJoinDCH,
      optionalItems: this.order.optionalItems?.map(x => x.optionalItemId) || [],
      email,
      name,
      surName,
      areaCode,
      phone,
      paymentMethod: this.order.carGrade.paymentMethod,
      specialRequest: this.order.specialRequest,
      airlineId: null,
      flightNo,
      arrivalTime,
      vehicleLicNo: this.order.selectedCarGrade?.mlsVehicleLicNo,
      tempBookingNo: this.order.selectedCarGrade?.tempBookingNo,
      providerName: this.order.carGrade.provider?.name
    };
    if (this.order.timebase === TimeBase.ByTrip || this.order.rentType === RentType.RentCar) {
      data = {
        ...data,
        dropOffLocation: this.order.place.dropOffLocation?._id || '',
        dropOffAddress: this.order.place.dropOffLocation?.address || '',
      };
    }
    if (this.isLoggedIn) {
      data.token = this.authService.getToken();
    }
    this.submitForm.emit(data);
  }

  private initForm() {
    this.customerForm = this.fb.group({
      name: [null, Validators.required],
      surName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      areaCode: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern('[0-9]{4,12}')]],
      HOW_TO_KNOW_MLS: [null, Validators.required],
      arrivalTime: [null],
      flightNo: [null],
      becomeMember: [false],
      termFriendOfDCH: [false],
      agree: [false],
      termAgreeToJoinDCH: [false]
    });
  }

  private setCustomerForm() {
    combineLatest([this.customerService.getAreaCodes(), this.authService.isLoggedIn()])
      .pipe(take(1))
      .subscribe(([codes, isLoggedIn]: [string[], boolean]) => {
        this.areaCodes = codes;
        this.isLoggedIn = isLoggedIn;
        this.customerForm.patchValue({
          areaCode: this.areaCodes[0]
        });
        if (this.isLoggedIn) {
          const user = this.authService.getUserFromLocalStorage();
          if (user) {
            this.customerForm.patchValue({
              name: user.name,
              surName: user.surName,
              email: user.email,
              areaCode: user.areaCode,
              phone: user.phone,
              becomeMember: false
            });
          }
        }
      });
  }

  private getQuestions() {
    this.questions$ = this.customerService.getQuestion().pipe(
      map(resp => {
        const options: IDropdownItem[] = Object.keys(resp.options).map(key => {
          return {
            id: key,
            value: resp.options[key]
          };
        });
        return {
          title: resp.title,
          options
        };
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private setupCheckoutButton() {
    this.isEnquiry = this.order?.carGrade.paymentMethod.toLowerCase() === PaymentType.ENQUIRY.toLowerCase();
  }

  openTermsConditionModal() {
    this.displayTermsAndConditions = true;
  }

  closeTermsAndConditions() {
    this.displayTermsAndConditions = false;
  }
}
