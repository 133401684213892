import { FormControl, FormGroup } from '@angular/forms';

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

export function passwordMatch(group: FormGroup) {
  const control = group.controls['password'];
  const matchingControl = group.controls['confirmPassword'];

  if (matchingControl.errors && !matchingControl.errors.missMatch) {
    // return if another validator has already found an error on the matchingControl
    return;
  }
  // set error on matchingControl if validation fails
  if (control.value !== matchingControl.value) {
    matchingControl.setErrors({ missMatch: true });
  } else {
    matchingControl.setErrors(null);
  }
}


