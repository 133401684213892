export interface IRegion {
  address: string;
  latitude: number;
  longitude: number;
  name: string;
  systemLanguageId?: string;
  systemRegionId?: string;
  _id?: string;
}

export interface IRegionOption {
  _id: string;
  name: string;
  code: string;
}

export enum RegionCode {
  TW = 'TW',
  JP = 'JP',
  HK = 'HK'
}

export enum RegionName {
  TAIWAN = 'Taiwan',
  JAPAN = 'Japan',
  HONGKONG = 'Hong Kong'
}