import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from 'src/app/shared/constant/constant';
import { IOrderData } from 'src/app/model/order';
import { IOptionalItem, PriceType } from 'src/app/model/optional-item';
import { OptionalItemService } from 'src/app/services/car/optional-item.service';
import { lastValueFrom } from 'rxjs';
import { OrderService } from 'src/app/services/order/order.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  @Input() order: IOrderData | undefined;
  optionalItems: IOptionalItem[] = [];
  optionalItemsChecked: IOptionalItem[] = [];
  isShowRateTerms: boolean = false;
  isShowIconPen: boolean = true;
  showMoreDetail: boolean = false;
  DATE_TIME_FORMAT = 'EEE, MMM-dd, hh:mm a';
  @Output() confirm = new EventEmitter();

  pickupDate: string;
  dropOffDate: string;

  currentTotalPrice: number;
  optionalItemPrices: number = 0;
  screenDisplay: string;

  @Output() editInfo: EventEmitter<string> = new EventEmitter();

  constructor(
    private router: Router,
    private optionalItemService: OptionalItemService,
    private orderService: OrderService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.onResize();
    this.pickupDate = new Date(this.order.place.pickupTime).toDateString();
    this.dropOffDate = new Date(this.order.place.dropOffTime).toDateString();
    if (this.router.url === ROUTE.LOOKUP || this.router.url === ROUTE.ORDER_REVIEW) {
      this.isShowIconPen = false;
    }
    if (this.router.url === ROUTE.LOOKUP) {
      this.showMoreDetail = true;
    }
    await this.fetchOptionalItems();
    this.currentTotalPrice = this.order.carGrade.estimatedPrice;
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async fetchOptionalItems() {
    if (this.order.carGrade?.provider?.name === 'NICONICO') {
      console.log("====", this);
      const items$ = this.optionalItemService.getOptionalItemsNicoNico(
        {
          pu_location: Number(this.order.place.pickupRentalLocation._id),
          do_location: Number(this.order.place.dropOffRentalLocation._id),
          pu_date: this.order.place.pickupTime,
          do_date: this.order.place.dropOffTime,
          pu_time: this.order.place.pickupTimePrototype,
          do_time: this.order.place.dropOffTimePrototype,
          car_class: Number(this.order.carGrade._id)
        }
      )
      const items = await lastValueFrom(items$);
      const sanitizedItems = items.map(item => ({
        ...item,
        sanitizedDescription: this.sanitizeHtml(item.description)
      }));
      this.optionalItems = sanitizedItems;
    } else {
      const items$ = this.optionalItemService.getOptionalItemsByCarGrade(this.order.carGrade._id);
      const items = await lastValueFrom(items$);
      this.optionalItems = items;
    }
  }

  calculateTotal() {
    this.optionalItemPrices = 0;
    const checkedItems = this.optionalItems.filter(x => x.checked) || [];

    this.optionalItemsChecked = checkedItems;

    // console.log(
    //   {
    //     optionalItems : this.optionalItems,
    //     checkedItems : checkedItems
    //   }  
    // );
    
    checkedItems.forEach(item => {
      if (item.priceType === PriceType.DAY_PRICE) {
        this.optionalItemPrices += item.price * this.order.totalDays;
      } else {
        this.optionalItemPrices += item.price;
      }
    });
    
    this.order = {
      ...this.order,
      totalPrice: this.order.carGrade.estimatedPrice + this.optionalItemPrices,
      discountedPrice: this.order.carGrade.estimatedPrice + this.optionalItemPrices,
      optionalItems: checkedItems
    };
    this.orderService.saveOrderData(this.order);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 768) {
      this.screenDisplay = 'mobile';
    } else {
      this.screenDisplay = 'desktop';
    }
  }

  onCheck() {
    // let optionalItemPrices = 0;
    // const checkedItems = this.optionalItems.filter(x => x.checked) || [];
    // checkedItems.forEach(item => {
    //   if (item.priceType === PriceType.DAY_PRICE) {
    //     optionalItemPrices += item.price * this.order.totalDays;
    //   } else {
    //     optionalItemPrices += item.price;
    //   }
    //   this.optionalItemsChecked.push(item);
    // });
    // this.currentTotalPrice += optionalItemPrices;
  }

  onClickRateTerms() {
    this.isShowRateTerms = !this.isShowRateTerms;
  }

  onEditInfo() {
    this.editInfo.emit();
  }

  onConfirm() {
    this.calculateTotal();
    this.confirm.emit();
  }

}
