<div class="d-flex flex-column">
  <div class="header-container">
    <app-header></app-header>
  </div>
  <div class="content flex-fill">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <app-footer-new></app-footer-new>
  </div>
</div>
<p-toast position="top-right" preventOpenDuplicates="true"></p-toast>
<p-dialog
  [class]="classNameOfDialog"
  header="{{ titleNotify }}"
  [(visible)]="display"
  >{{ contentNotify }}
  <ng-template pTemplate="footer">
    <p-button (click)="onCloseModal()" label="OK" styleClass="px-5"></p-button>
  </ng-template>
</p-dialog>
