import { ILocation, IPlace } from './../../model/location';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DATE_FORMAT_SEARCH_CAR, DATE_PICKER_FORMAT, ROUTE } from 'src/app/shared/constant/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { RentType, TimeBase } from 'src/app/model/home-page';
import { IOrderData } from 'src/app/model/order';
import { OrderService } from 'src/app/services/order/order.service';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { RegionService } from 'src/app/services/region/region.service';
import { convertTime12to24 } from 'src/app/shared/utils/common';

@Component({
  selector: 'app-location-summary',
  templateUrl: './location-summary.component.html',
  styleUrls: ['./location-summary.component.scss']
})
export class LocationSummaryComponent implements OnInit, OnDestroy {

  _place: IPlace | undefined;
  isShowIconPen: boolean = true;

  showModifyLocation: boolean = false;
  DATE_PICKER_FORMAT = DATE_PICKER_FORMAT;
  rentCarForm: FormGroup | undefined = undefined;
  limousineForm: FormGroup | undefined = undefined;
  locations$: Observable<ILocation[]> = of([]);
  private _sub: Subscription;
  search$: Subject<string> = new Subject();
  pickupMinDate: Date = new Date();
  currentRentType: RentType;
  RentType = RentType;
  TimeBase = TimeBase;
  order: IOrderData | undefined;
    
  constructor(
    private router: Router,
    private orderService: OrderService,
    private formBuilder: FormBuilder,
    private regionServive: RegionService,
  ) {}

  ngOnInit(): void {
    this.order = this.orderService.getOrderData();

    if (this.order) {
      this.currentRentType = this.order.rentType;
      this.initForm();
      
      this._sub = this.search$.pipe(
        filter(val => val.length > 0),
        tap(() => this.locations$ = undefined),
        debounceTime(500),
      ).subscribe(val => {
        this.locations$ = this.regionServive.searchLocations(this.order.place.region._id, val);
      });
    }

    const routeToHidePenIcon = [ROUTE.ORDER_CONFIRM, ROUTE.LOOKUP, ROUTE.ORDER_REVIEW];
    if (routeToHidePenIcon.includes(this.router.url) || this.router.url.includes('/order-detail')) {
      this.isShowIconPen = false;
    }

    
  }

  @Input()
  get place(): IPlace | undefined {
    return this._place;
  }

  set place(value: IPlace | undefined) {
    if (value) {
      this._place = {
        ...value,
        pickupTime: new Date(value.pickupTime).toString(),
        dropOffTime: value.dropOffTime ? new Date(value.dropOffTime).toString() : null
      };
    }
  }
  @Input() backButton: boolean = true;

  DATE_TIME_FORMAT = 'EEE, MMM-dd, hh:mm a';

  onEditLocation() {
    this.showModifyLocation = true;
  }

  private initForm() {
    if (this.currentRentType === RentType.RentCar) {
      this.rentCarForm = this.formBuilder.group({
        pickupLocation: [this.order.place.pickupLocation, Validators.required],
        dropOffLocation: [this.order.place.dropOffLocation],
        pickupDate: [new Date(this.order.place.pickupTime.split(' ')[0]), Validators.required],
        pickupTime: [this.order.place.pickupTimePrototype, Validators.required],
        dropOffDate: [new Date(this.order.place.dropOffTime.split(' ')[0]), Validators.required],
        dropOffTime: [this.order.place.dropOffTimePrototype, Validators.required],
      });
    } else {
      if (this.order.timebase && this.order.timebase === TimeBase.ByTime) {
        this.limousineForm = this.formBuilder.group({
          pickupLocation: [this.order.place.pickupLocation, Validators.required],
          pickupAddress: [this.order.place.pickupLocation.address, Validators.required],
          pickupDate: [new Date(this.order.place.pickupTime.split(' ')[0]), Validators.required],
          pickupTime: [this.order.place.pickupTimePrototype, Validators.required],
          dropOffDate: [new Date(this.order.place.dropOffTime.split(' ')[0]), Validators.required],
          dropOffTime: [this.order.place.dropOffTimePrototype, Validators.required],
        });
      } else { // by Trip
        this.limousineForm = this.formBuilder.group({
          pickupLocation: [this.order.place.pickupLocation, Validators.required],
          pickupAddress: [this.order.place.pickupLocation.address, Validators.required],
          dropOffLocation: [this.order.place.dropOffLocation],
          dropOffAddress: [this.order.place.dropOffLocation.address],
          pickupDate: [new Date(this.order.place.pickupTime.split(' ')[0]), Validators.required],
          pickupTime: [this.order.place.pickupTimePrototype, Validators.required],
        });
      }
    }
  }

  onSelectPickupDate(date: Date) {
    if (this.currentRentType === RentType.RentCar) {
      this.rentCarForm.patchValue({
        dropOffDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      });
    } else {
      this.limousineForm.patchValue({
        dropOffDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      });
    }
  }

  onSubmit() {
    // For Rent a Car
    if (this.currentRentType === RentType.RentCar) {
      const { pickupDate, pickupTime, dropOffDate, dropOffTime, pickupLocation, dropOffLocation } = this.rentCarForm.value;
      let [pickupHour, pickupMinute] = convertTime12to24(pickupTime).split(':');
      let [dropOffHour, dropOffMinute] = convertTime12to24(dropOffTime).split(':');
      pickupDate.setHours(pickupHour, pickupMinute);
      dropOffDate.setHours(dropOffHour, dropOffMinute);
      const order: IOrderData = {
        searchTime: this.order.searchTime,
        place: {
          region: this.order.place.region,
          pickupTime: formatDate(pickupDate, DATE_FORMAT_SEARCH_CAR, 'en'),
          dropOffTime: formatDate(dropOffDate, DATE_FORMAT_SEARCH_CAR, 'en'),
          pickupLocation,
          dropOffLocation: dropOffLocation ? dropOffLocation : pickupLocation,
          pickupTimePrototype: pickupTime,
          dropOffTimePrototype: dropOffTime,
        },
        carGrade: null,
        rentType: this.order.rentType,
        selectedCarGrade: this.order.selectedCarGrade
      };
      this.orderService.saveOrderData(order);
    } else { // For Rent a Car with driver
      let {
        pickupDate, pickupTime,
        dropOffDate, dropOffTime,
        pickupLocation, dropOffLocation,
        pickupAddress, dropOffAddress
      } = this.limousineForm.value;
      let [pickupHour, pickupMinute] = convertTime12to24(pickupTime).split(':');
      if (dropOffTime) {
        let [dropOffHour, dropOffMinute] = convertTime12to24(dropOffTime).split(':');
        dropOffDate.setHours(dropOffHour, dropOffMinute);
      }
      pickupDate.setHours(pickupHour, pickupMinute);
  
      if (this.order.timebase === TimeBase.ByTrip) {
        dropOffLocation = dropOffLocation ? dropOffLocation : pickupLocation;
      }
      if (pickupLocation) {
        pickupLocation = {
          ...pickupLocation,
          address: pickupAddress
        };
      }
      if (dropOffLocation) {
        dropOffLocation = {
          ...dropOffLocation,
          address: dropOffAddress ? dropOffAddress : pickupAddress
        };
      }
      const order: IOrderData = {
        timebase: this.order.timebase,
        rentType: this.order.rentType,
        searchTime: this.order.searchTime,
        place: {
          region: this.order.place.region,
          pickupTime: formatDate(pickupDate, DATE_FORMAT_SEARCH_CAR, 'en'),
          dropOffTime: dropOffDate ? formatDate(dropOffDate, DATE_FORMAT_SEARCH_CAR, 'en') : null,
          pickupLocation,
          dropOffLocation,
          pickupTimePrototype: pickupTime,
          dropOffTimePrototype: dropOffTime,
        },
        carGrade: null,
        selectedCarGrade: this.order.selectedCarGrade
      };
      this.orderService.saveOrderData(order);
    }
    this.showModifyLocation = false;
    if (this.router.url === ROUTE.CAR_OPTIONAL_ITEM) {
      this.router.navigate([ROUTE.CAR_SELECTION]);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(()=> this.router.navigate([ROUTE.CAR_SELECTION]));
    }
  }

  onSelectPickupTime(event) {
    if (this.currentRentType === RentType.RentCar) {
      this.rentCarForm.markAsDirty();
      this.rentCarForm.patchValue({
        pickupTime: event
      });
    } else {
      this.limousineForm.markAsDirty();
      this.limousineForm.patchValue({
        pickupTime: event
      });
    }
    
  }

  onSelectDropOffTime(event) {
    if (this.currentRentType === RentType.RentCar) {
      this.rentCarForm.markAsDirty();
      this.rentCarForm.patchValue({
        dropOffTime: event
      });
    } else {
      this.limousineForm.markAsDirty();
      this.limousineForm.patchValue({
        dropOffTime: event
      });
    }
  }

  onSearchLocation(value: string) {
    this.search$.next(value);
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
