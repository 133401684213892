export const Login = {
  WEB_CMS: 'WebCMS',
  WEB_CLIENT: 'WebClient',
};

export const DATE_FORMAT = 'MMM-dd-yyyy';
export const DATE_TIME_FORMAT = 'MMM-dd HH:mm';
// format for datepciker, it's not same with Angular date format
export const DATE_PICKER_FORMAT = 'M-dd-yy';
export const DATE_PICKER_FORMAT_CHINESE = 'm月dd日yy';
export const DATE_FORMAT_SEND_REQUEST = 'yyyy-MM-dd';
export const DATE_FORMAT_SEARCH_CAR = 'YYYY-MM-dd HH:mm';

export const ROUTE = {
  NEWS: '/news',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  HOME_PAGE: '/home-page',
  CAR_SELECTION: '/car-selection',
  CAR_OPTIONAL_ITEM: '/car-optional-item',
  LIMO_CAR_SELECTION: '/limo-car-selection',
  LIMO_OPTIONAL_ITEM: '/limo-optional-item',
  LIMO_CHINA: '/limo-china',
  LIMO_CHINA_CONTACT_US: '/limo-china-contact-us',
  ORDER_REVIEW: '/order-review',
  ORDER_CONFIRM: '/order-confirm',
  ORDER_DETAIL: '/order-detail',
  VAN_LOGISTICS: '/van-logistics',
  LONG_TERM_LEASING: '/long-term-leasing',
  ADVERTISING_TRUCK: '/advertising-truck',
  LIMOUSINE_SERVICE: '/limousine-service',
  OVERSEAS: '/overseas',
  RENT_CAR: '/rent-car',
  APRON_GO: '/apron-go',
  PROPERTY: '/property',
  TAG: 'tag',
  LOOKUP: '/lookup',
  RATE_CARD: '/rate-card',
  FLEXI_PACKAGE: '/flexi-package',
  CORPORATE: '/corporate',
  INDIVIDUAL: '/individual',
  SIGN_IN: '/sign-in',
  USER_PROFILE: '/profile',
  RESET_PASSWORD: '/reset-password',
};

export const AREA_CODES = [
  { code: '+852', iconUrl: '../../../assets/images/icons/hong-kong.png', region:'HK' },
  { code: '+886', iconUrl: '../../../assets/images/icons/japan.png', region:'JP' },
  { code: '+81', iconUrl: '../../../assets/images/icons/taiwan.png', region:'TW' },
];

export const GENDER = ['Male', 'Female'];

export const FROM_URL = {
  SIGN_IN: 'sign-in',
  REGISTER: 'register',
  LOOKUP: 'lookup',
};

export const DEFAULT_DATE_TIME_PICKER = '12:00 PM';

export const SORT_OPTIONS = [
  { name: 'Grade name A - Z', value: 1, translocoKey: 'gradeNameA_Z' },
  { name: 'Grade name Z - A', value: 2, translocoKey: 'gradeNameZ_A' },
  { name: 'Price Low to High', value: 3, translocoKey: 'priceLowToHigh' },
  { name: 'Price High to Low', value: 4, translocoKey: 'priceHighToLow' },
];

export const EMERGENCY_MESSAGE_TYPE = {
  INFORMATION: 'Information',
  WARNING: 'Warning',
  ERROR: 'Error',
};

// Apply For HK booking:
// Only book 2 days in advance to 60 days
export const PICKUP_MIN_DATE_FOR_HK = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 4
);
export const PICKUP_MAX_DATE_FOR_HK = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 60
);

// For Oversea booking:
// Only book 7 days in advance to 90 days
export const PICKUP_MIN_DATE_NOT_HK = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 8
);
export const PICKUP_MAX_DATE_NOT_HK = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 90
);
