<!-- sign in -->
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!signInSMS">
  <div class="form-container d-flex flex-column gap-3" *ngIf="!signInSMS">
    <div *transloco="let t" class="g-col-12">
      <label class="label">{{ t("email") }}</label>
      <input
        id="email"
        type="email"
        pInputText
        class="form-control"
        formControlName="username"
      />
    </div>
    <div *transloco="let t" class="g-col-12">
      <label class="label">{{ t("password") }}</label>
      <input
        id="password"
        type="password"
        class="form-control"
        pInputText
        formControlName="password"
      />
    </div>
  </div>

  <p class="mt-3 terms_condition" *transloco="let t">
    <a href="javascript:void(0)" (click)="openTermsAndConditions()">
      {{ t("iAgreeToTheTNCWithDCHMotorLeasing_BySigningIn") }}
      <strong>{{ t("termsAndConditions") }}</strong>
      <span *ngIf="english"> {{ t("tnc_after") }}</span>
    </a>
  </p>

  <div class="text-center" *transloco="let t">
    <button
      class="btn__primary sign__in"
      type="submit"
      styleClass="px-5"
      [disabled]="loginForm.invalid || isLoading"
    >
      {{ t("signIn") }}
    </button>
  </div>
</form>

<!-- sign in via sms -->
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="signInSMS">
  <div *transloco="let t">
    <label class="label">{{ t("mobileNumber") }}</label>
    <div class="joinForm">
      <div class="g-col-4 extension" *transloco="let t">
        <p-dropdown
          [options]="areas"
          formControlName="areaCode"
          styleClass="w-100"
          placeholder="{{ t('areaCode') }}"
        >
          <ng-template let-option pTemplate="selectedItem">
            <div class="selected-item-wrapper">
              <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
              <span>{{ option.code }}</span>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="dropdown-item-wrapper dropdown__item">
              <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
              <span>{{ option.code }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <input
        type="text"
        pInputText
        class="mobile form-control g-col-8"
        formControlName="phone"
      />
    </div>

    <label class="label">{{ t("verificationNo") }}</label>
    <div class="verification" *transloco="let t">
      <input
        type="text"
        pInputText
        class="form-control border-0 g-col-9"
        formControlName="otp"
      />
      <button (click)="sendOtp($event)" styleClass="py-1" class="sendOtp">
        {{ t("send") }}
      </button>
    </div>
  </div>
  <p class="mt-3 terms_condition" *transloco="let t">
    <a href="javascript:void(0)" (click)="openTermsAndConditions()">
      <span>{{ t("iAgreeToTheTNCWithDCHMotorLeasing_BySigningIn") }}</span>
      <strong>{{ t("termsAndConditions") }}</strong>
      <span *ngIf="english"> {{ t("tnc_after") }}</span>
    </a>
  </p>
  <div class="text-center my-4" *transloco="let t">
    <button
      class="btn__primary sign__in"
      type="submit"
      styleClass="px-5"
      [disabled]="loginForm.invalid || isLoading"
    >
      {{ t("signIn") }}
    </button>
  </div>
</form>

<app-terms-condition-dialog
  [isDisplayed]="displayTermsAndConditions"
  (onClosed)="closeTermsAndConditions()"
></app-terms-condition-dialog>
