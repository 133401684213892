<div *transloco="let t" style="justify-content: space-between;display: flex;" class="mb-3">
</div>
<ng-container *ngIf="screenDisplay === 'mobile'">
  <div class="row">
    <div class="col-md-12 order-summary">
      <div class="row order-summary-info">
        <div class="col-md-12">
          <p class="text-label">Location</p>
          <p class="text-value">{{order.region}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Pickup Point
          </p>
          <p class="text-value">{{order.pickupZone}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Area
          </p>
          <p class="text-value">{{order.pickupArea}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Drop-off Point
          </p>
          <p class="text-value">{{order.dropOffZone}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Area
          </p>
          <p class="text-value">{{order.dropOffArea}}</p>
        </div>
        <div class="col-md-12">
          <p class="text-label">Additional Location</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row car-info">
    <div class="col-md-6 left">
      <img [src]="staticImg" alt="Car Image" class="car-img">
    </div>
    <div class="col-md-6 right">
      <h4 class="car-name pl-2">Mercedes Benz S Class</h4>
      <h6 class="car-model pl-2">
        Ford Ranger or similar</h6>
    </div>
  </div>
  <div class="row order-summary-info">
    <p class="terms-label">Terms and Condition</p>
    <ol class="terms-value">
      <small>
        <li *transloco="let t">{{t('tnc_1')}}</li>
        <li *transloco="let t">{{t('tnc_2')}}</li>
        <li *transloco="let t">{{t('tnc_3')}}</li>
        <li *transloco="let t">{{t('tnc_4')}}</li>
        <li *transloco="let t">{{t('tnc_5')}}</li>
        <li *transloco="let t">{{t('tnc_6')}}</li>
        <li *transloco="let t">{{t('tnc_7')}}</li>
        <li *transloco="let t">{{t('tnc_8')}}</li>
        <li *transloco="let t">{{t('tnc_9')}}</li>
        <li *transloco="let t">{{t('tnc_10')}}</li>
        <li *transloco="let t">{{t('tnc_11')}}</li>
      </small>
    </ol>
  </div>
  <div class="row order-price">
    <div class="col-md-6 order-days">
      <div class="text-order-price" *transloco="let t">{{ t('estimatedTotal') }}</div>
    </div>
    <div class="col-md-2 order-price-total">
      <p>${{order.price}}</p>
    </div>
    <div class="col-md-4 button-confirm-box">
      <button class="button-confirm-mobile" (onClick)="onDownload()">
        <div class="row">
          <div class="col-6 download-label">
            <p>
              Download
            </p>
            <p>
              Order Form
            </p>
          </div>
          <div class="col-6 img-box">
            <img src="assets/images/rac/form_download.svg">
          </div>
        </div>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="screenDisplay === 'desktop'">
  <div class="row">
    <div class="col-md-6 order-summary">
      <div class="row order-summary-info">
        <div class="col-md-12">
          <p class="text-label">Location</p>
          <p class="text-value">{{order.region}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Pickup Point
          </p>
          <p class="text-value">{{order.pickupZone}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Area
          </p>
          <p class="text-value">{{order.pickupArea}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Drop-off Point
          </p>
          <p class="text-value">{{order.dropOffZone}}</p>
        </div>
        <div class="col-md-6">
          <p class="text-label">
            Area
          </p>
          <p class="text-value">{{order.dropOffArea}}</p>
        </div>
        <div class="col-md-12">
          <p class="text-label">Additional Location</p>
        </div>
      </div>
      <div class="row order-summary-info">
        <p class="terms-label">Terms and Condition</p>
        <ol class="terms-value">
          <small>
            <li *transloco="let t">{{t('tnc_1')}}</li>
            <li *transloco="let t">{{t('tnc_2')}}</li>
            <li *transloco="let t">{{t('tnc_3')}}</li>
            <li *transloco="let t">{{t('tnc_4')}}</li>
            <li *transloco="let t">{{t('tnc_5')}}</li>
            <li *transloco="let t">{{t('tnc_6')}}</li>
            <li *transloco="let t">{{t('tnc_7')}}</li>
            <li *transloco="let t">{{t('tnc_8')}}</li>
            <li *transloco="let t">{{t('tnc_9')}}</li>
            <li *transloco="let t">{{t('tnc_10')}}</li>
            <li *transloco="let t">{{t('tnc_11')}}</li>
          </small>
        </ol>
      </div>
    </div>
  
    <div #carInfo class="col-md-6">
      <div class="row car-info">
        <div class="col-md-6 left">
          <img [src]="staticImg" alt="Car Image" class="car-img">
        </div>
        <div class="col-md-6 right">
          <h4 class="car-name pl-2">Mercedes Benz S Class</h4>
          <h6 class="car-model pl-2">
            Ford Ranger or similar</h6>
        </div>
      </div>
      <div class="row order-price">
        <div class="col-md-6 order-days">
          <div class="text-order-price" *transloco="let t">{{ t('estimatedTotal') }}</div>
        </div>
        <div class="col-md-2 order-price-total">
          <p>${{order.price}}</p>
        </div>
        <div class="col-md-4 button-confirm-box">
          <p-button class="button-confirm" (onClick)="onDownload()">
            <div class="row">
              <div class="col-md-9 download-label">
                <p>
                  Download
                </p>
                <p>
                  Order Form
                </p>
              </div>
              <div class="col-md-3">
                <img src="assets/images/rac/form_download.svg">
              </div>
            </div>
          </p-button>
        </div>
      </div>
    </div>
  
  </div>
</ng-container>