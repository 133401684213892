import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IQuestion } from 'src/app/model/customer';
import { AREA_CODES, GENDER } from 'src/app/shared/constant/constant';
import { ISystemAirline } from 'src/app/model/airline';
import { IUser } from 'src/app/model/user';
import { IResetPassword } from 'src/app/model/reset-password';
import { tap } from 'rxjs/operators';
import { encryptStringValue } from 'src/app/shared/utils/common';
import { BrowserService } from '../browser/browser.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  url: string = `${environment.api}web`;
  private _localStorage: Storage | undefined;

  constructor(private http: HttpClient, private browserService: BrowserService) {
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }

  getQuestion(): Observable<IQuestion> {
    return this.http.get<IQuestion>(`${this.url}/get-inception-dropdown?name=how_to_know_mls`);
  }

  getAreaCodes(): Observable<any[]> {
    return of(AREA_CODES.map(code => code.code));
  }

  getGenderList(): Observable<string[]> {
    return of(GENDER);
  }

  sendOTP(areaCode: string, phone: string, fromUrl: string): Observable<{ msg: string }> {
    return this.http.post<{ msg: string }>(`${this.url}/customer/generate-otp`, { areaCode, phone, fromUrl });
  }

  getAirlines(): Observable<ISystemAirline> {
    return this.http.get<ISystemAirline>(`${this.url}/get-airline-selection`);
  }

  getUserInfo(): Observable<IUser> {
    return this.http.get<IUser>(`${this.url}/customer/my-info`);
  }

  updateUserInfo(data: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${this.url}/customer/my-info`, data);
  }

  resetPassword(data: IResetPassword): Observable<IResetPassword> {
    data = {
      email: encryptStringValue(data.email),
      password: encryptStringValue(data.password)
    };
    return this.http.post<IResetPassword>(`${this.url}/customer/reset-password`, data).pipe(
      tap(user => {
        const encryptedUser = CryptoJS.AES.encrypt(encodeURIComponent(JSON.stringify(user)), environment.secretKey).toString();
        this._localStorage?.setItem('user', encryptedUser);
      })
    );
  }

  forgotPassword(email: string): Observable<string> {
    return this.http.post<string>(`${this.url}/customer/forgot-password`, { email });
  }
}
