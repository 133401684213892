<div class="register-dialog-container">
  <p-dialog
    *transloco="let t"
    [modal]="true"
    [(visible)]="isVisible"
    (onHide)="closeModal()"
    styleClass="register-dialog"
  >
    <div class="registration-card">
      <div *transloco="let t" class="registration-card-header">
        {{ t("registration") }}

        <button (click)="closeModal()" class="btn-close"></button>
      </div>
      <app-registration-form></app-registration-form>
    </div>
  </p-dialog>
</div>
