<div *transloco="let t" class="wrapper-form-enquiry">
  <div class="wrapper-inner">
    <h3>{{ t("contact_us") }}</h3>
    <form [formGroup]="enquiryForm" class="form-content" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <label *transloco="let t">{{ t("name") }}</label>
          <input id="name" type="text" pInputText class="form-control enquiry-input" formControlName="name" />
        </div>
        <div class="col-md-6">
          <label *transloco="let t">{{ t("mobileNo") }}.</label>
          <div class="row">
            <div class="col-md-4 col-sm-12 area-code">
              <p-dropdown [options]="areas" (onChange)="onPhoneCodeChange($event.value)">
                <ng-template let-option pTemplate="selectedItem">
                  <div class="selected-item-wrapper">
                    <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
                    <span>{{ option.code }}</span>
                  </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                  <div class="dropdown-item-wrapper dropdown__item">
                    <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
                    <span>{{ option.code }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-8 number-box">
              <input type="number" pInputText class="form-control g-col-8 input-phone mobile" formControlName="phone" />
            </div>
          </div>
        </div>
        <div class="col-md-12 pt-3">
          <label>
            {{ t('email') }}
          </label>
          <input id="email" type="email" class="form-control enquiry-input" pInputText formControlName="email" />
        </div>
        <div class="col-md-12 pt-3">
          <label>
            {{t('subject')}}
          </label>
          <select>
            <option value="">{{t('selectSubject')}}</option>
            <option *ngFor="let val of checkType()" [value]="t(val)" formControlName="enquireOn">{{t(val)}}</option>
          </select>
        </div>
        <div class="col-md-12 pt-3">
          <label>
            {{ t('message') }}
          </label>
          <textarea rows="4" class="form-control pt-2" formControlName="message"></textarea>
        </div>
      </div>

      <!-- <div class="g-col-6 joinForm limousine">
          <label>
            Mobile No.
          </label>
            <p-dropdown [options]="areas" styleClass="areas h-100 w-100" (onChange)="onPhoneCodeChange($event.value)">
              <ng-template let-option pTemplate="selectedItem">
                <div class="selected-item-wrapper">
                  <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
                  <span>{{ option.code }}</span>
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div class="dropdown-item-wrapper dropdown__item">
                  <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
                  <span>{{ option.code }}</span>
                </div>
              </ng-template>
            </p-dropdown>
          <div class="g-col-8">
            <input type="number" pInputText class="form-control g-col-8 input-phone mobile" formControlName="phone" />
          </div>
        </div> -->
      <div class="recaptcha">
        <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
      </div>
      <p-button *transloco="let t" type="submit" styleClass="enquiry-button"
        [disabled]="enquiryForm.invalid || !captchaToken">{{ t("send") }}

        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.4884 5.77621C17.2976 5.98025 17.2976 6.31141 17.4884 6.51537L22.3326 11.7002H0.488399C0.218722 11.7002 0 11.9344 0 12.223C0 12.5116 0.218722 12.7457 0.488399 12.7457H22.3326L17.4882 17.9307C17.2976 18.1347 17.2975 18.4658 17.4883 18.6699C17.679 18.8741 17.9883 18.8741 18.179 18.6698L23.857 12.5926C23.9523 12.4905 24 12.3567 24 12.223C24 12.0893 23.9523 11.9554 23.8569 11.8534L18.179 5.77621C17.9883 5.57199 17.679 5.57199 17.4884 5.77621Z"
            fill="#203E5B" />
        </svg>
      </p-button>
    </form>
  </div>
</div>