<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="grid gap-3">
    <div class="g-col-12">
      <label for="name" class="block">{{ t("firstName") }}</label>
      <input
        type="text"
        pInputText
        class="form-control g-col-12"
        formControlName="name"
      />
    </div>
    <div class="g-col-12">
      <label for="surName" class="block">{{ t("lastName") }}</label>
      <input
        type="text"
        pInputText
        class="form-control g-col-12"
        formControlName="surName"
      />
    </div>
    <div class="g-col-12">
      <label for="gender" class="block">{{ t("gender") }}</label>
      <p-dropdown
        [options]="gender$ | async"
        formControlName="gender"
        class="g-col-12"
        styleClass="w-100 register-dropdown"
      >
        <ng-template pTemplate="item" let-item>
        {{item | transloco}}
        </ng-template>
        <ng-template pTemplate="selectedItem" let-item>
        {{item | transloco}}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="g-col-12">
      <label for="birthday" class="block">{{ t("birthday") }}</label>
      <p-calendar
        [showIcon]="true"
        *ngIf="isBrowser"
        formControlName="birthday"
        class="custom-calendar g-col-12"
        styleClass="d-flex register-birthday"
        [maxDate]="maxDate"
        [dateFormat]="DATE_PICKER_FORMAT"
        appendTo="body"
      ></p-calendar>
      <!-- appendTo="body" -- fix the missdisplay on calendar show -->
    </div>
    <div class="g-col-12">
      <label for="email" class="block required">{{ t("email") }}</label>
      <input
        type="email"
        pInputText
        class="form-control g-col-12"
        formControlName="email"
      />
    </div>
    <div class="g-col-12">
      <label for="areaCode" class="block required">{{
        t("mobileNumber")
      }}</label>
      <div class="joinForm">
        <div class="extension">
          <p-dropdown
            [options]="areas"
            styleClass="w-100"
            formControlName="areaCode"
          >
            <ng-template let-option pTemplate="selectedItem">
              <div class="selected-item-wrapper">
                <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
                <span>{{ option.code }}</span>
              </div>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <div class="dropdown-item-wrapper dropdown__item">
                <img [src]="option.iconUrl" class="flag-icon" alt="Flag" />
                <span>{{ option.code }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="mobile">
          <input
            type="text"
            pInputText
            class="form-control g-col-8"
            formControlName="phone"
          />
        </div>
      </div>
    </div>

    <div class="verification-wrapper g-col-12 grid" *transloco="let t">
      <label for="areaCode" class="required">{{ t("verificationNo") }}</label>

      <div class="verification">
        <input
          type="text"
          pInputText
          class="form-control border-0 g-col-12"
          pInputText
          formControlName="otp"
        />
        <button (click)="sendOtp($event)" styleClass="py-1" class="sendOtp">
          {{ t("send") }}
        </button>
      </div>
    </div>
    <div class="g-col-12">
      <label for="password" class="block required">{{ t("password") }}</label>
      <input
        pInputText
        type="password"
        class="form-control g-col-12"
        formControlName="password"
      />
    </div>
    <div class="g-col-12" *transloco="let t">
      <label for="confirmPassword" class="block required">{{
        t("confirmPassword")
      }}</label>
      <input
        pInputText
        type="password"
        class="form-control g-col-12"
        formControlName="confirmPassword"
      />
      <p
        class="g-col-12 mb-0 p-error"
        *ngIf="registerForm.controls.confirmPassword?.hasError('missMatch')"
      >
        {{ t("passwordDoesNotMatch") }}
      </p>
    </div>
    <div class="tnc_container g-col-12">
      <div class="mb-2">
        <label for="receive-promotion">
          <p-checkbox
            [binary]="true"
            inputId="receive-promotion"
            formControlName="agree"
          ></p-checkbox>
          <a href="javascript:void(0)" (click)="showTermsConditionModal()">
            {{ t("iAgreeToTheTNCWithDCHMotorLeasing_BySigningIn") }}
            <strong>{{ t("termsAndConditions") }}</strong>
            <span *ngIf="english"> {{ t("tnc_after") }}</span>
          </a>
        </label>
      </div>
    </div>
    <div class="tnc_container g-col-12">
      <div class="mb-2">
        <label for="receive-lastnew">
          <p-checkbox
            class="mr-2"
            [binary]="true"
            inputId="receive-lastnew"
            formControlName="receiveLatestNews"
          ></p-checkbox>
          {{ t("iAgreeToReceiveLatestNews") }}
        </label>
      </div>
    </div>
    <div class="g-col-12">
      <p-messages
        [(value)]="messages"
        [escape]="false"
        [enableService]="false"
        severity="error"
        *ngIf="messages.length"
      ></p-messages>
    </div>
    <div class="g-col-12 text-center">
      <button
        class="submit-btn"
        styleClass="px-5"
        [disabled]="
          registerForm.invalid || !registerForm.controls['agree'].value
        "
      >
        {{ t("register") }}
      </button>
    </div>
  </div>
</form>

<app-terms-condition-dialog
  [isDisplayed]="displayTermsConditionModal"
  (onClosed)="closeTermsAndConditions()"
></app-terms-condition-dialog>
