<Footer class="footer-container" *transloco="let t">
  <div class="copyright">© Dah Chong Hong (Motor Leasing) Limited 2023</div>

  <ul *transloco="let t" class="navigation">
    <li>
      <a class="link" href="/about-us"> {{ t("aboutUs") }}</a>
    </li>

    <li>
      <a class="link" href="/contact-us"> {{ t("contactUs") }}</a>
    </li>

    <li>
      <a class="link" href="/terms-of-use"> {{ t("termsOfUse") }}</a>
    </li>

    <li>
      <a class="link" href="/disclaimer"> {{ t("disclaimer") }}</a>
    </li>
  </ul>
</Footer>
