<div class="summary" *ngIf="order" >
  <!-- <h4 class="mb-3">Summary</h4> -->
  <div class="d-flex justify-content-between">
    <p *transloco="let t">{{ t('carRentalFee') }} x {{ order.totalDays }} {{ t('days') }}</p>
    <p>{{ order.carGrade.systemCurrencyCode }} {{ order.carGrade.estimatedPrice }}</p>
  </div>
  <!-- discount -->
  <div class="d-flex justify-content-between" *ngIf="order.discountAmount">
    <p>Discount</p>
    <p>{{ order.discountType === DiscountType.BY_VALUE ? order.carGrade.systemCurrencyCode : '%' }}{{ order.discountAmount || 0 }}</p>
  </div>
  <!-- optional items -->
  <div *ngIf="order.optionalItems?.length"><strong *transloco="let t">{{ t('optionalItems') }}:</strong> </div>
  <ng-container *ngFor="let item of order.optionalItems">
    <div class="d-flex justify-content-between ms-3">
      <ng-container *ngIf="item.priceType === PriceType.FIXED_PRICE">
        <p>{{ item.optionalItemName }}</p>
        <p>{{ item.systemCurrencyName }} {{ item.price }}</p>
      </ng-container>
      <ng-container *ngIf="item.priceType === PriceType.DAY_PRICE">
        <p *transloco="let t">{{ item.optionalItemName }} x {{ order.totalDays }} {{ t('days') }}</p>
        <p>{{ item.systemCurrencyName }} {{ item.price*order.totalDays }}</p>
      </ng-container>
    </div>
  </ng-container>
  <div class="d-flex justify-content-between">
    <strong *transloco="let t">{{ t('estimatedTotal') }}</strong>
    <p>{{ order.carGrade.systemCurrencyCode }} {{ order.discountedPrice || 0 }}</p>
  </div>
  <div class="d-flex justify-content-between" *ngIf="order.carGrade.extraFee > 0">
    <strong>Extra Handling Fee</strong>
    <p>{{ order.carGrade.systemCurrencyCode }} {{ order.carGrade.extraFee }}</p>
  </div>
  <ng-container *ngIf="isShowRateTerms" >
    <p style="color: #203E5B; cursor: pointer;" (click)="onClick()" *transloco="let t">{{ t('seeRateTerms') }}</p>
  </ng-container>
</div>
