import { ILocation } from 'src/app/model/location';
import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { IOrderData } from 'src/app/model/order';
import { RegionService } from 'src/app/services/region/region.service';
import {
  AREA_CODES,
  DATE_FORMAT_SEARCH_CAR,
  DATE_PICKER_FORMAT,
  DATE_PICKER_FORMAT_CHINESE,
  DEFAULT_DATE_TIME_PICKER,
  PICKUP_MAX_DATE_FOR_HK,
  PICKUP_MAX_DATE_NOT_HK,
  PICKUP_MIN_DATE_FOR_HK,
  PICKUP_MIN_DATE_NOT_HK,
} from 'src/app/shared/constant/constant';
import { IRegionOption, RegionCode, RegionName } from 'src/app/model/region';
import { Calendar } from 'primeng/calendar';
import { convertTime12to24 } from 'src/app/shared/utils/common';
import { RentType } from 'src/app/model/home-page';

@Component({
  selector: 'app-car-search-form',
  templateUrl: './car-search-form.component.html',
  styleUrls: ['./car-search-form.component.scss'],
})
export class CarSearchFormComponent implements OnInit {
  @Input() regions: IRegionOption[] = [];
  @Output() search: EventEmitter<IOrderData> = new EventEmitter();
  @Input() order: IOrderData | undefined;
  areas = AREA_CODES;
  visibleBottomSideBar: boolean = true;
  showCountry: boolean = false;
  showLocation: boolean = false;
  showTime: boolean = false;
  isDesktop: boolean = true;

  rentCarForm: FormGroup | undefined = undefined;
  DATE_PICKER_FORMAT = DATE_PICKER_FORMAT;

  locations$: Observable<ILocation[]> = of([]);

  // Apply For HK booking:
  // Only book 2 days in advance to 60 days
  pickupMinDate: Date = PICKUP_MIN_DATE_FOR_HK;
  pickupMaxDate: Date = PICKUP_MAX_DATE_FOR_HK;

  // Max Date
  maxDate: any;
  todayDate = new Date();

  hiddenEndDate: boolean = true;

  itemLocation: ILocation;
  map: google.maps.Map;
  showMap = false;
  contentMapStyle = {
    left: '0px',
    top: '0px',
    bottom: 'unset',
    position: 'absolute'
  };

  onPick: boolean = false;

  countryLabel: string = null;
  pickupLabel: string = null;
  dropOffLabel: string = null;
  pickupDateLabel: string = null;
  dropOffDateLabel: string = null;

  setMap(event) {
    this.map = event.map;
  }

  onDropdownClose() {
    console.log('onDropdownClose');
    this.showMap = false;
  }

  onMouseOver(location, elementEvent = null) {
    if (elementEvent) {
      let el = elementEvent.target;
      let rect = el.getBoundingClientRect();
      let elWidth = elementEvent.target.offsetParent.offsetWidth;
  
      this.contentMapStyle.left = (rect.left + elWidth - 23) + 'px';
  
      if (window.scrollY > 400) {
        this.contentMapStyle.top = rect.top - 150 + 'px';
        this.contentMapStyle.position = 'absolute';
        this.contentMapStyle.bottom = 'unset';
      } else {
        this.contentMapStyle.top = 'unset';
        this.contentMapStyle.bottom = '0px';
        this.contentMapStyle.position = 'fixed';
      }
    }
    if (this.countryLabel != RegionName.JAPAN) {
      let bounds = new google.maps.LatLngBounds();
      location.options = {
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 12,
      };
      location.overlays = [
        new google.maps.Marker({
          position: { lat: location.latitude, lng: location.longitude },
        }),
      ];
      location.overlays.forEach((marker) => {
        bounds.extend(marker.getPosition());
      });
      setTimeout(() => {
        this.map.fitBounds(bounds);
        this.map.setZoom(12);
      }, 200);
      this.itemLocation = location;
      this.showMap = true;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private regionServive: RegionService
  ) { }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    this.itemLocation = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenWidth();
  }

  ngOnInit(): void {
    this.initForm();
    this.languageCheck();
    const { region } = this.rentCarForm.value;
    this.locations$ = this.regionServive.searchLocations(region, '');
    this.checkScreenWidth();
  }

  languageCheck() {
    const currentLang = localStorage.getItem('currentLang');
    switch (currentLang) {
      case 'TC':
        this.DATE_PICKER_FORMAT = DATE_PICKER_FORMAT_CHINESE;
        break;
      case 'SC':
        this.DATE_PICKER_FORMAT = DATE_PICKER_FORMAT_CHINESE;
        break;
    
      default:
        this.DATE_PICKER_FORMAT = DATE_PICKER_FORMAT;
        break;
    }
  }

  getFlag(code: string) {
    const data = this.areas.find((val) => val.region === code);
    return data.iconUrl;
  }

  onChangeRegion() {
    const { region } = this.rentCarForm.value;
    const currentRegion = this.regions.find((x) => x._id === region);
    this.countryLabel = currentRegion?.name;
    this.pickupLabel = null;
    this.dropOffLabel = null;
    this.pickupDateLabel = null;
    this.dropOffDateLabel = null;
    this.locations$ = this.regionServive.searchLocations(region, '');
    this.rentCarForm.patchValue({
      pickupLocation: null,
      dropOffLocation: null,
    });
    this.rentCarForm.markAsUntouched();
    this.rentCarForm.markAsPristine();
    const hkRegion = this.regions.find((x) => x.code === RegionCode.HK);
    if (region && region === hkRegion?._id) {
      this.pickupMinDate = PICKUP_MIN_DATE_FOR_HK;
      this.pickupMaxDate = PICKUP_MAX_DATE_FOR_HK;
      this.maxDate = new Date(
        new Date().setDate(this.todayDate.getDate() + 30)
      );
      this.rentCarForm.patchValue({
        pickupDate: this.pickupMinDate,
        dropOffDate: new Date(
          this.pickupMinDate.getFullYear(),
          this.pickupMinDate.getMonth(),
          this.pickupMinDate.getDate() + 1
        ),
      });
    } else {
      // For Oversea booking:
      // Only book 7 days in advance to 90 days
      this.maxDate = new Date(
        new Date().setDate(this.todayDate.getDate() + 90)
      );
      this.rentCarForm.patchValue({
        pickupDate: PICKUP_MIN_DATE_NOT_HK,
        dropOffDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 9
        ), // dropOffDate after pickDate 1 day
      });
      this.pickupMinDate = PICKUP_MIN_DATE_NOT_HK;
      this.pickupMaxDate = PICKUP_MAX_DATE_NOT_HK;
    }
  }

  onSubmit() {
    const {
      region,
      pickupDate,
      pickupTime,
      dropOffDate,
      dropOffTime,
      pickupLocation,
      dropOffLocation,
    } = this.rentCarForm.value;
    let [pickupHour, pickupMinute] = convertTime12to24(pickupTime).split(':');
    let [dropOffHour, dropOffMinute] =
      convertTime12to24(dropOffTime).split(':');
    pickupDate.setHours(pickupHour, pickupMinute);
    dropOffDate.setHours(dropOffHour, dropOffMinute);
    delete pickupLocation.options;
    delete pickupLocation.overlays;
    if (dropOffLocation?.options && dropOffLocation?.overlays) {
      delete dropOffLocation.options;
      delete dropOffLocation.overlays;
    }
    const selectedRegion = this.regions.find((x) => x._id === region);
    const order: IOrderData = {
      place: {
        region: selectedRegion,
        pickupTime: formatDate(pickupDate, DATE_FORMAT_SEARCH_CAR, 'en'),
        dropOffTime: formatDate(dropOffDate, DATE_FORMAT_SEARCH_CAR, 'en'),
        pickupLocation,
        dropOffLocation: dropOffLocation ? dropOffLocation : pickupLocation,
        pickupTimePrototype: pickupTime,
        dropOffTimePrototype: dropOffTime,
      },
      carGrade: null,
    };
    this.search.emit(order);
  }

  private initForm() {
    // set hong kong region by default
    const hkRegion = this.regions.find((x) => x.code === RegionCode.HK);
    this.countryLabel = hkRegion?.name;
    this.rentCarForm = this.formBuilder.group({
      region: [hkRegion?._id, Validators.required],
      pickupLocation: [null],
      dropOffLocation: [null],
      pickupDate: [this.pickupMinDate, Validators.required],
      pickupTime: [DEFAULT_DATE_TIME_PICKER, Validators.required],
      dropOffDate: [
        new Date(
          this.pickupMinDate.getFullYear(),
          this.pickupMinDate.getMonth(),
          this.pickupMinDate.getDate() + 1
        ),
        Validators.required,
      ],
      dropOffTime: [DEFAULT_DATE_TIME_PICKER, Validators.required],
      endDate: [
        new Date(),
        new Date(
          this.pickupMinDate.getFullYear(),
          this.pickupMinDate.getMonth(),
          this.pickupMinDate.getDate() + 1
        ),
      ],
    });

    if (this.order && this.order.rentType === RentType.RentCar) {
      if (this.order.place.region._id === hkRegion?._id) {
        this.pickupMinDate = PICKUP_MIN_DATE_FOR_HK;
        this.pickupMaxDate = PICKUP_MAX_DATE_FOR_HK;
      } else {
        this.pickupMinDate = PICKUP_MIN_DATE_NOT_HK;
        this.pickupMaxDate = PICKUP_MAX_DATE_NOT_HK;
      }
      this.rentCarForm.patchValue({
        region: this.order.place.region._id,
        pickupLocation: this.order.place.pickupLocation,
        dropOffLocation:
          this.order.place.dropOffLocation &&
            this.order.place.dropOffLocation._id ===
            this.order.place.pickupLocation._id
            ? null
            : this.order.place.dropOffLocation,
        pickupDate: new Date(this.order.place.pickupTime.split(' ')[0]),
        pickupTime: this.order.place.pickupTimePrototype,
        dropOffDate: new Date(this.order.place.dropOffTime.split(' ')[0]),
        dropOffTime: this.order.place.dropOffTimePrototype,
        endDate: new Date(this.order.place.dropOffTime.split(' ')[0]),
      });
    }
  }

  private checkScreenWidth(): void {
    const windowWidth = window.innerWidth;
    this.isDesktop = windowWidth >= 768;
  }

  onSelectPickupDate(event: Date, calendar: Calendar) {
    const { region } = this.rentCarForm.value;
    var result = new Date(event);
    const hkRegion = this.regions.find((x) => x.code === RegionCode.HK);
    if (region && region === hkRegion?._id) {
      result.setDate(result.getDate() + 30);
      this.maxDate = result;
      console.log('Max Date HK', this.maxDate);
    } else {
      result.setDate(result.getDate() + 90);
      this.maxDate = result;
      console.log('Max Date Not HK', this.maxDate);
    }
    calendar.value = null;
    this.rentCarForm.patchValue({
      dropOffDate: null,
      endDate: null,
    });
    this.hiddenEndDate = false;
    setTimeout(() => {
      calendar.focus = true;
      calendar.showOnFocus = true;
      calendar.showOverlay();
      calendar.cd.detectChanges();
      calendar.value = [new Date(event), null];
      calendar.minDate = new Date(event);
    }, 200);
  }

  onDropOffDateFocus(calendar: Calendar) {
    const { region } = this.rentCarForm.value;

    var result = new Date(this.rentCarForm.value.pickupDate);

    const hkRegion = this.regions.find((x) => x.code === RegionCode.HK);
    if (region && region === hkRegion?._id) {
      result.setDate(result.getDate() + 30);
      this.maxDate = result;
    } else {
      result.setDate(result.getDate() + 90);
      this.maxDate = result;
    }

    this.hiddenEndDate = false;
    setTimeout(() => {
      calendar.focus = true;
      calendar.showOnFocus = true;
      calendar.showOverlay();
      calendar.cd.detectChanges();
      calendar.value = [
        new Date(this.rentCarForm.get('pickupDate').value),
        new Date(this.rentCarForm.get('dropOffDate').value),
      ];
      calendar.minDate = new Date(this.rentCarForm.get('pickupDate').value);
    }, 200);
  }

  onSelectEndDate(event, calendar: Calendar) {
    console.log('Event', event);
    console.log('Max Date', this.maxDate);
    setTimeout(() => {
      calendar.hideOverlay();
      this.hiddenEndDate = true;
      this.rentCarForm.patchValue({
        dropOffDate: new Date(event),
        endDate: [
          new Date(this.rentCarForm.get('pickupDate').value),
          new Date(event),
        ],
      });
    }, 500);
  }

  closeCalendar(calendar: Calendar) {
    calendar.hideOverlay();
    this.hiddenEndDate = true;
  }

  onEndDateClickOutside() {
    this.hiddenEndDate = true;
  }

  onSelectPickupTime(event) {
    this.rentCarForm.patchValue({
      pickupTime: event,
    });
  }

  onSelectDropOffTime(event) {
    this.rentCarForm.patchValue({
      dropOffTime: event,
    });
  }

  dialogCountryHandler(action: string) {
    if (action === 'open') {
      this.showCountry = true;
    } else {
      this.showCountry = false;
    }
  }

  dialogLocationHandler(action: string) {
    if (action === 'open') {
      this.showLocation = true;
    } else {
      const { pickupLocation, dropOffLocation } = this.rentCarForm.value;
      if (pickupLocation) this.pickupLabel = pickupLocation.name;
      if (dropOffLocation) this.dropOffLabel = dropOffLocation.name;
      this.showLocation = false;
    }
  }

  dialogTimeHandler(action: string) {
    if (action === 'open') {
      this.showTime = true;
    } else {
      const { pickupDate, dropOffDate } = this.rentCarForm.value;
      this.pickupDateLabel = new Date(pickupDate).toDateString();
      this.dropOffDateLabel = new Date(dropOffDate).toDateString();
      this.showTime = false;
    }
  }
}
