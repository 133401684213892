import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin } from 'src/app/model/user';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { FROM_URL, AREA_CODES } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
})
export class SignInFormComponent implements OnInit {
  areas = AREA_CODES;
  private _signInSMS: boolean = false;
  displayTermsAndConditions: boolean;
  language = localStorage.getItem('language');
  english = this.language !== 'TC' && this.language !== 'SC';
  currentPhoneCode: string | null = null;

  @Input()
  set signInSMS(value: boolean) {
    this._signInSMS = value;
    if (this._signInSMS) {
      this.loginForm = this.fb.group({
        areaCode: ['', Validators.required],
        phone: [null, Validators.required],
        otp: ['', Validators.required]
      });
    } else {
      this.loginForm = this.fb.group({
        username: [null, Validators.required],
        password: [null, Validators.required]
      });
    }
  }
  get signInSMS(): boolean {
    return this._signInSMS;
  }

  @Input() isLoading: boolean = false;
  @Output() login: EventEmitter<UserLogin> = new EventEmitter();

  areaCodes$: Observable<string[]> = of([]);
  loginForm: FormGroup | undefined;

  constructor(private fb: FormBuilder,
    private customerService: CustomerService,
    private notificationService: NotificationsService,) { }
  ngOnInit(): void {
    this.areaCodes$ = this.customerService.getAreaCodes().pipe(
      tap(codes => {
        if (codes.length) {
          this.loginForm.patchValue({
            areaCode: codes[0]
          });
        }
      }));
  }

  sendOtp(event: Event) {
    event.preventDefault()
    const { areaCode, phone } = this.loginForm.value;
    this.customerService.sendOTP(areaCode.code, phone, FROM_URL.SIGN_IN).subscribe(() => {
      this.notificationService.showAlerts('OTP was sent.', 200);
    }, (err: HttpErrorResponse) => {
      const errorr: { msg: string }[] = err.error.errors;
      errorr.forEach(item => this.notificationService.showAlerts(item.msg, 404));
    });
  }

  onSubmit() {
    if (this._signInSMS) {
      const { areaCode, phone } = this.loginForm.value;
      this.login.emit({ ...this.loginForm.value, fromUrl: FROM_URL.SIGN_IN, areaCode: areaCode.code });
    } else {
      this.login.emit({ ...this.loginForm.value, fromUrl: FROM_URL.SIGN_IN });
    }
  }

  openTermsAndConditions() {
    this.displayTermsAndConditions = true;
  }

  closeTermsAndConditions() {
    this.displayTermsAndConditions = false;
  }
}
