<form [formGroup]="limoFormGroup" class="d-grid gap-3" (ngSubmit)="onSubmit()">
  <ng-container *ngIf="screenDisplay === 'mobile'">
    <div class="row gx-0 car-rent-mobile" *transloco="let t">
      <div class="col-12 car-rent-form">
        <div class="car-rent-country">
          <p-dropdown [disabled]="disableLocation" (onChange)="onChangeRegion($event.value)" [options]="staticRegion"
            optionLabel="label" optionValue="code" placeholder="Select a region" formControlName="region"
            styleClass="form-control"></p-dropdown>
        </div>
      </div>
      <ng-container *ngIf="currentRegion === 'HK'">
        <div class="col-12 car-rent-form">
          <div class="car-rent-country" (click)="onShowPickupLocation()">
            <img src="assets/images/rac/pinpoint.svg">
            <p class="hk-location">
              {{pickupLabel || 'Pickup Point'}}
            </p>
          </div>
        </div>
        <div class="col-12 car-rent-form">
          <div class="car-rent-country" (click)="onShowDropOffLocation()">
            <img src="assets/images/rac/pinpoint.svg">
            <p class="hk-location">
              {{dropOffLabel || 'Drop-off Point'}}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentRegion === 'CH'">
        <div class="col-12 car-rent-form">
          <div class="car-rent-country" (click)="onShowChinaRegion()">
            <p>
              {{chinaServiceLabel || 'Service Region'}}
            </p>
          </div>
        </div>
      </ng-container>
      <div class="col-12">
        <div class="car-rent-country">
          <!-- <p-button type="submit" class="search-button">
            <span>{{ t("search") }}</span>
            <i class="fa-solid fa-arrow-right-long"></i>
          </p-button> -->
          <p-button type="submit" styleClass="search-button">
            <div class="search-button-container">
              <span>{{ t('search') }}</span>
              <i class="fa-solid fa-arrow-right-long"></i>
            </div>
          </p-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="screenDisplay === 'desktop'">
    <div class="row gx-0 car-rent" *transloco="let t">
      <div class="col-md-2 car-rent-form">
        <div class="car-rent-country">
          <label>{{t('location')}}</label>
          <p-dropdown [disabled]="disableLocation" (onChange)="onChangeRegion($event.value)" [options]="staticRegion"
            optionLabel="label" optionValue="code" placeholder="Select a region" formControlName="region"
            styleClass="form-control"></p-dropdown>
          <small class="text-danger">{{t('regionIsRequired')}}</small>
        </div>
      </div>
      <ng-container *ngIf="currentRegion === 'HK'">
        <div class="col-md-4 car-rent-form" *transloco="let t">
          <div class="row gx-0 car-rent-location">
            <div class="col-md-6 car-rent-location-sub">
              <label>{{ t("pickupLocation") }}</label>
              <div class="w-100 flex-wrap">
                <p-dropdown [options]="listLocationForPickup" (onChange)="onChangePickUpLocation($event)"
                  formControlName="limoZonePickUpLocation" optionLabel="name" optionValue="_id" [filter]="true"
                  filterBy="name" [showClear]="true" placeholder="{{t('selectPickupPoint')}}">
                </p-dropdown>
              </div>
            </div>
            <div class="col-md-6 car-rent-location-sub">
              <label>{{t('area')}}</label>
              <div class="w-100 flex-wrap">
                <p-dropdown [options]="listPickupArea" formControlName="limoZonePickupArea" optionLabel="name"
                  optionValue="_id" [filter]="true" filterBy="name" [showClear]="true"
                  placeholder="{{t('selectArea')}}">
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 car-rent-form" *transloco="let t">
          <div class="row gx-0 car-rent-location">
            <div class="col-md-6 car-rent-location-sub">
              <label>{{t('dropoffPoint')}}</label>
              <div class="w-100 flex-wrap">
                <p-dropdown [options]="listLocationForDropOff" (onChange)="onChangeDropOffLocation($event)"
                  formControlName="limoZoneDropOffLocation" optionLabel="name" optionValue="_id" [filter]="true"
                  filterBy="name" [showClear]="true" placeholder="{{t('selectDropoffPoint')}}">
                </p-dropdown>
              </div>
            </div>
            <div class="col-md-6 car-rent-location-sub">
              <label>{{t('area')}}</label>
              <div class="w-100 flex-wrap">
                <p-dropdown [options]="listDropOffArea" formControlName="limoZoneDropOffArea" optionLabel="name"
                  optionValue="_id" [filter]="true" filterBy="name" [showClear]="true"
                  placeholder="{{t('selectArea')}}">
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentRegion === 'CH'">
        <div class="col-md-8 car-rent-form" *transloco="let t">
          <div class="row gx-0 car-rent-location">
            <div class="col-md-12 car-rent-location-sub">
              <label>{{t('serviceRegion')}}</label>
              <div class="w-100 flex-wrap">
                <p-dropdown [options]="listChinaMap" formControlName="chinaService" optionLabel="name"
                  optionValue="code" [filter]="true" filterBy="name" [showClear]="true"
                  placeholder="{{t('selectServiceRegion')}}">
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-md-2 car-rent-form" *transloco="let t">
        <!-- <p-button type="submit" class="search-button">
          <span>{{ t("search") }}</span>
          <i class="fa-solid fa-arrow-right-long"></i>
        </p-button>
         -->
        <p-button type="submit" styleClass="search-button">
          <div class="search-button-container">
            <span>{{ t('search') }}</span>
            <i class="fa-solid fa-arrow-right-long"></i>
          </div>
        </p-button>
      </div>
    </div>
  </ng-container>
</form>

<p-dialog [header]="'Pickup Location'" [modal]="true" [(visible)]="showPickupLocation" (onHide)="closeModal()"
  [dismissableMask]="true" *transloco="let t">
  <form [formGroup]="limoFormGroup" class="d-grid gap-3">
    <div class="row gx-0 car-rent-location">
      <div class="col-12 car-rent-location-sub mb-3">
        <div class="w-100 flex-wrap">
          <p-dropdown [options]="listLocationForPickup" (onChange)="onChangePickUpLocation($event)"
            formControlName="limoZonePickUpLocation" optionLabel="name" optionValue="_id" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="{{t('selectPickupPoint')}}">
          </p-dropdown>
        </div>
      </div>
      <div class="col-12 car-rent-location-sub">
        <div class="w-100 flex-wrap">
          <p-dropdown [options]="listPickupArea" formControlName="limoZonePickupArea" optionLabel="name" optionValue="_id"
            [filter]="true" filterBy="name" [showClear]="true" placeholder="{{t('selectArea')}}">
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="row gx-0 confirm-container">
      <button (click)="closeModal()" class="button-confirm">Confirm</button>
    </div>
  </form>
</p-dialog>

<p-dialog [header]="'Drop-Off Location'" [modal]="true" [(visible)]="showDropOffLocation" (onHide)="closeModal()"
  [dismissableMask]="true" *transloco="let t">
  <form [formGroup]="limoFormGroup" class="d-grid gap-3">
    <div class="row gx-0 car-rent-location">
      <div class="col-12 car-rent-location-sub mb-3">
        <div class="w-100 flex-wrap">
          <p-dropdown [options]="listLocationForDropOff" (onChange)="onChangeDropOffLocation($event)"
            formControlName="limoZoneDropOffLocation" optionLabel="name" optionValue="_id" [filter]="true" filterBy="name"
            [showClear]="true" placeholder="{{t('selectDropoffPoint')}}">
          </p-dropdown>
        </div>
      </div>
      <div class="col-12 car-rent-location-sub">
        <div class="w-100 flex-wrap">
          <p-dropdown [options]="listDropOffArea" formControlName="limoZoneDropOffArea" optionLabel="name"
            optionValue="_id" [filter]="true" filterBy="name" [showClear]="true" placeholder="{{t('selectArea')}}">
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="row gx-0 confirm-container">
      <button (click)="closeModal()" class="button-confirm">Confirm</button>
    </div>
  </form>
</p-dialog>

<p-dialog [header]="'Service Region'" [modal]="true" [(visible)]="showChinaRegion" (onHide)="closeModal()"
  [dismissableMask]="true" *transloco="let t">
  <form [formGroup]="limoFormGroup" class="d-grid gap-3">
    <div class="row gx-0 car-rent-location">
      <div class="col-12 car-rent-location-sub">
        <div class="w-100 flex-wrap">
          <p-dropdown [options]="listChinaMap" formControlName="chinaService" optionLabel="name" optionValue="code"
            [filter]="true" filterBy="name" [showClear]="true" placeholder="{{t('selectServiceRegion')}}">
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="row gx-0 confirm-container">
      <button (click)="closeModal()" class="button-confirm">Confirm</button>
    </div>
  </form>
</p-dialog>