<ul class="step-progress-bar">
    <ng-container *ngFor="let item of items;let i = index;">
        <li class="{{i < activeIndex ? 'cursor' : ''}}" (click)="onClick(i, item)">
            <div class="{{activeIndex === i + 1 ? 'progress-bar-active' : 'progress-bar-cont'}}">
                <div class="step-title">{{item.label}}</div>
                <div class="step-number"><span>{{i + 1}}</span></div>
            </div>
        </li>
    </ng-container>
</ul>
<div *transloco="let t">
    <div *ngIf="showCountDown" class="wrapper-count-down">{{t('selectCarAvail')}} <strong>{{counter | formatTime}}</strong>  {{t('minutes')}}</div>
    <p-dialog header="Timeout" [closable]="false" [modal]="true" [(visible)]="arrivalTime" [draggable]="false" [resizable]="false" [style]="{width: '50vw'}">
        <p class="content">{{t('outSession')}}<br/>{{t('selectAgain')}}</p>
            <ng-template pTemplate="footer" *transloco="let t">
                <p-button (click)="onCloseModal()" label="{{ t('selectACar') }}" styleClass="p-button-text"></p-button>
            </ng-template>
    </p-dialog>
</div>

