<p-messages styleClass="top-notification" [(value)]="msgs" [enableService]="false"></p-messages>
<p-menubar styleClass="dch-menu" [ngClass]="!isHomePage ? 'bg-white-transparent' : ''">
  <ng-template pTemplate="start">
    <a routerLink="/home-page">
      <img src="assets/images/home-page/logo_DCH.png" class="mr-2 logo" />
    </a>
  </ng-template>
  <ng-template pTemplate="end">
    <div class="right-menu" *transloco="let t">
      <!-- <p-button
        type="button"
        label="{{ t('news') }}"
        routerLink="{{ ROUTE.NEWS }}"
        styleClass="p-button-secondary p-button-text"
      ></p-button>
      <div class="dropdown">
        <p-button
          type="button"
          label="{{ t('racLimo') }}"
          styleClass="p-button-secondary p-button-text"
        ></p-button>
        <div class="dropdown-content">
          <a routerLink="{{ ROUTE.RENT_CAR }}">{{ t("rentACar") }}</a>
          <a routerLink="{{ ROUTE.OVERSEAS }}">{{ t("overseas") }}</a>
          <div class="dropdown-limo">
            <p-button
              type="button"
              label="{{ t('limousineService') }}"
              styleClass="p-button-secondary p-button-text"
            ></p-button>
            <div class="dropdown-content-limo">
              <a routerLink="/limousine-service/hk">{{
                t("HK_Cross_Border_Limousine_Services")
              }}</a>
              <a routerLink="/limousine-service/cn">{{
                t("chinaLimousineService")
              }}</a>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <p-button
        type="button"
        label="{{ t('pricing') }}"
        routerLink="{{ ROUTE.RATE_CARD }}"
        styleClass="p-button-secondary p-button-text"
      ></p-button>
      <p-button
        type="button"
        label="{{ t('vanLogistics') }}"
        routerLink="{{ ROUTE.VAN_LOGISTICS }}"
        styleClass="p-button-secondary p-button-text"
      ></p-button>

      <p-button
        type="button"
        routerLink="{{ ROUTE.LONG_TERM_LEASING }}"
        label="{{ t('longTermLeasing') }}"
        styleClass="p-button-secondary p-button-text"
      ></p-button> -->

      <!-- <div class="dropdown-content">
          <a routerLink={{ROUTE.CORPORATE}}>{{ t('corporate') }}</a>
          <a routerLink={{ROUTE.INDIVIDUAL}}>{{ t('individual') }}</a>
        </div> -->

      <!-- <p-button
        type="button"
        label="{{ t('advertisingTruck') }}"
        routerLink="{{ ROUTE.ADVERTISING_TRUCK }}"
        styleClass="p-button-secondary p-button-text"
      ></p-button>
      <div class="dropdown">
        <p-button
          type="button"
          label="{{ t('carshare') }}"
          styleClass="p-button-secondary p-button-text"
        ></p-button>
        <div class="dropdown-content">
          <a routerLink="{{ ROUTE.PROPERTY }}">{{ t("property") }}</a>
          <a routerLink="{{ ROUTE.APRON_GO }}">{{ t("Aprongo") }}</a>
        </div>
      </div> -->
      <!-- <p-button
        type="button"
        label="{{ t('checkYourBooking') }}"
        routerLink="{{ ROUTE.LOOKUP }}"
        styleClass="p-button-secondary p-button-text"
      ></p-button> -->
      <button *ngIf="!(isLoggedIn$ | async)" (click)="login()" class="btn-menu">
        <img class="user-icon" [src]="userIcon" alt="user-icon-button" />
      </button>

      <p-button *ngIf="isLoggedIn$ | async" styleClass="p-button-secondary p-button-text"
        (click)="userMenu.toggle($event)">
        <img class="user-icon" [src]="userIcon" alt="user-icon-button" />
        <span [style.color]="colorDynamic" class="current-user">
          {{ t("hi") }}, {{ userNameLogged$ | async }}
        </span>
      </p-button>
      <p-menu #userMenu [popup]="true" [model]="userMenuItems"></p-menu>

      <div class="dropdown">
        <p-button type="button" styleClass="p-button-secondary p-button-text gap-5px">
          <!-- <img class="user-icon" src="assets/images/lang-icon.png" alt="lang-icon-button" /> -->
          <i class="fa-solid fa-globe medium-icon" [style.color]="colorDynamic">
            <span [style.color]="colorDynamic" class="current-lang">
              {{ currentLangDisplay }}
            </span>
          </i>
        </p-button>
        <div class="dropdown-content">
          <a (click)="changeLanguage('EN')" style="cursor: pointer">English</a>
          <a (click)="changeLanguage('TC')" style="cursor: pointer">繁體中文</a>
          <a (click)="changeLanguage('SC')" style="cursor: pointer">简体中文</a>
        </div>
      </div>
      <!-- <div style="width: 1px; height:60px; background: black; margin-right: 10px;"></div> -->
      <!-- <p-button
        *ngIf="!(isLoggedIn$ | async)"
        type="button"
        label="{{ t('signIn_Register') }}"
        styleClass="p-button-secondary p-button-text p-custom-signin-btn"
        routerLink="/sign-in"
      ></p-button> -->

      <!-- <p-button
        *ngIf="!(isLoggedIn$ | async)"
        type="button"
        label="{{ t('signIn_Register') }}"
        styleClass="p-button-secondary p-button-text p-custom-signin-btn"
        (click)="handleShowLogin()"
      ></p-button> -->
    </div>
    <button (click)="onShowMenu()" class="btn-menu">
      <img [src]="burgerIcon" alt="more-icon-button" class="hamburger" />
    </button>
  </ng-template>
</p-menubar>
<p-sidebar [(visible)]="visibleRightSideBar" position="right" [baseZIndex]="1">
  <p-panelMenu styleClass="header-side-menu" [model]="topMenuItems" [multiple]="false"></p-panelMenu>

  <p-panelMenu styleClass="header-side-menu bottom-menu" [model]="bottomMenuItems" [multiple]="false"></p-panelMenu>
</p-sidebar>

<app-signin-dialog [isVisible]="showLogin" (onClose)="handleCloseLogin()"
  (onShowRegister)="handleShowRegister()"></app-signin-dialog>

<app-register-dialog [isVisible]="showRegister" (onClose)="handleCloseRegister()"></app-register-dialog>