<form *ngIf="rentCarForm" [formGroup]="rentCarForm" class="d-grid gap-3 mt-3 mb-3" (ngSubmit)="onSubmit()">
  <!-- country dialog -->
  <p-dialog *ngIf="!isDesktop" [modal]="true" [(visible)]="showCountry" (onHide)="dialogCountryHandler('close')"
    styleClass="form-dialog" class="form-dialog-wrapper" [dismissableMask]="true">
    <div class="form-dialog-header">Location</div>
    <div class="form-dialog-body">
      <p-dropdown [options]="regions" optionLabel="name" optionValue="_id" placeholder="Select a region"
        formControlName="region" (onChange)="onChangeRegion()" styleClass="form-control">
        <ng-template let-option pTemplate="selectedItem">
          <div class="selected-item-wrapper">
            <img [src]="getFlag(option.code)" class="flag-icon" alt="Flag" />
            <span style="margin-left: 10px">{{ option.name }}</span>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div class="dropdown-item-wrapper dropdown__item">
            <img [src]="getFlag(option.code)" class="flag-icon" alt="Flag" />
            <span style="margin-left: 10px">{{ option.name }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </p-dialog>

  <!-- location dialog -->
  <p-dialog *ngIf="!isDesktop" [modal]="true" [(visible)]="showLocation" (onHide)="dialogLocationHandler('close')"
    styleClass="form-dialog" class="form-dialog-wrapper" [dismissableMask]="true">
    <div class="form-dialog-header">Pickup & Return Point</div>
    <div class="form-dialog-body">
      <div class="col-md-4" *transloco="let t">
        <div class="row gx-0 car-rent-location">
          <div class="col-md-6 car-rent-location-sub">
            <label>{{ t("pickupLocation") }}</label>
            <div class="w-100 flex-wrap">
              <p-dropdown [options]="locations$ | async" formControlName="pickupLocation" optionLabel="name"
                [filter]="true" filterBy="name" [showClear]="true" placeholder="Enter Location" (onHide)="onDropdownClose()">
                <ng-template let-location pTemplate="item">
                  <div (mouseover)="onMouseOver(location)">
                    {{ location.name }}
                  </div>

                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-6 car-rent-location-sub">
            <label>Return to</label>
            <div class="w-100 flex-wrap">
              <p-dropdown [options]="locations$ | async" formControlName="dropOffLocation" optionLabel="name"
                [filter]="true" filterBy="name" [showClear]="true" placeholder="Same Location" (onHide)="onDropdownClose()">
                <ng-template let-location pTemplate="item">
                  <div (mouseover)="onMouseOver(location)">
                    {{ location.name }}
                  </div>

                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div *ngIf="showMap && !isDesktop" class="content-map-mobile">
            <p style="font-size: 20px; font-weight: bold">
              Location: {{ itemLocation?.name }}
            </p>
            <p style="font-size: 14px">
              Address: {{ itemLocation?.address }}
            </p>
            <p-gmap (onMapReady)="setMap($event)" [options]="itemLocation?.options" [overlays]="itemLocation?.overlays"
              [style]="{
                width: '100%',
                height: '320px',
                'margin-top': '30px'
              }"></p-gmap>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <!-- time dialog -->
  <p-dialog *ngIf="!isDesktop" [modal]="true" [(visible)]="showTime" (onHide)="dialogTimeHandler('close')"
    styleClass="form-dialog" class="form-dialog-wrapper" [dismissableMask]="true">
    <div class="form-dialog-header">Pickup & Drop-off Time</div>
    <div class="form-dialog-body">
      <div class="col-md-2">
        <div class="car-rent-time">
          <label>Pickup Time</label>
          <p-calendar placeholder="Pick-up Date" formControlName="pickupDate" styleClass="d-flex" #pickupDate
            [showIcon]="false" icon="fas fa-calendar-alt" [minDate]="pickupMinDate" [maxDate]="pickupMaxDate"
            [dateFormat]="DATE_PICKER_FORMAT" [numberOfMonths]="2" (onSelect)="onSelectPickupDate($event, endDate)"
            inputId="pickup-date">
            <ng-template pTemplate="header">
              <div class="p-calendar-header">
                <div class="row">
                  <div class="col-11" style="margin: auto">
                    {{
                    rentCarForm.get("pickupDate").value | date : "MMM d, y"
                    }}
                    -
                    {{
                    rentCarForm.get("dropOffDate").value
                    ? (rentCarForm.get("dropOffDate").value
                    | date : "MMM d, y")
                    : "Drop-off Date"
                    }}
                  </div>
                  <div class="col-1">
                    <p-button (onClick)="closeCalendar(pickupDate)" icon="pi pi-times"></p-button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-calendar>
          <small class="text-danger">Pickup date is required.</small>
          <app-time-picker [defaultValue]="rentCarForm.controls['pickupTime'].value" label="Pick-up Time"
            (selected)="onSelectPickupTime($event)"></app-time-picker>
        </div>
      </div>

      <div class="col-md-2">
        <div class="car-rent-time">
          <label>Drop-off Time</label>
          <p-calendar formControlName="dropOffDate" styleClass="d-flex" [showIcon]="false" icon="fas fa-calendar-alt"
            (onFocus)="onDropOffDateFocus(endDate)" [hidden]="!hiddenEndDate" [dateFormat]="DATE_PICKER_FORMAT"
            [maxDate]="maxDate" inputId="pickup-date">
          </p-calendar>
          <p-calendar placeholder="Drop-off Date" formControlName="endDate" styleClass="d-flex" #endDate
            [showIcon]="false" icon="fas fa-calendar-alt" selectionMode="range" [hidden]="hiddenEndDate"
            [numberOfMonths]="2" (onClickOutside)="onEndDateClickOutside()"
            (onSelect)="onSelectEndDate($event, endDate)" [dateFormat]="DATE_PICKER_FORMAT" [maxDate]="maxDate"
            inputId="dropoff-date">
            <ng-template pTemplate="header">
              <div class="p-calendar-header">
                <div class="row">
                  <div class="col-11" style="margin: auto">
                    {{
                    rentCarForm.get("pickupDate").value | date : "MMM d, y"
                    }}
                    -
                    {{
                    rentCarForm.get("dropOffDate").value
                    ? (rentCarForm.get("dropOffDate").value
                    | date : "MMM d, y")
                    : "Drop-off Date"
                    }}
                  </div>
                  <div class="col-1">
                    <p-button (onClick)="closeCalendar(endDate)" icon="pi pi-times"></p-button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-calendar>
          <small class="text-danger">Dropoff Date is required.</small>
          <app-time-picker [defaultValue]="rentCarForm.controls['dropOffTime'].value" label="Drop-off Time"
            (selected)="onSelectDropOffTime($event)"></app-time-picker>
        </div>
      </div>
    </div>
  </p-dialog>

  <div class="row gx-0 car-rent">
    <!-- MOBILE -->
    <button type="button" (click)="dialogCountryHandler('open')" class="car-rent-form-mobile">
      <div *ngIf="countryLabel;else countryDefaultLabel">
        <label>
          <p style="margin-bottom: unset;">
            {{countryLabel}}
          </p>
        </label>
      </div>

      <ng-template #countryDefaultLabel>
        <label>Country </label>
      </ng-template>

      <img src="../../../../assets/images/icons/arrow-down.svg" alt="arrow-down" />
    </button>

    <button type="button" (click)="dialogLocationHandler('open')" class="car-rent-form-mobile">
      <img src="../../../../assets/images/icons/ic-location.svg" alt="arrow-down" />

      <div *ngIf="pickupLabel || dropOffLabel;else locationDefaultLabel">
        <label>
          <p style="margin-bottom: unset; font-size: 16px;">
            {{pickupLabel}}
          </p>
          <p style="margin-bottom: unset; font-size: 16px;">
            {{dropOffLabel}}
          </p>
        </label>
      </div>

      <ng-template #locationDefaultLabel>
        <label>Pickup & Return Point </label>
      </ng-template>


      <img src="../../../../assets/images/icons/arrow-down.svg" alt="arrow-down" />
    </button>

    <button type="button" (click)="dialogTimeHandler('open')" class="car-rent-form-mobile">
      <img src="../../../../assets/images/icons/ic-location.svg" alt="arrow-down" />

      <div *ngIf="pickupDateLabel;else dateDefaultLabel">
        <label>
          <span style="font-size: 16px;">{{pickupDateLabel}}</span>
          <i style="font-size: smaller; padding: 0 5px;" class="fa-solid fa-arrow-right-long"></i>
          <span style="font-size: 16px;">{{dropOffDateLabel}}</span>
        </label>
      </div>

      <ng-template #dateDefaultLabel>
        <label>Pickup & Drop-off Time </label>
      </ng-template>


      <img src="../../../../assets/images/icons/arrow-down.svg" alt="arrow-down" />
    </button>

    <!-- DESKTOP -->
    <div class="col-md-2 car-rent-form-desktop" style="border-right: 0.5px solid white" *transloco="let t">
      <div class="car-rent-country">
        <label>{{ t('country') }}</label>
        <p-dropdown [options]="regions" optionLabel="name" optionValue="_id" placeholder="Select a region"
          formControlName="region" (onChange)="onChangeRegion()" styleClass="form-control">
          <ng-template let-option pTemplate="selectedItem">
            <div class="selected-item-wrapper">
              <img [src]="getFlag(option.code)" class="flag-icon" alt="Flag" />
              <span style="margin-left: 10px">{{ t(option.code) }}</span>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="dropdown-item-wrapper dropdown__item">
              <img [src]="getFlag(option.code)" class="flag-icon" alt="Flag" />
              <span style="margin-left: 10px">{{ t(option.code) }}</span>
            </div>
          </ng-template></p-dropdown>
        <small class="text-danger" *transloco="let t">{{ t("regionIsRequired") }}.</small>
      </div>
    </div>
    <div class="col-md-4 car-rent-form-desktop" *transloco="let t">
      <div class="row gx-0 car-rent-location">
        <div class="col-md-6 car-rent-location-sub">
          <label>{{ t("pickupLocation") }}</label>
          <div class="w-100 flex-wrap">
            <p-dropdown [options]="locations$ | async" formControlName="pickupLocation" optionLabel="name"
              [filter]="true" filterBy="name, zoneName" [showClear]="true" placeholder="{{ t('enterLocation') }}"
              (onHide)="onDropdownClose()">
              <ng-template let-location pTemplate="item">
                <div (mouseover)="onMouseOver(location, $event)">
                  {{location.name}}
                </div>
              </ng-template>
            </p-dropdown>
            <!-- <div *ngIf="showMap" class="content-map" [ngStyle]="contentMapStyle">
              <p style="font-size: 20px; font-weight: bold">
                Location: {{ itemLocation?.name }}
              </p>
              <p style="font-size: 14px">
                Address: {{ itemLocation?.address }}
              </p>
              <p-gmap (onMapReady)="setMap($event)" [options]="itemLocation?.options"
                [overlays]="itemLocation?.overlays" [style]="{
                  width: '100%',
                  height: '320px',
                  'margin-top': '30px'
                }"></p-gmap>
            </div> -->
          </div>
          <!-- <div *ngIf="showMap" class="map"> -->

          <!-- </div> -->
        </div>
        <div class="col-md-6 car-rent-location-sub">
          <label>{{ t("returnTo") }}</label>
          <div class="w-100 flex-wrap">
            <p-dropdown [options]="locations$ | async" formControlName="dropOffLocation" optionLabel="name"
              [filter]="true" filterBy="name" [showClear]="true" placeholder="{{ t('sameLocation') }}"
              (onHide)="onDropdownClose()">
              <ng-template let-location pTemplate="item">
                <div (mouseover)="onMouseOver(location, $event)">
                  {{location.name}}
                </div>
              </ng-template>
            </p-dropdown>
            <!-- <div *ngIf="showMap" class="content-map" [ngStyle]="contentMapStyle">
              <p style="font-size: 20px; font-weight: bold">
                Location: {{ itemLocation?.name }}
              </p>
              <p style="font-size: 14px">
                Address: {{ itemLocation?.address }}
              </p>
              <p-gmap (onMapReady)="setMap($event)" [options]="itemLocation?.options"
                [overlays]="itemLocation?.overlays" [style]="{
                  width: '100%',
                  height: '320px',
                  'margin-top': '30px'
                }"></p-gmap>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 car-rent-form-desktop inline-form" *transloco="let t">
      <div class="car-rent-time">
        <label>{{ t("pickupTime") }}</label>
        <p-calendar placeholder="Pick-up Date" formControlName="pickupDate" styleClass="d-flex" #pickupDate
          [showIcon]="false" icon="fas fa-calendar-alt" [minDate]="pickupMinDate" [maxDate]="pickupMaxDate"
          [dateFormat]="DATE_PICKER_FORMAT" [numberOfMonths]="2" (onSelect)="onSelectPickupDate($event, endDate)"
          inputId="pickup-date">
          <ng-template pTemplate="header">
            <div class="p-calendar-header">
              <div class="row">
                <div class="col-11" style="margin: auto">
                  {{ rentCarForm.get("pickupDate").value | date : "MMM d, y" }}
                  -
                  {{
                  rentCarForm.get("dropOffDate").value
                  ? (rentCarForm.get("dropOffDate").value
                  | date : "MMM d, y")
                  : "Drop-off Date"
                  }}
                </div>
                <div class="col-1">
                  <p-button (onClick)="closeCalendar(pickupDate)" icon="pi pi-times"></p-button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-calendar>
        <small class="text-danger">Pickup date is required.</small>
        <app-time-picker [defaultValue]="rentCarForm.controls['pickupTime'].value" label="Pick-up Time"
          (selected)="onSelectPickupTime($event)"></app-time-picker>
      </div>
    </div>
    <div class="col-md-2 car-rent-form-desktop inline-form">
      <div class="car-rent-time">
        <label *transloco="let t">{{ t("dropOffTime") }}</label>
        <p-calendar formControlName="dropOffDate" styleClass="d-flex" [showIcon]="false" icon="fas fa-calendar-alt"
          (onFocus)="onDropOffDateFocus(endDate)" [hidden]="!hiddenEndDate" [dateFormat]="DATE_PICKER_FORMAT"
          [maxDate]="maxDate" inputId="pickup-date">
        </p-calendar>
        <p-calendar placeholder="Drop-off Date" formControlName="endDate" styleClass="d-flex" #endDate
          [showIcon]="false" icon="fas fa-calendar-alt" selectionMode="range" [hidden]="hiddenEndDate"
          [numberOfMonths]="2" (onClickOutside)="onEndDateClickOutside()" (onSelect)="onSelectEndDate($event, endDate)"
          [dateFormat]="DATE_PICKER_FORMAT" [maxDate]="maxDate" inputId="dropoff-date">
          <ng-template pTemplate="header">
            <div class="p-calendar-header">
              <div class="row">
                <div class="col-11" style="margin: auto">
                  {{ rentCarForm.get("pickupDate").value | date : "MMM d, y" }}
                  -
                  {{
                  rentCarForm.get("dropOffDate").value
                  ? (rentCarForm.get("dropOffDate").value
                  | date : "MMM d, y")
                  : "Drop-off Date"
                  }}
                </div>
                <div class="col-1">
                  <p-button (onClick)="closeCalendar(endDate)" icon="pi pi-times"></p-button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-calendar>
        <small class="text-danger">Dropoff Date is required.</small>
        <app-time-picker [defaultValue]="rentCarForm.controls['dropOffTime'].value" label="Drop-off Time"
          (selected)="onSelectDropOffTime($event)"></app-time-picker>
      </div>
    </div>
    <div class="col-md-2 car-rent-form" *transloco="let t">
      <!-- <p-button type="submit" class="search-button"
        [disabled]="rentCarForm.invalid || !rentCarForm.controls['pickupLocation']?.value">
        <span>{{ t('search') }}</span>
        <i class="fa-solid fa-arrow-right-long"></i>
      </p-button> -->
      <p-button type="submit" styleClass="search-button" icon="fa-solid fa-arrow-right-long" iconPos="right"
        label="{{ t('search') }}" [disabled]="rentCarForm.invalid || !rentCarForm.controls['pickupLocation']?.value">
        <span>{{ t('search') }}</span>
      </p-button>
    </div>
  </div>
</form>
<div *ngIf="showMap && isDesktop" class="content-map" [ngStyle]="contentMapStyle">
  <p style="font-size: 20px; font-weight: bold">
    Location: {{ itemLocation?.name }}
  </p>
  <p style="font-size: 14px">
    Address: {{ itemLocation?.address }}
  </p>
  <p-gmap (onMapReady)="setMap($event)" [options]="itemLocation?.options"
    [overlays]="itemLocation?.overlays" [style]="{
      width: '100%',
      height: '320px',
      'margin-top': '30px'
    }"></p-gmap>
</div>