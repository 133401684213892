import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { S3FileDomain } from 'src/app/model/s3-file';

export let s3Static = environment.staticPath;

const httpOptions = {
  headers: new HttpHeaders({
    'skip-auth': 'true'
  })
};
@Injectable({
  providedIn: 'root'
})
export class S3FilesDomainService {
  url: string = `${environment.api}s3/domain`;
  currentEnvName: string = environment.envName;

  constructor(private http: HttpClient) {}

  getFileDomain(): string | null {
    let domainJSON =  this.getDomainUrlFromLocal();

    if (domainJSON) {
      try {
        let domainData = JSON.parse(domainJSON);
        const savedEnvName = domainData.envName;

        if (savedEnvName) {
          if (this.currentEnvName == savedEnvName) {
            s3Static = domainData.domain;
            return domainData.domain;
          } else {
            this.fetchDomainAndSaveToLocal();
            return this.getDomainUrlFromLocal();
          }
        }

        return domainData;
      } catch (error) {
        this.fetchDomainAndSaveToLocal();
        return this.getDomainUrlFromLocal();
      }
    } else {
      this.fetchDomainAndSaveToLocal();
      return this.getDomainUrlFromLocal();
    }
  }

  getFiles(imagePath: string): string {
    return this.getFileDomain() + imagePath;
  }

  private getDomainUrlFromLocal(): string {
    try {
      const domainJSON = localStorage.getItem('file-domain');

      if (domainJSON) {
        return JSON.parse(domainJSON).domain;
      } else {
        return environment.staticPath;
      }
    } catch (error) {
      return environment.staticPath;
    }
  }

  private async fetchDomain(): Promise<string> {
    const resp = await firstValueFrom(this.http.get<S3FileDomain>(`${this.url}`, httpOptions));
    return resp.data;
  }

  private fetchDomainAndSaveToLocal() {
    this.fetchDomain().then(result => {
      this.saveDomainToLocal(result);
    });
  }

  private saveDomainToLocal(domain: string) {
    let domainResult = domain;
    if (!domain.endsWith('/')) {
      domainResult = domainResult + '/';
    }
    const data = {
      domain: domainResult,
      envName: this.currentEnvName
    };
    s3Static = domainResult;

    localStorage.setItem('file-domain', JSON.stringify(data));
  }
}