import { ICarGradeItem, PaymentType } from './car-selection';
import { RentType, TimeBase } from './home-page';
import { IPlace } from './location';
import { IOptionalItem } from './optional-item';

export interface IOrderData {
  orderNo?: string;
  place: IPlace;
  carGrade?: ICarGradeItem;
  optionalItems?: IOptionalItem[];
  totalPrice?: number;
  totalDays?: number;
  timebase?: TimeBase;
  rentType?: RentType;
  discountAmount?: number;
  discountType?: DiscountType;
  discountedPrice?: number;
  specialRequest?: string;
  searchTime?: Date | string;
  orderStatusCode?: string;
  _id?: string;
  selectedCarGrade?: ISelectedCarGrade;
  createdAt?: string;
}

export interface ISelectedCarGrade {
  tempBookingNo: string;
  pickupTime: string;
  dropOffTime: string;
  mlsVehicleLicNo: string;
}


export interface IOrderEnquire {
  region: string;
  carGrade: string;
  pickupLocation: string;
  dropOffLocation?: string;
  pickupAddress?: string;
  dropOffAddress?: string;
  pickupTime: string;
  dropOffTime: string;
  timebase?: TimeBase;
  rentType: RentType;
  becomeMember: boolean;
  termFriendOfDCH: boolean;
  HOW_TO_KNOW_MLS: string;
  token?: string;
  email?: string;
  name?: string;
  surName?: string;
  areaCode?: string;
  phone?: string;
  membershipId?: string;
  optionalItems?: string[];
  promotionCode?: string;
  paymentMethod: PaymentType;
  specialRequest?: string;
  airlineId?: string;
  flightNo?: string,
  termAgreeToJoinDCH: boolean,
  arrivalTime: string,
  vehicleLicNo?: string,
  tempBookingNo?: string,
  providerName?: string,
  
  carGradeName?: string,
  carGradeImage?: string,
  carGradeSeat?: string,
  carGradeLargeSuitcase?: string,
  carGradeSmallSuitcase?: string,
  carGradeEstimatedPrice?: string,
  carGradePaymentMethod?: string,
  carGradeSystemCurrencyCode?: string,
}

export interface IPromotionCodeParams {
  region: string;
  carGrade: string;
  rentType: string;
  timebase: string;
  pickupLocation: string;
  dropOffLocation: string;
  pickupTime: string;
  dropOffTime: string;
  promotionCode: string;
  optionalItems?: string[];
}

export interface IPromotionCodeResponse {
  beforeDiscountPrice: number;
  discountAmount: number;
  discountCurrencyCode: string;
  discountCurrencyId: string;
  discountType: DiscountType;
  promotionDescription: string;
  totalPrice: number;
  errors?: { [key: string]: string };
}

export enum DiscountType {
  BY_VALUE = 'BY_VALUE',
  BY_PERCENTAGE = 'BY_PERCENTAGE'
}

export interface OrderEnquireResponse {
  carGradeId: string;
  chargeTimeBaseCode: string;
  customerAreaCode: string;
  customerContactNumber: string;
  customerFirstName: string;
  customerLastName: string;
  discountAmount: number;
  discountCurrencyId: string;
  discountType: DiscountType;
  lastEmailSentDate: string;
  membershipId: string;
  optionalItemIds: string[];
  orderNo: string;
  orderStatusCode: string;
  orderTypeCode: string;
  pickupLocation: string;
  pickupTime: string;
  rentTypeCode: RentType
  returnLocation: string;
  returnTime: string;
  systemCurrencyId: string;
  systemRegionId: string;
  totalPrice: number;
  _id?: string;
}

export interface IOrderDetail {
  _id: string;
  pickupTime: string;
  returnTime: string;
  regionName: string;
  orderNo: string;
  carGradeName: string;
  carGradeImage: Array<string>;
  pickupLocationName: string;
  returnLocationName: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerAreaCode: string;
  customerContactNumber: string;
  orderStatusCode: string;
  discountType: string;
  discountAmount: string;
  place: {
    region: {
      name: string,
      code: string,
    },
    pickupTime: string,
    dropOffTime: string,
    pickupLocation: {
      name: string,
    },
    dropOffLocation: {
      name: string,
    },
  },
  carGrade: {
    seats: string,
    images: Array<string>,
    smallSuitcases: string,
    largeSuitcases: string,
    estimatedPrice: string,
    normalPrice: string,
    paymentMethod: string,
    systemCurrencyCode: string,
    name: string,
  },
  rentType: string,
  totalPrice: string,
  discountedPrice: string,
  optionalItems?: IOptionalItem[],
  totalDays: number,
  specialRequest?: string,
}

export interface CancelOrderResponse {
  status: string
}
