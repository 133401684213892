<div class="d-flex justify-content-between py-4" *ngIf="item">
  <div>
    <p>{{ item.optionalItemName }}</p>
    <p [innerHTML]="item.description"></p>
  </div>
  <div>
    <label class="me-4">
      <ng-container *ngIf="item.priceType === PriceType.FIXED_PRICE">
        {{ item.price }}{{ item.systemCurrencyName }}
      </ng-container>
      <ng-container *ngIf="item.priceType === PriceType.DAY_PRICE">
        {{ item.price }}{{ item.systemCurrencyName }} / Day
      </ng-container>
    </label>
    <p-checkbox *ngIf="checkbox" [binary]="true" [(ngModel)]="item.checked" (onChange)="onCheck()"></p-checkbox>
  </div>
</div>
