import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DEFAULT_DATE_TIME_PICKER } from 'src/app/shared/constant/constant';
import { initTimePicker } from 'src/app/shared/utils/common';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  timePicker: String[] = [];
  @Input() label: string = '';
  @Output() selected: EventEmitter<string> = new EventEmitter();
  pickerTimeForm: FormGroup | undefined;
  @Input() defaultValue: string = DEFAULT_DATE_TIME_PICKER;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.timePicker = initTimePicker();
    this.languageCheck();
    this.pickerTimeForm = this.formBuilder.group({
      pickTime: [this.defaultValue, Validators.required],
    });
  }
  onChangeItem(event) {
    this.selected.emit(event.value);
  }
  languageCheck() {
    const currentLang = localStorage.getItem('currentLang');
    let noonLabel = 'noon';
    switch (currentLang) {
      case 'TC':
        noonLabel = '上午';
        break;
      case 'SC':
        noonLabel = '上午';
        break;
    
      default:
        noonLabel = 'noon';
        break;
    }
    this.timePicker.forEach(val => {
      if (val['name'] == 'noon') val['name'] = noonLabel;
    });
  }
}
