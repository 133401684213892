import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRegion, IRegionOption } from 'src/app/model/region';
import { ILocation } from 'src/app/model/location';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  url: string = `${environment.api}web`;

  constructor(private http: HttpClient) { }

  getRegions(): Observable<IRegionOption[]> {
    return this.http.get<IRegionOption[]>(`${this.url}/get-regions`);
  }

  getLocationsByRegion(regionId: string): Observable<IRegion[]> {
    return this.http.get<IRegion[]>(`${this.url}/get-locations-by-region?region=${regionId}`);
  }

  searchLocations(regionId: string, locationQuery: string): Observable<ILocation[]> {
    return this.http.get<ILocation[]>(`${this.url}/order/search-locations?region=${regionId}&locationQuery=${locationQuery}`);

  }
}
