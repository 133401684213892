import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})


export class BreadcrumbComponent implements OnInit {
  language:string = localStorage.getItem('currentLang');
  HomeText:string = '';
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  home = { label: this.language, routerLink: 'home-page' };
  menuItems: MenuItem[];
  isShowBreadCrumb: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService) {}

  ngOnInit(): void {
    switch (this.language) {
      case 'EN':
        this.HomeText = 'HOME';
        break;
      case 'TC':
        this.HomeText = '主頁';
        break;
      case 'SC':
        this.HomeText = '主页';
        break;
      default:
        this.HomeText = 'HOME';
        break;
    }
    this.home = { label: this.HomeText, routerLink: 'home-page' };
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      
      if (routeURL !== '' && routeURL != 'news') {
        url = '/news';
      } else {
        url += `/${routeURL}`;
      }
      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!label) {
        this.isShowBreadCrumb = true;
      } else {
        this.isShowBreadCrumb = false;
      }
      
      if (label !== null && label !== undefined && label != 'Home Page') {
        const breadcrumbExisted = breadcrumbs.find(e => e.label === label);
        if (!breadcrumbExisted) {
          breadcrumbs.push({ label, routerLink: url });
        }
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}

