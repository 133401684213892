export interface ICarGradeItem {
  images: string[];
  name: string;
  seats: number;
  _id: string;
  estimatedPrice: number;
  extraFee?: number;
  systemCurrencyName: string;
  paymentMethod: PaymentType;
  systemCurrencyCode: string;
  largeSuitcases: number;
  smallSuitcases: number;
  mlsVehicleLicNo: string;
  tempBookingNo: string;
  carModels: ICarModels[];
  doors?: number;
  pickUpLocation?: any;
  provider?: any;
  fuel?: string;
  gearbox?: string;
  hasAirConditional?: boolean;
}

export interface ICarSearchResult {
  data: ICarGradeItem[];
}

export interface ICarModels {
  carGradeId: string,
  carModelName: string,
  carModelId: string,
  quantity: number
}

export enum PaymentType {
  ENQUIRY = 'ENQUIRY',
  ONLINE_PAYMENT = 'ONLINE PAYMENT',
}
