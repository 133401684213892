import { Component, Input  } from '@angular/core';

@Component({
  selector: 'app-contact-us-bottom',
  templateUrl: './contact-us-bottom.component.html',
  styleUrls: ['./contact-us-bottom.component.scss'],
})
export class ContactUsBottomComponent {
  @Input() content: string;
}
