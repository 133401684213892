import { Component, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from '../../services/order/order.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
})
export class CancelDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string, orderNo: string },
    private orderService: OrderService,
    private notificationService: NotificationsService,
  ) {}

  onSubmit() {
    this.orderService.cancelOrder(this.data.id).subscribe(
      () => {
        location.reload();
      }, (err: HttpErrorResponse) => {
        this.notificationService.showAlerts('Cancel failed '.concat(err.message), 500);
      }
    );
  }

}
