<p-dialog
  *transloco="let t"
  [modal]="true"
  [(visible)]="isVisible"
  (onHide)="closeModal()"
  styleClass="signin-dialog"
>
  <div class="card-container">
    <div class="card_header">
      <h3>{{ t("signIn") }}</h3>

      <div class="card-header-right">
        <a
          href="javascript:void(0)"
          *ngIf="!signInSMS"
          (click)="signInSMS = true"
          >{{ t("signInViaSMS") }}</a
        >
        <a
          href="javascript:void(0)"
          *ngIf="signInSMS"
          (click)="signInSMS = false"
          >{{ t("signInViaEMAIL") }}</a
        >
        <button (click)="closeModal()" class="btn-close"></button>
      </div>
    </div>

    <app-sign-in-form
      [isLoading]="isLoading"
      [signInSMS]="signInSMS"
      (login)="onLogin($event)"
    ></app-sign-in-form>
    <p-messages
      [(value)]="messages"
      [escape]="false"
      [enableService]="false"
      severity="error"
      *ngIf="messages.length"
    ></p-messages>

    <div class="d-flex gap-2 flex-column">
      <a
        class="forgot-password"
        (click)="closeModal()"
        routerLink="/forgot-password"
        >{{ t("forgetPassword") }}</a
      >

      <div class="separator">
        <span>{{ t("or") }}</span>
      </div>

      <button (click)="showRegister()" class="member-btn">
        {{ t("becomeAMember") }}
      </button>
    </div>
  </div>
</p-dialog>
