<div class="grid gap-3 car-item p-3" *ngIf="carGrade">
  <div class="g-col-6 text-center" *ngIf="carGrade.images.length">
    <img [src]="carGrade.images[0]" alt="Car Image" class="car-img">
  </div>
  <div class="g-col-6 d-flex justify-content-between">
    <div class="px-3">
      <h4>{{ carGrade.name }}</h4>
      <p class="mb-0">{{ carGrade.seats }} passengers</p>
      <p class="mb-0">{{ carGrade.name }}</p>
    </div>
  </div>
</div>
