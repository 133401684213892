import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AREA_CODES } from 'src/app/shared/constant/constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})
export class EnquiryFormComponent implements OnInit {
  enquiryForm: FormGroup | undefined;
  areas = AREA_CODES;
  siteKey: string = '';
  captchaToken: string = '';
  currentPhoneCode: string = AREA_CODES[0].code;
  pricingSubjectList = [
    'pricing',
    'carModels',
    'paymentMethod',
    'insurance',
    'others'
  ];
  longTermSubjectList = [
    'pricing',
    'carModels',
    'insurance',
    'others'
  ];
  @Output() onSubmitEnquiry: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: string;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.siteKey = environment.recaptcha.siteKey;
    this.enquiryForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.pattern('[0-9]{4,10}')]],
      enquireOn: [null],
      message: [null],
    });
  }

  onPhoneCodeChange(phoneCode: any) {
    this.currentPhoneCode = String(phoneCode.code);
    console.log(this.currentPhoneCode);
  }
  
  
  onSubmit() {
    const phoneNum = this.enquiryForm.get('phone').value;
    this.enquiryForm.patchValue({ phone: `${this.currentPhoneCode}${phoneNum}` });
    this.onSubmitEnquiry.emit(this.enquiryForm.value);
  }

  resolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
  }

  checkType() {
    let subjects: any;
    switch (this.type) {
      case 'pricing':
        subjects = this.pricingSubjectList;
        break;
      case 'longTerm':
        subjects = this.longTermSubjectList;
        break;
    
      default:
        subjects = this.pricingSubjectList;
        break;
    }

    return subjects
  }

}
