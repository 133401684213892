import { CustomerService } from 'src/app/services/customer/customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import {
  first,
  // tap
} from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Observable, of } from 'rxjs';
import { passwordMatch } from 'src/app/shared/utils/validation';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {
  DATE_PICKER_FORMAT,
  DATE_FORMAT_SEND_REQUEST,
  FROM_URL,
  AREA_CODES,
} from 'src/app/shared/constant/constant';
import { formatDate } from '@angular/common';
import { BrowserService } from 'src/app/services/browser/browser.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit {
  registerForm: FormGroup | undefined;
  messages: Message[] = [];
  areas = AREA_CODES;
  gender$: Observable<string[]> = of([]);
  maxDate: Date | undefined;
  DATE_PICKER_FORMAT = DATE_PICKER_FORMAT;
  isBrowser: boolean = false;
  displayTermsConditionModal: Boolean = false;
  language = localStorage.getItem('language');
  english = this.language !== 'TC' && this.language !== 'SC';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private customerService: CustomerService,
    private browserService: BrowserService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.maxDate = new Date();
    this.isBrowser = this.browserService.isBrowser();
  }

  sendOtp(event: Event) {
    event.preventDefault();
    const { phone, areaCode } = this.registerForm.value;
    this.customerService
      .sendOTP(areaCode.code, phone, FROM_URL.REGISTER)
      .subscribe(
        () => {
          this.notificationService.showAlerts('OTP was sent.', 200);
        },
        (err: HttpErrorResponse) => {
          const errorr: { msg: string }[] = err.error.errors;
          errorr.forEach((item) =>
            this.notificationService.showAlerts(item.msg, 404)
          );
        }
      );
  }

  onSubmit() {
    const phoneNum = this.registerForm.get('phone').value;
    const { agree, birthday, confirmPassword, otp, areaCode, ...body } =
      this.registerForm.value;
    body.birthday = birthday
      ? formatDate(birthday, DATE_FORMAT_SEND_REQUEST, 'en')
      : '';
    body.fromUrl = FROM_URL.REGISTER;
    body.otp = otp.trim();
    body.phone = phoneNum;
    body.areaCode = areaCode.code;
    this.authService
      .registerNewUser(body)
      .pipe(first())
      .subscribe(
        () => {
          this.messages = [
            {
              severity: 'success',
              summary: 'Register successfully!',
              detail: 'Go to <a href="sign-in">Login</a> page',
            },
          ];
        },
        (err: HttpErrorResponse) => {
          this.messages = err.error.errors.map((x) => ({
            severity: 'error',
            summary: x.msg,
          }));
        }
      );
  }

  private initForm() {
    this.registerForm = this.fb.group(
      {
        email: [null, [Validators.required, Validators.email]],
        name: [null, Validators.nullValidator],
        surName: [null, Validators.nullValidator],
        phone: [null, [Validators.required, Validators.pattern('[0-9]{4,12}')]],
        otp: [null, Validators.required],
        password: [null, Validators.required],
        confirmPassword: [null, Validators.required],
        membershipId: [null],
        gender: [''],
        birthday: [''],
        agree: [null, Validators.required],
        receiveLatestNews: [null],
        areaCode: [AREA_CODES[0], Validators.required],
      },
      { validators: passwordMatch }
    );

    this.gender$ = this.customerService.getGenderList();
  }

  showTermsConditionModal() {
    this.displayTermsConditionModal = true;
  }

  closeTermsAndConditions() {
    this.displayTermsConditionModal = false;
  }
}
