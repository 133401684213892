import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private messageService: MessageService) { }

  showAlerts(content: string, statusCode: number, typeAlerts: string = '') {
    switch (statusCode) {
      case 200:
      case 202:
        typeAlerts = 'success';
        break;
      case 400:
      case 404:
        typeAlerts = 'error';
        break;
      case 500:
        typeAlerts = 'error';
        break;
      case 401:
        typeAlerts = 'warn';
        break;
      default:
        break;
    }
    this.messageService.add({
      severity: typeAlerts,
      summary: content,
    });
  }

  hideAllNotifications(): void {
    this.messageService.clear();
  }

}
