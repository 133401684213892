import { ButtonModule } from 'primeng/button';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { FooterComponent } from './components/footer/footer.component';
import { FooterNewComponent } from './components/footer-new/footer-new.component';
import { HeaderComponent } from './components/header/header.component';
import { AppMaterialModule } from './material-module';
import { MenubarModule } from 'primeng/menubar';
import { MessageService, SharedModule } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { CustomInterceptorService } from './services/interceptor/custom-interceptor.service';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
// import { APP_BASE_HREF } from '@angular/common';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { DialogModule } from 'primeng/dialog';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslocoRootModule } from './transloco-root.module';
import { FooterService } from './services/footer/footer.service';
// import { TranslationLoaderService } from './services/translationLoader/translation-loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppInitializationService } from './services/init/appInitialization.service';
import { Location } from '@angular/common';
import { SigninDialogComponent } from './components/signin-dialog/signin-dialog.component';
import { CancelDialogComponent } from './components/cancel-dialog/cancel-dialog.component';
import { SharedComponentsModule } from './components/shared-components/shared-components.module';
import { RegisterDialogComponent } from './components/register-dialog/register-dialog.component';
const config: SocketIoConfig = {
  url: environment.urlSocket,
  options: { transports: ['websocket'] },
};

@NgModule({
  declarations: [
    AppComponent,
    // FooterComponent,
    FooterNewComponent,
    HeaderComponent,
    BreadcrumbComponent,
    SigninDialogComponent,
    CancelDialogComponent,
    RegisterDialogComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useFactory: myAppInitializer,
      multi: true,
      deps: [Router, ActivatedRoute, AppInitializationService, Location],
    },
    MessageService,
    FooterService,
    // {
    //   provide: APP_BASE_HREF, useValue: '/ml_website/'
    // }
  ],
  bootstrap: [AppComponent],
  imports: [
    TranslocoRootModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SocketIoModule.forRoot(config),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ButtonModule,
    MenubarModule,
    SharedModule,
    MenuModule,
    ToastModule,
    MessagesModule,
    DialogModule,
    BreadcrumbModule,
    SidebarModule,
    PanelMenuModule,
    QRCodeModule,
    SharedComponentsModule,
  ],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function myAppInitializer(
  router: Router,
  aRoute: ActivatedRoute,
  appInitializationService: AppInitializationService,
  location: Location
): () => Promise<any> {
  return (): Promise<any> => {
    console.log(1, router, router.url, appInitializationService.isInitialized);
    const currentUrl = location.path();
    const params = aRoute.snapshot.params;
    console.log(2, params, currentUrl);
    const regUrl = '?route=promo-detail&promotion=';
    if (currentUrl.startsWith(regUrl)) {
      const promotionId = currentUrl.slice(regUrl.length, currentUrl.length);
      console.log(promotionId);
      router.navigate([`news/promotion/${promotionId}`]);
    } else {
      appInitializationService.isInitialized = true;
    }
    return Promise.resolve();
  };
}
