import { encryptStringValue } from 'src/app/shared/utils/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser, UserLogin } from 'src/app/model/user';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { tap } from 'rxjs/operators';
import { BrowserService } from '../browser/browser.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url: string = `${environment.api}web/customer`;
  private _localStorage: Storage | undefined;
  private _isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _userNAmeLoggedInSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private http: HttpClient, private browserService: BrowserService) {
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }

  getToken(): string {
    const user = this.getUserFromLocalStorage();
    return user?.token || '';
  }

  isLoggedIn(): Observable<boolean> {
    return this._isLoggedInSubject.asObservable();
  }

  userNameLoggedIn(): Observable<string> {
    return this._userNAmeLoggedInSubject.asObservable();
  }

  updateLoginStatus(isLoggedIn: boolean) {
    this._isLoggedInSubject.next(isLoggedIn);
  }

  updateUserNameLoggedIn(userName: string) {
    this._userNAmeLoggedInSubject.next(userName);
  }

  login(body: UserLogin): Observable<IUser> {
    body = {
      ...body,
      username: encryptStringValue(body.username),
      password: encryptStringValue(body.password)
    };
    return this.http.post<IUser>(`${this.url}/sign-in`, body).pipe(
      tap(user => {
        this.updateLoginStatus(true);
        this.updateUserNameLoggedIn(user.name);
        this.saveUserToLocalStorate(user);
      })
    );
  }

  logout() {
    this._isLoggedInSubject.next(false);
    this._userNAmeLoggedInSubject.next('');
    this.clearUserInLocalStorage();
  }

  registerNewUser(body: IUser): Observable<{ token: string }> {
    body = {
      ...body,
      email: encryptStringValue(body.email),
      password: encryptStringValue(body.password)
    };
    return this.http.post<{ token: string }>(`${this.url}/register`, body);
  }

  saveUserToLocalStorate(user: IUser) {
    const encryptedUser = CryptoJS.AES.encrypt(encodeURIComponent(JSON.stringify(user)), environment.secretKey).toString();
    this._localStorage?.setItem('user', encryptedUser);
  }

  getUserFromLocalStorage(): IUser {
    const encryptedUser = this._localStorage?.getItem('user') || '';
    if (encryptedUser) {
      const bytes = CryptoJS.AES.decrypt(encryptedUser, environment.secretKey);
      return JSON.parse(decodeURIComponent(bytes.toString(CryptoJS.enc.Utf8)));
    }
    return null;
  }

  clearUserInLocalStorage() {
    this._localStorage?.removeItem('user');
  }
}
