import { OrderService } from 'src/app/services/order/order.service';
import { ICarGradeItem } from '../../model/car-selection';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOrderData } from '../../model/order';
import { CarService } from '../../services/car/car.service';
import { BrowserService } from '../../services/browser/browser.service';
import { RegionCode } from '../../model/region';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { s3Static } from 'src/app/services/s3-files/s3-files.service';

@Component({
  selector: 'app-car-item-for-modal',
  templateUrl: './car-item-for-modal.component.html',
  styleUrls: ['./car-item-for-modal.component.scss']
})
export class CarItemModalComponent {

  _carGrade: ICarGradeItem;
  show: boolean = false;
  @Input() order: IOrderData | undefined;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();

  @Input()
  get car(): ICarGradeItem {
    return this._carGrade;
  }

  set car(car: ICarGradeItem) {
    this._carGrade = {
      ...car,
      images: car.images.map(img => `${s3Static}${img}`)
    };
  }
  private _localStorage: Storage | undefined;

  constructor(private orderService: OrderService,
    private carService: CarService,
    private notificationService: NotificationsService,
    private browserService: BrowserService) { 
    if (this.browserService.isBrowser()) {
      this._localStorage = localStorage;
    }
  }

  onSelectCar(event: Event) {
    event.preventDefault()
    if (this.order?.place?.region?.code === RegionCode.HK) {
      const body = {
        tempBookingNo: this._carGrade.tempBookingNo,
        pickupTime: this.order?.place.pickupTime,
        dropOffTime: this.order?.place.dropOffTime,
        mlsVehicleLicNo: this._carGrade.mlsVehicleLicNo,
  
        tempBookingNoOld: this.order?.carGrade?.tempBookingNo || '',
        pickupTimeOld: this.order?.selectedCarGrade?.pickupTime || '',
        dropOffTimeOld: this.order?.selectedCarGrade?.dropOffTime || '',
        mlsVehicleLicNoOld: this.order?.carGrade?.mlsVehicleLicNo || '',
      };
      this.carService.selectCar(body).subscribe({
        next: () => {
          this._localStorage.setItem('counter', JSON.stringify(600)); // 600 it's mean 10 minutes
          this.orderService.saveSelectCarGradeToOrder({
            tempBookingNo: this._carGrade.tempBookingNo,
            pickupTime: this.order?.place.pickupTime,
            dropOffTime: this.order?.place.dropOffTime,
            mlsVehicleLicNo: this._carGrade.mlsVehicleLicNo,
          });
        },
        error: err => {
          const errorr: { msg: string }[] = err.error?.errors;
          errorr.forEach((item, index) => {
            if (index === 0) {
              this.notificationService.showAlerts(item.msg, 404);
            }
          });
        }
      });
    }
    
    this.orderService.saveCarGradeToOrder(this._carGrade);
    this.closeModal.emit();
  }
}
