import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOptionalItem } from 'src/app/model/optional-item';
import { NicoNicoExtrasParams } from 'src/app/model/niconico';

@Injectable({
  providedIn: 'root'
})
export class OptionalItemService {

  url: string = `${environment.api}web`;

  constructor(private http: HttpClient) { }

  getOptionalItemsByCarGrade(carGrade: string): Observable<IOptionalItem[]> {
    return this.http.get<IOptionalItem[]>(`${this.url}/get-items-by-car-grade?carGrade=${carGrade}`);
  }

  getOptionalItemsNicoNico(params: NicoNicoExtrasParams): Observable<IOptionalItem[]> {
    return this.http.get<IOptionalItem[]>(`${this.url}/order/get-niconico-extras?pu_location=${params.pu_location}&do_location=${params.do_location}&pu_date=${params.pu_date}&do_date=${params.do_date}&pu_time=${params.pu_time}&do_time=${params.do_time}&car_class=${params.car_class}`);
  }
}
