import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
// import { ROUTE } from 'src/app/shared/constant/constant';
import { IHkLimoOrder } from 'src/app/model/limo';

@Component({
  selector: 'app-limo-order-detail',
  templateUrl: './limo-order-detail.component.html',
  styleUrls: ['./limo-order-detail.component.scss']
})
export class LimoOrderDetailComponent implements OnInit {
  @Input() order: IHkLimoOrder | undefined;
  isShowRateTerms: boolean = false;
  isShowIconPen: boolean = true;
  showMoreDetail: boolean = false;
  DATE_TIME_FORMAT = 'EEE, MMM-dd, hh:mm a';
  @Output() confirm = new EventEmitter();
  carInfoHeight: string;
  screenDisplay: string;

  pickupDate: string;
  dropOffDate: string;
  staticImg: string = 'assets/images/rac/search-car.png';

  @Output() editInfo: EventEmitter<string> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() {
    this.onResize();
    console.log(this.order);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 768) {
      this.screenDisplay = 'mobile';
    } else {
      this.screenDisplay = 'desktop';
    }
  }

  onClickRateTerms() {
    this.isShowRateTerms = !this.isShowRateTerms;
  }

  onEditInfo() {
    this.editInfo.emit();
  }

  onConfirm() {
    console.log('TES');
    this.confirm.emit();
  }

  onDownload() {
    const fileUrl = 'assets/images/downloads/limo_hk_form.pdf';
    window.open(fileUrl, '_blank');

  }

}
